/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      company_name
      credits
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      media {
        items {
          id
          accountID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      encode_analyses {
        items {
          id
          accountID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      comparisons {
        items {
          id
          accountID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      references {
        items {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          scan_type
          scan_order
          updatedAt
          user_identity_id
        }
        nextToken
      }
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVQaaSSubscription = /* GraphQL */ `
  query GetVQaaSSubscription($id: ID!) {
    getVQaaSSubscription(id: $id) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVQaaSSubscriptions = /* GraphQL */ `
  query ListVQaaSSubscriptions(
    $filter: ModelVQaaSSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVQaaSSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      comparisons {
        items {
          id
          teamID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      encode_analyses {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      media {
        items {
          id
          teamID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCrossAccountS3 = /* GraphQL */ `
  query GetCrossAccountS3($id: ID!) {
    getCrossAccountS3(id: $id) {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCrossAccountS3s = /* GraphQL */ `
  query ListCrossAccountS3s(
    $filter: ModelCrossAccountS3FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrossAccountS3s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Key
        ETag
        Size
        LastModified
        LastSeen
        accountID
        referenceID
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCrossCorrelation = /* GraphQL */ `
  query GetCrossCorrelation($id: ID!) {
    getCrossCorrelation(id: $id) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listCrossCorrelations = /* GraphQL */ `
  query ListCrossCorrelations(
    $filter: ModelCrossCorrelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrossCorrelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groups
        owner
        offset
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDisMSE = /* GraphQL */ `
  query GetDisMSE($id: ID!) {
    getDisMSE(id: $id) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listDisMSES = /* GraphQL */ `
  query ListDisMSES(
    $filter: ModelDisMSEFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisMSES(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        owner
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDistorted = /* GraphQL */ `
  query GetDistorted($id: ID!) {
    getDistorted(id: $id) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      scan_type
      scan_order
      updatedAt
      user_identity_id
    }
  }
`;
export const listDistorteds = /* GraphQL */ `
  query ListDistorteds(
    $filter: ModelDistortedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistorteds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        persons
        subscriptions
        teams
        id
        metric_psnr
        metric_psnr_bin
        metric_psnr_cdf
        metric_vmaf
        metric_vmaf_bin
        metric_vmaf_cdf
        owner
        progress_calc_metric
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        scan_type
        scan_order
        updatedAt
        user_identity_id
      }
      nextToken
    }
  }
`;
export const getEncodeAnalysis = /* GraphQL */ `
  query GetEncodeAnalysis($id: ID!) {
    getEncodeAnalysis(id: $id) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      refMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      refMediaID
      encMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      encMediaID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      deleted
      description
      encode_id
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      comparisons {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        vqm {
          nextToken
        }
        inAlarm
        unAcknowledged
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
      }
      triggerEpoch
      persons {
        items {
          id
          accountID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions
      teams {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      vmaf_reduct
      psnr_reduct
      metric_psnr
      metric_vmaf
      owner
      progress_calc_metric
      progress_create_hls
      progress_deploy
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      unAcknowledged
      updatedAt
      user_identity_id
    }
  }
`;
export const listEncodeAnalyses = /* GraphQL */ `
  query ListEncodeAnalyses(
    $filter: ModelEncodeAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEncodeAnalyses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        triggerEpoch
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        unAcknowledged
        updatedAt
        user_identity_id
      }
      nextToken
    }
  }
`;
export const getComparison = /* GraphQL */ `
  query GetComparison($id: ID!) {
    getComparison(id: $id) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      createdAt
      deleted
      description
      encodeAnalyses {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      owner
      persons {
        items {
          id
          accountID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listComparisons = /* GraphQL */ `
  query ListComparisons(
    $filter: ModelComparisonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComparisons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      build_number
      referenceMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          triggerEpoch
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          unAcknowledged
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodedMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          triggerEpoch
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          unAcknowledged
          updatedAt
          user_identity_id
        }
        nextToken
      }
      createdAt
      cross_account_s3_id
      deleted
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      id
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        vqm {
          nextToken
        }
        inAlarm
        unAcknowledged
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
      }
      owner
      persons {
        items {
          id
          accountID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      fps
      bitrate_list_per_sec
      width
      height
      extension
      es_filename
      updatedAt
      user_identity_id
    }
  }
`;
export const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      nextToken
    }
  }
`;
export const getMeasurementPoint = /* GraphQL */ `
  query GetMeasurementPoint($id: ID!) {
    getMeasurementPoint(id: $id) {
      createdAt
      description
      id
      media {
        items {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodeAnalyses {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          triggerEpoch
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          unAcknowledged
          updatedAt
          user_identity_id
        }
        nextToken
      }
      vqm {
        items {
          id
          createdAt
          updatedAt
          measurementPointId
          owner
        }
        nextToken
      }
      inAlarm
      unAcknowledged
      owner
      updatedAt
      children {
        items {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        nextToken
      }
      parentID
      parent {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        vqm {
          nextToken
        }
        inAlarm
        unAcknowledged
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
      }
    }
  }
`;
export const listMeasurementPoints = /* GraphQL */ `
  query ListMeasurementPoints(
    $filter: ModelMeasurementPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasurementPoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        vqm {
          nextToken
        }
        inAlarm
        unAcknowledged
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
      }
      nextToken
    }
  }
`;
export const getVqm = /* GraphQL */ `
  query GetVqm($id: ID!) {
    getVqm(id: $id) {
      id
      createdAt
      updatedAt
      measurementPointId
      chunk {
        epoch
        value
      }
      owner
    }
  }
`;
export const listVqms = /* GraphQL */ `
  query ListVqms(
    $filter: ModelVqmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVqms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        measurementPointId
        chunk {
          epoch
          value
        }
        owner
      }
      nextToken
    }
  }
`;
export const vqmByDate = /* GraphQL */ `
  query VqmByDate(
    $measurementPointId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVqmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vqmByDate(
      measurementPointId: $measurementPointId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        measurementPointId
        chunk {
          epoch
          value
        }
        owner
      }
      nextToken
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      name
      owner
      inAlarm
      unAcknowledged
      lowVQ
      rootID
      root {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        vqm {
          nextToken
        }
        inAlarm
        unAcknowledged
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        inAlarm
        unAcknowledged
        lowVQ
        rootID
        root {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPSNRavg = /* GraphQL */ `
  query GetPSNRavg($id: ID!) {
    getPSNRavg(id: $id) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listPSNRavgs = /* GraphQL */ `
  query ListPSNRavgs(
    $filter: ModelPSNRavgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSNRavgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        owner
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRefMSE = /* GraphQL */ `
  query GetRefMSE($id: ID!) {
    getRefMSE(id: $id) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listRefMSES = /* GraphQL */ `
  query ListRefMSES(
    $filter: ModelRefMSEFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRefMSES(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        owner
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReference = /* GraphQL */ `
  query GetReference($id: ID!) {
    getReference(id: $id) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      build_number
      createdAt
      cross_account_s3_id
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          referenceID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      scan_type
      scan_order
      updatedAt
      user_identity_id
    }
  }
`;
export const listReferences = /* GraphQL */ `
  query ListReferences(
    $filter: ModelReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        viewers
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        scan_type
        scan_order
        updatedAt
        user_identity_id
      }
      nextToken
    }
  }
`;
export const getSSIM = /* GraphQL */ `
  query GetSSIM($id: ID!) {
    getSSIM(id: $id) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listSSIMS = /* GraphQL */ `
  query ListSSIMS(
    $filter: ModelSSIMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSSIMS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        owner
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVMAF = /* GraphQL */ `
  query GetVMAF($id: ID!) {
    getVMAF(id: $id) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const listVMAFS = /* GraphQL */ `
  query ListVMAFS(
    $filter: ModelVMAFFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVMAFS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        owner
        sequence
        values
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccountMedia = /* GraphQL */ `
  query GetAccountMedia($id: ID!) {
    getAccountMedia(id: $id) {
      id
      accountID
      mediaID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      media {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAccountMedias = /* GraphQL */ `
  query ListAccountMedias(
    $filter: ModelAccountMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        mediaID
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        media {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAccountEncodeAnalysis = /* GraphQL */ `
  query GetAccountEncodeAnalysis($id: ID!) {
    getAccountEncodeAnalysis(id: $id) {
      id
      accountID
      encodeAnalysisID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        triggerEpoch
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        unAcknowledged
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAccountEncodeAnalyses = /* GraphQL */ `
  query ListAccountEncodeAnalyses(
    $filter: ModelAccountEncodeAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountEncodeAnalyses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        encodeAnalysisID
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        encodeAnalysis {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          triggerEpoch
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          unAcknowledged
          updatedAt
          user_identity_id
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAccountComparison = /* GraphQL */ `
  query GetAccountComparison($id: ID!) {
    getAccountComparison(id: $id) {
      id
      accountID
      comparisonID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAccountComparisons = /* GraphQL */ `
  query ListAccountComparisons(
    $filter: ModelAccountComparisonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountComparisons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        comparisonID
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        comparison {
          accountID
          createdAt
          deleted
          description
          id
          owner
          updatedAt
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTeamComparison = /* GraphQL */ `
  query GetTeamComparison($id: ID!) {
    getTeamComparison(id: $id) {
      id
      teamID
      comparisonID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTeamComparisons = /* GraphQL */ `
  query ListTeamComparisons(
    $filter: ModelTeamComparisonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamComparisons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        comparisonID
        team {
          id
          name
          description
          createdAt
          updatedAt
        }
        comparison {
          accountID
          createdAt
          deleted
          description
          id
          owner
          updatedAt
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTeamEncodeAnalysis = /* GraphQL */ `
  query GetTeamEncodeAnalysis($id: ID!) {
    getTeamEncodeAnalysis(id: $id) {
      id
      teamID
      encodeAnalysisID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        triggerEpoch
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        unAcknowledged
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTeamEncodeAnalyses = /* GraphQL */ `
  query ListTeamEncodeAnalyses(
    $filter: ModelTeamEncodeAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamEncodeAnalyses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamID
        encodeAnalysisID
        team {
          id
          name
          description
          createdAt
          updatedAt
        }
        encodeAnalysis {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          triggerEpoch
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          unAcknowledged
          updatedAt
          user_identity_id
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTeamMedia = /* GraphQL */ `
  query GetTeamMedia($id: ID!) {
    getTeamMedia(id: $id) {
      id
      teamID
      mediaID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      media {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTeamMedias = /* GraphQL */ `
  query ListTeamMedias(
    $filter: ModelTeamMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMedias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamID
        mediaID
        team {
          id
          name
          description
          createdAt
          updatedAt
        }
        media {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getEncodeAnalysisComparison = /* GraphQL */ `
  query GetEncodeAnalysisComparison($id: ID!) {
    getEncodeAnalysisComparison(id: $id) {
      id
      encodeAnalysisID
      comparisonID
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        triggerEpoch
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        unAcknowledged
        updatedAt
        user_identity_id
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEncodeAnalysisComparisons = /* GraphQL */ `
  query ListEncodeAnalysisComparisons(
    $filter: ModelEncodeAnalysisComparisonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEncodeAnalysisComparisons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        encodeAnalysisID
        comparisonID
        encodeAnalysis {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          triggerEpoch
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          unAcknowledged
          updatedAt
          user_identity_id
        }
        comparison {
          accountID
          createdAt
          deleted
          description
          id
          owner
          updatedAt
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

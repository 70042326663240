import React from "react";
import {API, graphqlOperation} from "aws-amplify";
import {Card, Flex, Text} from '@aws-amplify/ui-react';
import {FcHighPriority} from 'react-icons/fc'
import {VscBellDot} from 'react-icons/vsc'
import {IconContext} from "react-icons";

import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import HighchartsReact from 'highcharts-react-official';
import {onCreateVqm} from "./ViewerDigitalTwinServiceCard";

highchartsMore(Highcharts);
solidGauge (Highcharts);

const onUpdateMeasurementPoint = /* GraphQL */ `
  subscription OnUpdateMeasurementPoint($id: ID!) {
    onUpdateMeasurementPoint(id: $id) {
      createdAt
      description
      id
      media {
        items {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodeAnalyses {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          triggerEpoch
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          unAcknowledged
          updatedAt
          user_identity_id
        }
        nextToken
      }
      vqm {
        items {
          id
          createdAt
          updatedAt
          measurementPointId
          owner
        }
        nextToken
      }
      inAlarm
      unAcknowledged
      owner
      updatedAt
      children {
        items {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        nextToken
      }
      parentID
      parent {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        vqm {
          nextToken
        }
        inAlarm
        unAcknowledged
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
      }
    }
  }
`;
const getMeasurementPoint = /* GraphQL */ `
  query GetMeasurementPoint($id: ID!) {
    getMeasurementPoint(id: $id) {
      createdAt
      description
      id
      media {
        items {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodeAnalyses {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          triggerEpoch
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          unAcknowledged
          updatedAt
          user_identity_id
        }
        nextToken
      }
      vqm {
        items {
          id
          createdAt
          updatedAt
          measurementPointId
          owner
        }
        nextToken
      }
      inAlarm
      unAcknowledged
      owner
      updatedAt
      children {
        items {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        nextToken
      }
      parentID
      parent {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        vqm {
          nextToken
        }
        inAlarm
        unAcknowledged
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
      }
    }
  }
`;

function ViewerDigitalTwinMonitorCard(props) {
    const {
        monitorId,
        monitorName,
        monitorFormat,
        monitorCodec,
        monitorBitrate,
    } = props
    const [showAlarmIcon, setShowAlarmIcon] = React.useState(false)
    const [showUnAcknowledged, setShowUnAcknowledged] = React.useState(false)
    const [gaugeOptions, setGaugeOptions] = React.useState({
        chart: {
            type: 'solidgauge',
            height: '140px'
        },

        title: null,

        pane: {
            center: ['60%', '50%'],
            size: '80px',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },

        exporting: {
            enabled: false
        },

        tooltip: {
            enabled: false
        },

        yAxis: {
            min: 0,
            max: 100,
            stops: [
                [0.25, '#FF3333'], // red
                [0.55, '#FF3333'], // red
                [0.75, '#FFC41C'], // yellow
                [0.9, '#37F713'], // green
                [1, '#37F713'] // green
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
                enabled: false,
            },
            labels: {
                enabled: false,
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Speed',
            data: [100],
            dataLabels: {
                format:
                    '<div style="text-align:center">' +
                    '<span style="font-size:25px">{y}</span><br/>' +
                    '</div>'
            },
            tooltip: {
                valueSuffix: ' km/h'
            }
        }],
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        }
    })
    const bitrateChartRef = React.useRef()
    // console.log(monitorId)
    // Subscribe to create VQM mutation

    // getMeasurementPoint
    React.useEffect(() => {
        let mounted = true

        async function getMyMonitorPoint(){
            // console.log(monitorId)
            let response = await API.graphql(graphqlOperation(getMeasurementPoint, {id: monitorId}))
            return (response['data']['getMeasurementPoint'])
        }

        getMyMonitorPoint().then(data => {
            if (mounted && data) {
                // console.log(data)
                setShowUnAcknowledged(data['unAcknowledged'])
            }
        })
        return () => {
            mounted = false
        }
    }, [monitorId])

    // Subscribe to onCreateVQM
    React.useEffect(() => {
        let subscription
        let mounted = true

        async function setupSubscription() {
            subscription = API.graphql(
                graphqlOperation(
                    onCreateVqm,
                    {measurementPointId: monitorId}
                )).subscribe({
                next: (data) => {
                    if (mounted) {
                        if (data['value']['data']['onCreateVqm']['chunk']){
                            const score = Math.min(...data['value']['data']['onCreateVqm']['chunk'].map(item => item.value))
                            setShowAlarmIcon((score < 75))
                            setGaugeOptions({series: [{data: [Math.floor(score)]}]})
                            props.onScore(Math.floor(score))
                        }
                    }
                },
                error: error => {
                    console.warn(error);
                }
            })
        }
        if (monitorId) {
            setupSubscription().then()
            return () => {
                mounted = false
                if (subscription) {
                    subscription.unsubscribe()
                }
            }
        }
    }, [monitorId])

    // Subscribe to onUpdateMeasurementPoint
    React.useEffect(() => {
        let subscription
        let mounted = true

        async function setupSubscription() {
            subscription = API.graphql(
                graphqlOperation(
                    onUpdateMeasurementPoint,
                    {id: monitorId}
                )).subscribe({
                next: (data) => {
                    if (mounted) {
                        if (data['value']['data']['onUpdateMeasurementPoint']){
                            console.log(data['value']['data']['onUpdateMeasurementPoint'])
                            setShowUnAcknowledged(data['value']['data']['onUpdateMeasurementPoint']['unAcknowledged'])
                        }
                    }
                },
                error: error => {
                    console.warn(error);
                }
            })
        }
        if (monitorId) {
            setupSubscription().then()
            return () => {
                mounted = false
                if (subscription) {
                    subscription.unsubscribe()
                }
            }
        }
    }, [monitorId])

    // React.useEffect(() => {
    //     let mounted = true
    //     setGaugeOptions({series: [{data: [JSON.parse(monitorScore)]}]})
    //     return function cleanup() {
    //         mounted = false;
    //     };
    //
    // }, [monitorScore])
    // React.useEffect(() => {
    //     let interval
    //     if (monitorName !== 'CBR#1'){
    //         interval = setInterval(() => setGaugeOptions({series: [{data: [80 + Math.floor(Math.random() * 10)]}]}), 3000);
    //     }
    //     return () => {
    //         if (interval) clearInterval(interval);
    //     };
    // }, [monitorName]);

    return (
        <Card
            key={monitorId}
            id={monitorId}
            padding="0rem"
            variation="elevated"
            onClick={props.onClick}
        >
            <Flex direction={"column"} justifyContent={"space-between"} gap={0} width={'200px'}>
                <Flex direction={"row"} justifyContent={"flex-end"} width={"200px"} backgroundColor={"white"}>
                    <div style={{width:'200px', height:'140px'}}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={gaugeOptions}
                            ref={bitrateChartRef}
                        />
                    </div>
                    <Flex direction={"column"} justifyContent={"space-between"}>
                        {showAlarmIcon ?
                            <div style={{width: '30px', height: '30px', padding: '0px'}}>
                                <IconContext.Provider
                                    value={{style: {width: '25px', height: '25px', marginTop: '3px'}}}>
                                    <div>
                                        <FcHighPriority className={'customClass'}/>
                                    </div>
                                </IconContext.Provider>
                            </div> : <div style={{width: '30px', height: '30px', padding: '0px'}}/>
                        }
                        {showUnAcknowledged ?
                            <div style={{width: '30px', height: '30px', padding: '0px'}}>
                                <IconContext.Provider value={{style: {width: '25px', height: '25px'}}}>
                                    <div>
                                        <VscBellDot/>
                                    </div>
                                </IconContext.Provider>
                            </div> : <div style={{width: '30px', height: '30px', padding: '0px'}}/>
                        }
                    </Flex>
                </Flex>
                <Flex direction={"column"} justifyContent={"flex-start"} backgroundColor={'#3380FF'} height={"100px"} padding={"5px"}>
                    <Text
                        color={'white'}
                        style={{fontSize: '20px', lineHeight: '20px', marginBottom: '8px'}}
                    >
                        {monitorName}
                    </Text>
                    <Text
                        color={'white'}
                        style={{fontSize: '14px', lineHeight: '6px', marginBottom: '2px', marginLeft: '5px'}}
                    >
                        {monitorCodec}
                    </Text>
                    <Text
                        color={'white'}
                        style={{fontSize: '14px', lineHeight: '6px', marginBottom: '2px', marginLeft: '5px'}}
                    >
                        {monitorFormat + '@' + monitorBitrate}
                    </Text>
                </Flex>
            </Flex>
        </Card>
    )
}

export default ViewerDigitalTwinMonitorCard

import React from "react";
import {Button, Card, Flex, Heading, Text} from '@aws-amplify/ui-react';
import {API, graphqlOperation} from 'aws-amplify';
import {VscBellDot} from 'react-icons/vsc'
import {IconContext} from "react-icons";

const onUpdateEncodeAnalysis = /* GraphQL */ `
  subscription OnUpdateEncodeAnalysis($id: ID!, $owner: String!) {
    onUpdateEncodeAnalysis(id: $id, owner: $owner) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      refMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      refMediaID
      encMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      encMediaID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      deleted
      description
      encode_id
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      comparisons {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        vqm {
          nextToken
        }
        inAlarm
        unAcknowledged
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
      }
      triggerEpoch
      persons {
        items {
          id
          accountID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions
      teams {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      vmaf_reduct
      psnr_reduct
      metric_psnr
      metric_vmaf
      owner
      progress_calc_metric
      progress_create_hls
      progress_deploy
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      unAcknowledged
      updatedAt
      user_identity_id
    }
  }
`;

function AlarmCard(props) {
    const {
        alarmId,
        alarmOwner,
        alarmName,
        alarmStatus,
        alarmTimestamp,
        alarmUnAcknowledged,
        alarmRecommendation
    } = props
    const d1 = new Date(alarmTimestamp)
    const localAlarmTimestamp = d1.toLocaleString()
    const [showUnAcknowledged, setShowUnAcknowledged] = React.useState(alarmUnAcknowledged)
    const [vqaasStatus, setVqaasStatus] = React.useState(alarmStatus)
    // console.log(showUnAcknowledged)

    // Subscribe to update EncodeAnalysis
    React.useEffect(() => {
        let subscriptions = []
        let mounted = true

        async function setupSubscription() {
            subscriptions.push(API.graphql(
                graphqlOperation(
                    onUpdateEncodeAnalysis,
                    {
                        id: alarmId,
                        owner: alarmOwner
                    }
                )).subscribe({
                next: (data) => {
                    if (mounted) {
                        if (data['value']['data']['onUpdateEncodeAnalysis']){
                            console.log(data['value']['data']['onUpdateEncodeAnalysis']['status'])
                            setShowUnAcknowledged(data['value']['data']['onUpdateEncodeAnalysis']['unAcknowledged'])
                            setVqaasStatus(data['value']['data']['onUpdateEncodeAnalysis']['status'])
                        }
                    }
                },
                error: error => {
                    console.warn(error);
                }
            }))
            // console.log(data['value']['data']['onCreateEncodeAnalysis'])
        }
        if (alarmId) {
            setupSubscription().then()
            return () => {
                mounted = false
                if (subscriptions) {
                    subscriptions.forEach((subscription) => subscription.unsubscribe())
                }
            }
        }
    }, [alarmId, alarmOwner])

    return (
        <Card
            key={alarmId}
            id={alarmId}
            padding="0rem"
            variation="elevated"
        >
            <Flex direction={"row"} justifyContent={"flex-start"}>
                <Flex direction={"column"} justifyContent={"space-between"} gap={0}>
                    <Flex direction={"row"} justifyContent={"space-between"}>
                        <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"400px"} padding={'5px'}>
                            <Text>{localAlarmTimestamp}</Text>
                            <Heading level={5}>{alarmName}</Heading>
                        </Flex>
                        <Flex direction={"row"} justifyContent={"flex-end"} width={"80px"} gap={0}>
                            { showUnAcknowledged ?
                            <div style={{width:'40px', height:'40px', padding:'8px'}}>
                                <IconContext.Provider value={{ color: "black", style:{width:'25px', height:'25px'} }}>
                                    <div>
                                        <VscBellDot />
                                    </div>
                                </IconContext.Provider>
                            </div> : <div style={{width:'40px', height:'40px', padding:'8px'}}/>}
                        </Flex>
                    </Flex>
                    <Flex direction={"row"} justifyContent={"flex-start"} width={"500px"} style={{paddingLeft: '10px'}}>
                        {
                            (vqaasStatus !== 'Success') ?
                                <Text>{'VQaaS: ' + vqaasStatus}</Text> : <Text>{alarmRecommendation}</Text>
                        }
                    </Flex>
                    <Flex direction={"row"} justifyContent={"center"} style={{padding: '10px'}}>
                        <Button
                            size="small"
                            className="reference-card-button"
                            variation="primary"
                            style={{paddingTop: '2px', paddingBottom: '2px'}}
                            onClick={props.onReplayClick}
                        >
                            Replay
                        </Button>
                        <Button
                            size="small"
                            className="reference-card-button"
                            isDisabled={(!showUnAcknowledged)}
                            variation="primary"
                            style={{paddingTop: '2px', paddingBottom: '2px'}}
                            onClick={props.onAcknowledgeClick}
                        >
                            Acknowledge
                        </Button>
                        <Button
                            size="small"
                            className="reference-card-button"
                            isDisabled={(vqaasStatus !== 'Success')}
                            variation="primary"
                            style={{paddingTop: '2px', paddingBottom: '2px'}}
                            onClick={props.onVqaasClick}
                        >
                            VQaaS
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    )
}

export default AlarmCard

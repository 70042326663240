import React from "react";
import Popup from "reactjs-popup"
import {API, graphqlOperation, Storage} from 'aws-amplify';
import {
    Badge,
    Button,
    Card,
    Divider,
    Flex,
    Heading,
    Icon,
    Image as AmplifyImage,
    Link,
    Loader,
    Menu,
    MenuButton,
    MenuItem,
    Text,
    View
} from '@aws-amplify/ui-react';
import {Link as ReactRouterLink} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import SharePopup from "./SharePopup";

highchartsMore(Highcharts);

const onUpdatePersonsEncodeAnalysis = /* GraphQL */ `
  subscription OnUpdateEncodeAnalysis($id: ID!, $owner: String!) {
    onUpdateEncodeAnalysis(id: $id, owner: $owner) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      refMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          items {
            account {
              id
              company_name
              email
              first_name
              last_name
            }
          }
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      refMediaID
      encMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          items {
            account {
              id
              company_name
              email
              first_name
              last_name
            }
          }
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      encMediaID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      description
      encode_id
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      comparisons {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      persons {
        items {
          id
          accountID
          account {
            id
            email
          }
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions
      teams {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      vmaf_reduct
      psnr_reduct
      metric_psnr
      metric_vmaf
      owner
      progress_calc_metric
      progress_create_hls
      progress_deploy
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      updatedAt
      user_identity_id
    }
  }
`;

const getPersonsEncodeAnalysis = /* GraphQL */ `
  query GetEncodeAnalysis($id: ID!) {
    getEncodeAnalysis(id: $id) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      refMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          items {
            account {
              id
              company_name
              email
              first_name
              last_name
            }
          }
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      refMediaID
      encMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          items {
            account {
              id
              company_name
              email
              first_name
              last_name
            }
          }
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      encMediaID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      description
      encode_id
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      comparisons {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      persons {
        items {
          id
          accountID
          account {
            id
            email
          }
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions
      teams {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      vmaf_reduct
      psnr_reduct
      metric_psnr
      metric_vmaf
      owner
      progress_calc_metric
      progress_create_hls
      progress_deploy
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      updatedAt
      user_identity_id
    }
  }
`;


function SynamediaStrip() {
    return <Flex direction="column" gap={0}>
        <View
            backgroundColor={"#3380FF"}
            padding={"5px"}
            height={"12.5%"}
        />
        <View
            backgroundColor={"#FFB400"}
            padding={"5px"}
            height={"12.5%"}
        />
        <View
            backgroundColor={"#3380FF"}
            padding={"5px"}
            height={"75%"}
        />
    </Flex>;
}

function EAMedia(props) {
    const {media, encodeAnalysis} = props
    const [thumbnailURL, setThumbnailURL] = React.useState()
    const img = new Image();
    img.src = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAABaCAYAAAA/xl1SAAAEXUlEQVR4Xu2dzUtUYRSHz/UjMjMq0kUhIZRu2rgJooSJNraxnUbr2gmt/GgbIkh/RS4yhf6DCNMW0cKibQs/dkm0CKScbG733hpRx/EwLyMzv+G5IIOXM+97zvN7vNyZUYxOXLwWN506a2ZR8rX/uHn5RMk5TkCgWgTiOLbo5JWBuPPhXKl/sdnGxKVq7cU6EDiUwD8BHyUCHnJsjCMg3hwvAQQ8Xr6s7hBAQBSpKQEErCl+NkdAHKgpgYoEXFxcrGmz5TbP5XJ12Re8/FgqEjCKSt8r9Lc4/or0/aR6PODlp4KAPqPgCgT00SGgzyi4AgF9dAjoMwquQEAfXSLgreSTkJeHVm6MJZ+E7LntA6gPdG8FvHxe0Z0Bi1+/KlPYuf/mHqA+UASskBECVgaskmp+YH1aXAF9RsEVCOijQ0CfUXAFAvroENBnFFyBgD46BPQZBVcgoI8OAX1GwRUI6KNDQJ9RcAUC+ugQ0GcUXIGAPjoE9BkFVyCgjw4BfUbBFQjoo0NAn1FwBQL66BDQZxRcgYA+On4dy2cUXIGAPjoE9BkFVyCgjw4BfUbBFQjoo0NAn1FwBQL66BpCwEKhYPUYdj32lCpRT39FWJGAw8PDvtI1qFhYWKjBrkdvmYY8MjJSd32lDdUTr4oErEuaNCVNAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl49NvHgH1M5SeAAGl4zu+5tP/+B5F0e4GB7+v1s4IWC2SDbbOzp+CrXz+Zb93YmtqMtvOm+VutFd9SgSsOtLGWDAV8N2Hn9kwq1/eW8/V64mAHf+Hi5PH9OpYfAyfGQHD2TX0M4sC7uz8tuaW1ky39Ao4PT1lg4N3raPjjM3OPrd8ftv6+vpsdW3V8sllcmjonvX29tmbN69tZWVlV9jR0cfW3d1dwgwBG1qj8OFSAb9trlvnuS2Lkgvd2089djsRcGrqqbW1nbLNza+7i9+//8D6+/ttYmIsOzcz88zm5+esvf20LS8vZecmJ5/Y2tp6Vrf3QMDwjBr6mZmAX9et6/xWNufSx56y94DpC5T0KL5oqeQFCwI2tEbhw6USff+Rt+aokF0Bt/Mt1tXZeuSCR4l3UNLiQggYnhHPrAKBTMALD1/se8+nuO7G+KUqbMESEChP4C/PC3fauf/83QAAAABJRU5ErkJggg==`

    let mediaFilename
    if (media) {
        let splitRefMediaFilename = media.filename.split('/')
        if (splitRefMediaFilename.length === 1) {
            mediaFilename = splitRefMediaFilename[splitRefMediaFilename.length - 1]
        } else {
            mediaFilename = '.../' + splitRefMediaFilename[splitRefMediaFilename.length - 1]
        }
    }
    let showDeInterlaced = false
    if (media.scan_type === 'Interlaced') showDeInterlaced = true
    if (media.scan_type === 'MBAFF') showDeInterlaced = true
    let showResampled = false
    let showRescaled = false
    if (encodeAnalysis.spec_width && encodeAnalysis.spec_height){
        if (encodeAnalysis.spec_width !== media.spec_width) showRescaled = true
        if (encodeAnalysis.spec_height !== media.spec_height) showRescaled = true
    }

    React.useEffect(() => {
        let mounted = true
        async function getAWSUrl() {
            try {
                const result = await Storage.get(
                    media['id'] + "/media_01.jpg",
                    {
                        level: 'protected',
                        identityId: media.user_identity_id,
                        expires: 86400
                    });
                if (mounted) {
                    setThumbnailURL(result);
                }
            } catch (err) {
                console.log('URL not found')
            }
        }
        if (media) {
            if (media.progress_create_hls === 100) {
                getAWSUrl().then()
            }
        }
        return function cleanup() {
            mounted = false;
        };

    }, [media])

    function drag(ev) {
        ev.dataTransfer.setDragImage(img, 20, 20);
        ev.dataTransfer.setData("text", ev.target.id);
    }

    return <Flex direction="column" gap={"10px"}>
        <Flex direction={"row"}>
            <View height="81px" minWidth="144px">
                {thumbnailURL ?
                    <AmplifyImage
                        draggable="true"
                        onDragStart={(event) => drag(event)}
                        style={{cursor: 'move'}}
                        key={encodeAnalysis.id}
                        id={encodeAnalysis.id}
                        height="100%"
                        objectFit="contain"
                        src={thumbnailURL}
                        alt="Not available"
                    />
                    :
                    <Flex justifyContent="center" height="61px" padding="10px">
                        <Loader
                            height="100%"
                            filledColor="#FF3333"
                            emptyColor="var(--amplify-colors-brand-secondary-100)"
                        />
                    </Flex>
                }
            </View>
            <Flex direction="column" gap={"1px"} style={{paddingRight: "10px"}} alignItems={"flex-start"}>
                <Tooltip title={media.filename} placement={'top'}>
                    <Heading level={6} lineHeight={"2rem"}>{mediaFilename}</Heading>
                </Tooltip>
                <Text padding={"0px"}>
                    {media ? media.spec_width + "x" + media.spec_height + "@" + media.spec_frame_rate + "fps" : "Media deleted"}
                </Text>
                <Flex direction={"row"}>
                    <Text padding={"0px"}>
                        {media ? media.createdAt : ""}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
        <Flex direction={"row"}>
            { showDeInterlaced ? <Badge variation="info" size="small">De-interlaced</Badge> : <></>}
            { showResampled ? <Badge variation="info" size="small">Resampled</Badge> : <></> }
            { showRescaled ? <Badge variation="info" size="small">Rescaled</Badge> : <></>}
        </Flex>
    </Flex>;
}

function EAData(props) {
    const {refBitrateListPerSec, encBitrateListPerSec, vmafReduct, psnrReduct, metricVMAF, metricPsnr} = props
    // console.log(refBitrateListPerSec)
    const vmafChartRef = React.useRef()
    const psnrChartRef = React.useRef()
    const bitrateChartRef = React.useRef()
    const [vmafChartOptions, setVmafChartOptions] = React.useState({
        chart: {height: '48px'},
        title: {
            text: null
        },

        xAxis: {
            visible: false,
            type: 'datetime',
        },

        yAxis: {
            max: 100,
            min: 0,
            opposite: true,
            title: {
                text: null
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                enableMouseTracking: false,
                allowPointSelect: false
            }
        },
        series: [{
            type: 'arearange',
            name: 'Temperatures',
            data: JSON.parse(vmafReduct)
        }]
    })
    const [psnrChartOptions, setPsnrChartOptions] = React.useState({
        chart: {height: '48px'},
        title: {
            text: null
        },

        xAxis: {
            visible: false,
            type: 'datetime',
        },

        yAxis: {
            max: 60,
            min: 0,
            opposite: true,
            title: {
                text: null
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                enableMouseTracking: false,
                allowPointSelect: false
            }
        },
        series: [{
            type: 'arearange',
            name: 'PSNR',
            data: JSON.parse(psnrReduct)
        }]
    })
    const [bitrateChartOptions, setBitrateChartOptions] = React.useState({
        chart: {height: '48px'},
        title: {
            text: null
        },

        xAxis: {
            visible: false,
            type: 'datetime',
        },

        yAxis: {
            min: 0,
            opposite: true,
            title: {
                text: null
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                enableMouseTracking: false,
                allowPointSelect: false
            }
        },
        series: [
            {
                type: 'line',
                name: 'Bitrate',
                data: JSON.parse(refBitrateListPerSec.replace(/\bNaN\b/g, "null")),
                marker: {
                    enabled: false
                }
            },
            {
                type: 'line',
                name: 'Bitrate',
                data: JSON.parse(encBitrateListPerSec.replace(/\bNaN\b/g, "null")),
                marker: {
                    enabled: false
                }
            }
        ]
    })
    const [avgRef, setAvgRef] = React.useState(0)
    const [avgEnc, setAvgEnc] = React.useState(0)

    React.useEffect(() => {
        if (vmafReduct) setVmafChartOptions({series: [{data: JSON.parse(vmafReduct)}]})
    }, [vmafReduct])

    React.useEffect(() => {
        if (psnrReduct) setPsnrChartOptions({series: [{data: JSON.parse(psnrReduct)}]})
    }, [psnrReduct])

    React.useEffect( () => {
        if (refBitrateListPerSec && encBitrateListPerSec){
            setBitrateChartOptions({series: [
                    {data:JSON.parse(refBitrateListPerSec.replace(/\bNaN\b/g, "null"))},
                    {data:JSON.parse(encBitrateListPerSec.replace(/\bNaN\b/g, "null"))}
                ]})
            let sumRef = JSON.parse(refBitrateListPerSec.replace(/\bNaN\b/g, "null")).reduce((a, b) => a + b, 0);
            setAvgRef(sumRef / JSON.parse(refBitrateListPerSec.replace(/\bNaN\b/g, "null")).length)
            let sumEnc = JSON.parse(encBitrateListPerSec.replace(/\bNaN\b/g, "null")).reduce((a, b) => a + b, 0);
            setAvgEnc(sumEnc / JSON.parse(encBitrateListPerSec.replace(/\bNaN\b/g, "null")).length)
        }
    }, [refBitrateListPerSec, encBitrateListPerSec])

    return <>
        <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
            <View width={"600px"}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={vmafChartOptions}
                    ref={vmafChartRef}
                />
            </View>
            <Flex direction={"column"} gap={0}>
                <Text>VMAF: {metricVMAF}</Text>
                <Badge size={"small"} variation={"info"}>HD model</Badge>
            </Flex>
        </Flex>
        <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
            <View width={"600px"}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={psnrChartOptions}
                    ref={psnrChartRef}
                />
            </View>
            <Text>PSNR: {metricPsnr}dB</Text>
        </Flex>
        <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
            <View width={"600px"}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={bitrateChartOptions}
                    ref={bitrateChartRef}
                />
            </View>
            <Text>Ref bitrate: {avgRef.toPrecision(5)} Kbps Enc bitrate: {avgEnc.toPrecision(5)} Kbps</Text>
        </Flex>
    </>;
}

function EAStatus(props) {
    const {status, createdAt} = props

    return <>
        <Flex direction={"column"} gap={0}>
            <Tooltip title="Analysis format" placement="right">
                <Text padding={"0px"}>
                    {status}
                </Text>
            </Tooltip>
            <Text padding={"0px"}>
                {createdAt}
            </Text>
        </Flex>
        <Flex height={"40px"}>
            {(status === "Success") ? <></> :
                <Loader
                    height="100%"
                    filledColor="#FF3333"
                    emptyColor="var(--amplify-colors-brand-secondary-100)"
                />}
        </Flex>
    </>;
}

function EAButtons(props) {
    const {encodeAnalysis} = props
    return <Flex direction={"row"} padding={"1rem"}>
        <Tooltip
            title="Share"
            placement="top"
        >
            <Button className="reference-card-button" size="small"
                    onClick={props.onClick}>
                <Icon
                    ariaLabel="Share"
                    viewBox={{width: 24, height: 24}}
                    pathData="M20.000,8.000 C18.788,8.000 17.714,7.447 16.980,6.593 L7.900,11.134 C7.962,11.413 8.000,11.701 8.000,11.998 C8.000,12.269 7.972,12.533 7.920,12.788 L16.984,17.402 C17.718,16.551 18.791,16.000 20.000,16.000 C22.206,16.000 24.000,17.794 24.000,20.000 C24.000,22.206 22.206,24.000 20.000,24.000 C17.794,24.000 16.000,22.206 16.000,20.000 C16.000,19.722 16.029,19.450 16.084,19.188 L7.031,14.580 C6.297,15.441 5.218,16.000 4.000,16.000 C1.794,16.000 0.000,14.205 0.000,11.998 C0.000,9.793 1.794,7.998 4.000,7.998 C5.190,7.998 6.248,8.530 6.981,9.357 L16.082,4.806 C16.028,4.545 16.000,4.276 16.000,4.000 C16.000,1.794 17.794,0.000 20.000,0.000 C22.206,0.000 24.000,1.794 24.000,4.000 C24.000,6.206 22.206,8.000 20.000,8.000 ZM20.000,22.000 C21.103,22.000 22.000,21.103 22.000,20.000 C22.000,18.897 21.103,18.000 20.000,18.000 C18.897,18.000 18.000,18.897 18.000,20.000 C18.000,21.103 18.897,22.000 20.000,22.000 ZM4.000,9.998 C2.897,9.998 2.000,10.895 2.000,11.998 C2.000,13.102 2.897,14.000 4.000,14.000 C5.103,14.000 6.000,13.102 6.000,11.998 C6.000,10.895 5.103,9.998 4.000,9.998 ZM20.000,2.000 C18.897,2.000 18.000,2.897 18.000,4.000 C18.000,5.103 18.897,6.000 20.000,6.000 C21.103,6.000 22.000,5.103 22.000,4.000 C22.000,2.897 21.103,2.000 20.000,2.000 Z"
                />
            </Button>
        </Tooltip>
        <Popup
            modal
            closeOnDocumentClick
            open={props.open}
            onClose={props.onClose}
        >
            <SharePopup
                onClick={props.onClick1}
                shareWith={props.shareWith}
                onCloseShareWith={props.onCloseShareWith}
                searchFieldValue={props.searchFieldValue}
                onSearchFieldChange={props.onSearchFieldChange}
                onSearchFieldSubmit={props.onSearchFieldSubmit}
                onSearchFieldClear={props.onSearchFieldClear}
                shareWithSelection={props.shareWithSelection}
                onClickShareWithSelection={props.onClickShareWithSelection}
                onShareButtonClick={props.onShareButtonClick}
                persons={props.persons}
                teams={props.teams}
            />
        </Popup>
        <Link as={ReactRouterLink} to={"/encode-analysis/" + encodeAnalysis.id}>
            <Tooltip
                title="Details"
                placement="top"
            >
                <Button className="reference-card-button" size="small">
                    <Icon
                        ariaLabel="Info"
                        viewBox={{width: 24, height: 24}}
                        pathData="M20.321,20.485 C18.069,22.752 15.074,24.000 11.887,24.000 C8.700,24.000 5.705,22.752 3.453,20.485 C-1.197,15.806 -1.197,8.193 3.453,3.514 C5.705,1.248 8.700,-0.000 11.887,-0.000 C15.074,-0.000 18.069,1.248 20.321,3.514 C22.574,5.781 23.815,8.794 23.815,12.000 C23.815,15.205 22.574,18.218 20.321,20.485 ZM18.916,4.929 C17.038,3.040 14.542,2.000 11.887,2.000 C9.232,2.000 6.736,3.040 4.858,4.929 C0.984,8.827 0.984,15.172 4.858,19.071 C6.736,20.960 9.232,22.000 11.887,22.000 C14.542,22.000 17.038,20.960 18.916,19.071 C20.793,17.182 21.828,14.671 21.828,12.000 C21.828,9.329 20.793,6.818 18.916,4.929 ZM13.876,19.000 L9.900,19.000 C9.351,19.000 8.906,18.552 8.906,18.000 C8.906,17.447 9.351,17.000 9.900,17.000 L10.894,17.000 L10.894,13.000 L9.900,13.000 C9.351,13.000 8.906,12.552 8.906,12.000 C8.906,11.448 9.351,11.000 9.900,11.000 L11.888,11.000 C12.437,11.000 12.882,11.448 12.882,12.000 L12.882,17.000 L13.876,17.000 C14.425,17.000 14.870,17.447 14.870,18.000 C14.870,18.552 14.425,19.000 13.876,19.000 ZM11.888,9.000 C10.792,9.000 9.900,8.102 9.900,6.998 C9.900,5.896 10.792,5.000 11.888,5.000 C12.984,5.000 13.876,5.896 13.876,6.998 C13.876,8.102 12.984,9.000 11.888,9.000 Z"
                    />
                </Button>
            </Tooltip>
        </Link>
        <Menu
            trigger={
                <MenuButton className="reference-card-button" size="small">
                    <Icon
                        ariaLabel="Trash"
                        viewBox={{width: 24, height: 24}}
                        pathData="M19.000,6.000 L17.910,6.000 L16.105,22.118 C15.986,23.173 15.062,24.000 14.000,24.000 L6.000,24.000 C4.937,24.000 4.014,23.172 3.897,22.116 L2.120,6.000 L1.000,6.000 C0.447,6.000 0.000,5.552 0.000,5.000 C0.000,4.448 0.447,4.000 1.000,4.000 L6.000,4.000 L6.000,3.000 C6.000,1.346 7.346,-0.000 9.000,-0.000 L11.000,-0.000 C12.654,-0.000 14.000,1.346 14.000,3.000 L14.000,4.000 L19.000,4.000 C19.553,4.000 20.000,4.448 20.000,5.000 C20.000,5.552 19.553,6.000 19.000,6.000 ZM12.000,3.000 C12.000,2.449 11.552,2.000 11.000,2.000 L9.000,2.000 C8.448,2.000 8.000,2.449 8.000,3.000 L8.000,4.000 L12.000,4.000 L12.000,3.000 ZM4.132,6.000 L5.885,21.896 C5.889,21.935 5.960,22.000 6.000,22.000 L14.000,22.000 C14.040,22.000 14.113,21.935 14.117,21.894 L15.897,6.000 L4.132,6.000 Z"
                    />
                </MenuButton>
            }
        >
            <MenuItem onClick={props.onClick2}>Confirm</MenuItem>
            <Divider/>
            <MenuItem>Cancel</MenuItem>
        </Menu>
    </Flex>;
}

function EASharing(props) {
    const {account, persons, teams} = props || {}
    const sharedItem = (teams.items || []).map(item =>
        <Tooltip key={item.team.id} title={item.team.name} placement="bottom">
            <div data-initials={item.team.name[0]}/>
        </Tooltip>).concat((persons.items || []).map(item => <Tooltip key={item.account.id} title={item.account.email}
                                                                      placement="bottom">
        <div data-initials={((item.account.first_name ? item.account.first_name[0] : item.account.email[0]) + (item.account.last_name ? item.account.last_name[0] : item.account.email[1])).toUpperCase()}/>
    </Tooltip>))

    const sharedBy = <Tooltip title={account.email} placement={"bottom"}>
        <div data-initials={((account.first_name ? account.first_name[0] : account.email[0]) + (account.last_name ? account.last_name[0] : account.email[1])).toUpperCase()}/>
    </Tooltip>

    return <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"center"} height={"40px"}>
        <Heading level={6}>Shared {props.tabIndex === "0" ? "with" : "by"}</Heading>
        <Flex direction={"row"} gap={"0"}>
            {props.tabIndex === "0" ? sharedItem : sharedBy}
        </Flex>
    </Flex>;
}

function EncodeAnalysisCard(props) {
    const {encodeAnalysis, modalState} = props
    const {account, teams} = encodeAnalysis || {}
    const [persons, setPersons] = React.useState(encodeAnalysis.persons)
    const [refMedia, setRefMedia] = React.useState(encodeAnalysis.refMedia)
    const [encMedia, setEncMedia] = React.useState(encodeAnalysis.encMedia)
    const [status, setStatus] = React.useState(encodeAnalysis.status)
    const [createdAt, setCreatedAt] = React.useState(encodeAnalysis.createdAt)
    const [refBitrateListPerSec, setRefBitrateListPerSec] = React.useState(refMedia['bitrate_list_per_sec'] ? refMedia['bitrate_list_per_sec'] : '[]')
    const [encBitrateListPerSec, setEncBitrateListPerSec] = React.useState(encMedia['bitrate_list_per_sec'] ? encMedia['bitrate_list_per_sec'] : '[]')
    const [vmafReduct, setVmafReduct] = React.useState(encodeAnalysis.vmaf_reduct)
    const [psnrReduct, setPsnrReduct] = React.useState(encodeAnalysis.psnr_reduct)
    const [metricVMAF,setMetricVMAF] = React.useState(encodeAnalysis.metric_vmaf)
    const [metricPsnr, setMetricPsnr] = React.useState(encodeAnalysis.metric_psnr)
    // Subscribe to update Encode Analyses  mutation
    React.useEffect(() => {
        let subscription
        let mounted = true

        async function setupSubscription() {
            subscription = API.graphql(
                graphqlOperation(
                    onUpdatePersonsEncodeAnalysis,
                    {id: encodeAnalysis.id, owner: account.id}
                )).subscribe({
                next: (data) => {
                    if (mounted) {
                        setRefMedia(data['value']['data']['onUpdateEncodeAnalysis']['refMedia'])
                        setEncMedia(data['value']['data']['onUpdateEncodeAnalysis']['encMedia'])
                        setStatus(data['value']['data']['onUpdateEncodeAnalysis']['status'])
                        setCreatedAt(data['value']['data']['onUpdateEncodeAnalysis']['createdAt'])
                        setVmafReduct(data['value']['data']['onUpdateEncodeAnalysis']['vmaf_reduct'])
                        setPsnrReduct(data['value']['data']['onUpdateEncodeAnalysis']['psnr_reduct'])
                        setMetricVMAF(data['value']['data']['onUpdateEncodeAnalysis']['metric_vmaf'])
                        setMetricPsnr(data['value']['data']['onUpdateEncodeAnalysis']['metric_psnr'])
                        setPersons(data['value']['data']['onUpdateEncodeAnalysis']['persons'])
                        setRefBitrateListPerSec(data['value']['data']['onUpdateEncodeAnalysis']['refMedia']['bitrate_list_per_sec'])
                        setEncBitrateListPerSec(data['value']['data']['onUpdateEncodeAnalysis']['encMedia']['bitrate_list_per_sec'])
                    }
                },
                error: error => {
                    console.warn(error);
                }
            })
        }
        async function getMyEncodeAnalysis(){
            let response = await API.graphql(graphqlOperation(getPersonsEncodeAnalysis, {id: encodeAnalysis.id, owner: account.id}))
            return (response['data']['getEncodeAnalysis'])
        }

        if (encodeAnalysis.id && account.id) {
            getMyEncodeAnalysis().then(data => {
                if (mounted && data) {
                    setRefMedia(data['refMedia'])
                    setEncMedia(data['encMedia'])
                    setStatus(data['status'])
                    setCreatedAt(data['createdAt'])
                    setVmafReduct(data['vmaf_reduct'])
                    setPsnrReduct(data['psnr_reduct'])
                    setMetricVMAF(data['metric_vmaf'])
                    setMetricPsnr(data['metric_psnr'])
                    setRefBitrateListPerSec(data['refMedia']['bitrate_list_per_sec'])
                    setEncBitrateListPerSec(data['encMedia']['bitrate_list_per_sec'])
                    setPersons(data['persons'])
                }})

            setupSubscription().then()
            return () => {
                mounted = false
                if (subscription) {
                    subscription.unsubscribe()
                }
            }
        }
    }, [encodeAnalysis.id, account.id, encodeAnalysis.status])

    function handleUpdatePersons() {
        setTimeout(() => { API.graphql(graphqlOperation(getPersonsEncodeAnalysis, {id: encodeAnalysis.id, owner: account.id})).then(response => setPersons(response['data']['getEncodeAnalysis']['persons']))}, 2000)
        setTimeout(() => { API.graphql(graphqlOperation(getPersonsEncodeAnalysis, {id: encodeAnalysis.id, owner: account.id})).then(response => setPersons(response['data']['getEncodeAnalysis']['persons']))}, 4000)
    }


    return (
        <Card
            key={encodeAnalysis.id}
            id={encodeAnalysis.id}
            padding="0rem"
            variation="elevated"
        >
            <Flex justifyContent="space-between" gap="5px">
                <SynamediaStrip/>
                <Flex direction="column" gap={"3px"} alignItems={"flex-start"}>
                    <Flex direction={"row"}>
                        <EAMedia media={refMedia} encodeAnalysis={encodeAnalysis}/>
                        <EAMedia media={encMedia} encodeAnalysis={encodeAnalysis}/>
                    </Flex>
                    <Flex direction={"column"} gap={"2px"}>
                        <EAData
                            refBitrateListPerSec={refBitrateListPerSec}
                            encBitrateListPerSec={encBitrateListPerSec}
                            vmafReduct={vmafReduct}
                            psnrReduct={psnrReduct}
                            metricVMAF={metricVMAF}
                            metricPsnr={metricPsnr}
                        />
                        <Flex direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <EAStatus status={status} createdAt={createdAt}/>
                            <EAButtons
                                onClick={() => {
                                    props.onOpenModal(encodeAnalysis.id)
                                }}
                                open={modalState}
                                onClose={() => {
                                    props.onCloseModal()
                                }}
                                onClick1={() => {
                                    props.onCloseModal()
                                }}
                                shareWith={props.shareWith}
                                onCloseShareWith={(id) => props.onCloseShareWith(id)}
                                searchFieldValue={props.searchFieldValue}
                                onSearchFieldChange={props.onSearchFieldChange}
                                onSearchFieldSubmit={props.onSearchFieldSubmit}
                                onSearchFieldClear={props.onSearchFieldClear}
                                shareWithSelection={props.shareWithSelection}
                                onClickShareWithSelection={(item) => props.onClickShareWithSelection(item)}
                                onShareButtonClick={() => {
                                    props.onShareButtonClick();
                                    props.onCloseModal()
                                    handleUpdatePersons()
                                }}
                                persons={persons}
                                teams={teams}
                                encodeAnalysis={encodeAnalysis}
                                onClick2={props.onRemoveEncodeAnalysis}
                            />
                        </Flex>
                    </Flex>
                    <Divider/>
                    <EASharing tabIndex={props.tabIndex} persons={persons} teams={teams} account={account}/>
                </Flex>
            </Flex>
        </Card>
    )
}

export default EncodeAnalysisCard
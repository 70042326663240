// eslint-disable-next-line no-unused-vars
import React from 'react';
import {API, Auth} from 'aws-amplify';


export async function postMedia(filename) {
    const apiName = 'vqaasapi';
    const path = '/media-cognito';
    const user = await Auth.currentAuthenticatedUser()
    const token = user['signInUserSession']['idToken']['jwtToken']
    let myInit = {
        headers: {
            Authorization: token,
        },
        body: {'filename': filename}
    }
    return API.post(apiName, path, myInit);
}

export async function putMediaShare(id, shareWithId, type) {
    const apiName = 'vqaasapi';
    const path = '/media-cognito/' + id + '/share';
    const user = await Auth.currentAuthenticatedUser()
    const token = user['signInUserSession']['idToken']['jwtToken']
    let myInit = {
        headers: {
            Authorization: token,
        },
        body:
            {
                'share_with_id': shareWithId,
                'type': type
            }
    }
    return API.put(apiName, path, myInit);
}

export async function putMedia(media_id) {
    const apiName = 'vqaasapi';
    const path = '/media-cognito/' + media_id + '/import';
    const user = await Auth.currentAuthenticatedUser()
    const token = user['signInUserSession']['idToken']['jwtToken']
    let myInit = {
        headers: {
            Authorization: token,
        }
    }
    return API.put(apiName, path, myInit);
}

export async function getMedia(media_id) {
    const apiName = 'vqaasapi';
    const path = '/media-cognito/' + media_id;
    const user = await Auth.currentAuthenticatedUser()
    const token = user['signInUserSession']['idToken']['jwtToken']
    let myInit = {
        headers: {
            Authorization: token,
        }
    }
    return API.get(apiName, path, myInit);
}

export async function deleteMedia(id) {
    const apiName = 'vqaasapi';
    const path = '/media-cognito/' + id;
    const user = await Auth.currentAuthenticatedUser()
    const token = user['signInUserSession']['idToken']['jwtToken']
    let myInit = {
        headers: {
            Authorization: token,
        }
    }
    return API.del(apiName, path, myInit);
}

export async function putChannel(id) {
    const apiName = 'vqaasapi';
    const path = '/channel-cognito/' + id;
    const user = await Auth.currentAuthenticatedUser()
    const token = user['signInUserSession']['idToken']['jwtToken']
    let myInit = {
        headers: {
            Authorization: token,
        },
        body:
            {
                'lowVQ': true
            }
    }
    return API.put(apiName, path, myInit);
}

export async function putEncodeAnalysis(id, body) {
    const apiName = 'vqaasapi';
    const path = '/encode-analysis-cognito/' + id;
    const user = await Auth.currentAuthenticatedUser()
    const token = user['signInUserSession']['idToken']['jwtToken']
    let myInit = {
        headers: {
            Authorization: token,
        },
        body: body
    }
    return API.put(apiName, path, myInit);
}

export async function putEncodeAnalysisRenew(id) {
    const apiName = 'vqaasapi';
    const path = '/encode-analysis-cognito/' + id + '/renew';
    const user = await Auth.currentAuthenticatedUser()
    const token = user['signInUserSession']['idToken']['jwtToken']
    let myInit = {
        headers: {
            Authorization: token,
        }
    }
    return API.put(apiName, path, myInit);
}

export async function putMediaRenew(id) {
    const apiName = 'vqaasapi';
    const path = '/media-cognito/' + id + '/renew';
    const user = await Auth.currentAuthenticatedUser()
    const token = user['signInUserSession']['idToken']['jwtToken']
    let myInit = {
        headers: {
            Authorization: token,
        }
    }
    return API.put(apiName, path, myInit);
}
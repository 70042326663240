import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {API, graphqlOperation} from "aws-amplify";
import {Button, Collection, Flex, Heading, Image, Text, View, ThemeProvider, Theme} from '@aws-amplify/ui-react';
import background from 'assets/img/synamedia/viewer-digital-twin.jpg';
import heroBgMd from 'assets/img/synamedia/hero-bg-md.png';
import sourcePNG from 'assets/img/video-hd-icon-64x36.png';
import {BsRecordCircle} from 'react-icons/bs';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import networkGraph from 'highcharts/modules/networkgraph.js';
import colorAxis from 'highcharts/modules/coloraxis.js';
import HighchartsReact from 'highcharts-react-official';
import ViewerDigitalTwinMonitorCard from "../../components/vqaas/ViewerDigitalTwinMonitorCard";

highchartsMore(Highcharts);
networkGraph (Highcharts);
colorAxis(Highcharts);

const myTheme = {
    name: 'myCollection-theme',
    tokens: {
        components: {
            fieldcontrol: {
                color: { value: 'white' },
            },
            select: {
                icon: {
                    wrapper: {
                        color: {value: 'white'},
                    },
                }
            },
            pagination: {
                current: {
                    color: { value: 'black' },
                    backgroundColor: {
                        value: 'white',
                    },
                },
                button: {
                    color: { value: 'white' },
                    _hover: {
                        backgroundColor: {
                            value: 'whitesmoke',
                        },
                        color: { value: 'black' },
                    },
                },
            },
            button: {
                color: { value: 'white' },
                _focus: {
                    backgroundColor: {
                        value: 'white',
                    },
                    color: {
                        value: 'black',
                    },
                },
                _hover: {
                    backgroundColor: {
                        value: 'white',
                    },
                    color: {
                        value: 'black',
                    },
                },
            },
            collection: {
                pagination: {
                    current: {
                        color: { value: 'black' },
                        backgroundColor: {
                            value: 'white',
                        },
                    },
                    button: {
                        color: { value: 'white' },
                        _hover: {
                            backgroundColor: {
                                value: 'white',
                            },
                            color: { value: 'black' },
                        },
                    },
                },
                search: {
                    input: {
                        color: { value: 'white' },
                    },
                    button: {
                        color: { value: 'white' },
                        _focus: {
                            backgroundColor: {
                                value: 'white',
                            },
                            color: {
                                value: 'black',
                            },
                        },
                        _hover: {
                            backgroundColor: {
                                value: 'white',
                            },
                            color: {
                                value: 'black',
                            },
                        },
                    },
                },
            },
        },
    },
};

const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      name
      owner
      inAlarm
      unAcknowledged
      lowVQ
      rootID
      root {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        vqm {
          nextToken
        }
        inAlarm
        unAcknowledged
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
      }
      createdAt
      updatedAt
    }
  }
`;

function ViewerDigitalTwinChannel(props) {
    const {channel_id} = useParams()
    const [monitorPoints, setMonitorPoints] = React.useState([
        {id: '03c2e699-1cee-4540-b505-5f4dcbad94fe', name: 'Source', format: '1280x720p50', codec: 'AVC', bitrate: '26000 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: 'e178ce98-5f41-4cd8-a7a2-252dfb0ba8cd', from: 'Source', to: 'Mux', name: 'Mux', format: '1280x720p50', codec: 'AVC', bitrate: '26000 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: '9a4682cb-e2b4-4b8c-980b-f9273d1051cd', from: 'Mux', to: 'Decoder ABR', name: 'Decoder ABR', format: '1280x720p50', codec: 'AVC', bitrate: '26000 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: 'd20a79c4-a90b-42cc-8096-fff23e9cbdfd', from: 'Mux', to: 'Decoder CBR', name: 'Decoder CBR', format: '1280x720p50', codec: 'AVC', bitrate: '26000 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: '68eff4bf-1137-43a7-afd0-1e2040c446e8', from: 'Decoder CBR', to: 'CBR#1', name: 'CBR#1', format: '1280x720p50', codec: 'AVC', bitrate: '16000 kbps', score: 100, inAlarm: true, unAcknowledged: true},
        {id: '46718190-d11a-4688-98ab-7150e3066fca', from: 'Decoder ABR', to: 'ABR#1', name: 'ABR#1', format: '1280x720p25', codec: 'AVC', bitrate: '2650 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: '5d03af6f-e25a-4abc-bc9b-7570c712cddc', from: 'Decoder ABR', to: 'ABR#2', name: 'ABR#2', format: '960x540p25', codec: 'AVC', bitrate: '1620 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: 'a7fda52a-2722-49ac-8420-a109972c8db3', from: 'Decoder ABR', to: 'ABR#3', name: 'ABR#3', format: '768x432p25', codec: 'AVC', bitrate: '1160 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: 'f8284e92-5156-426d-b1b0-eeb25c503477', from: 'Decoder ABR', to: 'ABR#4', name: 'ABR#4', format: '640x360p25', codec: 'AVC', bitrate: '864 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: 'a27ac715-0b7c-460f-a235-549a43e37991', from: 'Decoder ABR', to: 'ABR#5', name: 'ABR#5', format: '512x288p25', codec: 'AVC', bitrate: '645 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: '3daee515-a7e2-4f62-a47d-5e7ff3ecb551', from: 'Decoder ABR', to: 'ABR#6', name: 'ABR#6', format: '384x216p12.5', codec: 'AVC', bitrate: '342 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {from: 'CBR#1', to: 'CBR#1_o', name: 'CBR#1_o', format: '1280x720p50', codec: 'AVC', bitrate: '16000 kbps', score: 100, inAlarm: true, unAcknowledged: true},
        {from: 'ABR#1', to: 'ABR#1_o', name: 'ABR#1_o', format: '1280x720p25', codec: 'AVC', bitrate: '2650 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {from: 'ABR#2', to: 'ABR#2_o', name: 'ABR#2_o', format: '960x540p25', codec: 'AVC', bitrate: '1620 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {from: 'ABR#3', to: 'ABR#3_o', name: 'ABR#3_o', format: '768x432p25', codec: 'AVC', bitrate: '1160 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {from: 'ABR#4', to: 'ABR#4_o', name: 'ABR#4_o', format: '640x360p25', codec: 'AVC', bitrate: '864 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {from: 'ABR#5', to: 'ABR#5_o', name: 'ABR#5_o', format: '512x288p25', codec: 'AVC', bitrate: '645 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {from: 'ABR#6', to: 'ABR#6_o', name: 'ABR#6_o', format: '384x216p12.5', codec: 'AVC', bitrate: '342 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        // {id: '12', name: 'CBR#1', format: '1920x1080p50', codec: 'AVC', bitrate: '8500 kbps', score: '70'},
        // {id: '13', name: 'ABR#1', format: '1920x1080p50', codec: 'AVC', bitrate: '8500 kbps', score: '70'},
        // {id: '14', name: 'ABR#2', format: '1920x1080p50', codec: 'AVC', bitrate: '8500 kbps', score: '70'},
        // {id: '15', name: 'ABR#3', format: '1920x1080p50', codec: 'AVC', bitrate: '8500 kbps', score: '70'},
        // {id: '16', name: 'ABR#4', format: '1920x1080p50', codec: 'AVC', bitrate: '8500 kbps', score: '70'},
        // {id: '17', name: 'ABR#5', format: '1920x1080p50', codec: 'AVC', bitrate: '8500 kbps', score: '70'},
        // {id: '18', name: 'ABR#6', format: '1920x1080p50', codec: 'AVC', bitrate: '8500 kbps', score: '70'},
    ])
    const [networkGraphOptions, setNetworkGraphOptions] = React.useState({
        chart: {
            type: 'networkgraph',
            height: '750px',
            backgroundColor: 'transparent',
            events: {
                load() {
                    this.series[0].nodes[0].fixedPosition = true;
                }
            },
            style: {
                fontFamily: 'monospace',
                color: "#f00"
            }
        },
        colorAxis: {
            min: 0,
            max: 100,
            stops: [
                [0.25, '#FF3333'], // red
                [0.55, '#FF3333'], // red
                [0.75, '#FFC41C'], // yellow
                [0.9, '#37F713'], // green
                [1, '#37F713'] // green
            ]
        },
        credits: {
            enabled: false
        },
        title: {
            text: 'Channel Topology',

            style: { "color": "white", "fontSize": "18px" }
        },
        tooltip: {enabled: false},
        subtitle: {
            visible: false
        },
        plotOptions: {
            networkgraph: {
                keys: ['from', 'to'],
                link: {
                    color: 'rgb(0,255,0)',
                    width: 4
                },
                layoutAlgorithm: {
                    enableSimulation: false,
                    friction: -0.9,
                    initialPositions: function () {
                        let chart = this.series[0].chart,
                            width = chart.plotWidth,
                            height = chart.plotHeight;

                        this.nodes.forEach(function (node) {
                            // If initial positions were set previously, use that
                            // positions. Otherwise use random position:
                            if (node.id === 'Source') {
                                node.plotX = node.plotX === undefined ?
                                    30 : node.plotX;
                                node.plotY = node.plotY === undefined ?
                                    height / 2 : node.plotY;
                            } else {
                                node.plotX = node.plotX === undefined ?
                                    Math.random() * width : node.plotX;
                                node.plotY = node.plotY === undefined ?
                                    Math.random() * height : node.plotY;
                            }
                        });
                    },
                    linkLength: 35
                }
            },
            series: {
                cursor: 'pointer',
                states: {
                    inactive: {
                        enabled: false
                    }
                },
                point: {
                    events: {
                        click: function (event) {
                            let click_id = this.id
                            if (click_id === 'CBR#1') history.push("/viewerdigitaltwin/monitorpoint/68eff4bf-1137-43a7-afd0-1e2040c446e8")
                            if (click_id === 'ABR#1') history.push("/viewerdigitaltwin/monitorpoint/46718190-d11a-4688-98ab-7150e3066fca")
                            if (click_id === 'ABR#2') history.push("/viewerdigitaltwin/monitorpoint/5d03af6f-e25a-4abc-bc9b-7570c712cddc")
                            if (click_id === 'ABR#3') history.push("/viewerdigitaltwin/monitorpoint/a7fda52a-2722-49ac-8420-a109972c8db3")
                            if (click_id === 'ABR#4') history.push("/viewerdigitaltwin/monitorpoint/f8284e92-5156-426d-b1b0-eeb25c503477")
                            if (click_id === 'ABR#5') history.push("/viewerdigitaltwin/monitorpoint/a27ac715-0b7c-460f-a235-549a43e37991")
                            if (click_id === 'ABR#6') history.push("/viewerdigitaltwin/monitorpoint/3daee515-a7e2-4f62-a47d-5e7ff3ecb551")
                        },
                    }
                }
            }
        },
        series: [{
            accessibility: {
                enabled: false
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontFamily: 'monospace',
                    fontSize: '14px',
                    color: 'rgb(192,255,0)'
                },
                linkFormat: ''
            },
            id: 'lang-tree',
            data: monitorPoints.filter((item) => item.from),
            nodes:[
                {
                    id: 'Source',
                    mass: 100,
                    marker: {
                        radius: 5,
                        // symbol: `url(${sourcePNG})`
                        }
                },
            ]
        }]
    })

    const networkGraphRef = React.useRef()
    const history  = useHistory();

    const onBackToDashboard = () => {
        history.push("/viewerdigitaltwin/dashboard");
    }

    // React.useEffect(() => {
    //     let mounted = true
    //
    //     async function getMyChannel(){
    //         // console.log(channel_id)
    //         let response = await API.graphql(graphqlOperation(getChannel, {id: channel_id}))
    //         return (response['data']['getChannel'])
    //     }
    //
    //     getMyChannel().then(data => {
    //         if (mounted && data) {
    //             console.log(data)
    //         }
    //     })
    //     return () => {
    //         mounted = false
    //     }
    // }, [channel_id])

    function networkColor(score) {
        if (score <= 50){
            return 'rgb(255,0,0)'
        }
        else if (score <= 75){
            return 'rgb(' + 255 + ', ' + Math.floor((score-50) * 255 / 25) + ', ' + 0 + ')'
        } else {
            return 'rgb(' + Math.floor(255 - 255 * (score - 75) / 25) + ', ' + 255 + ', ' + 0 + ')'
        }
    }

    React.useEffect(() => {
        // console.log(monitorPoints)
        setNetworkGraphOptions({series: [{data: [
                {
                    color: networkColor(monitorPoints[1]['score']),
                },
                {
                    color: networkColor(monitorPoints[2]['score']),
                },
                {
                    color: networkColor(monitorPoints[3]['score']),
                },
                {
                    color: networkColor(monitorPoints[4]['score']),
                },
                {
                    color: networkColor(monitorPoints[5]['score']),
                },
                {
                    color: networkColor(monitorPoints[6]['score']),
                },
                {
                    color: networkColor(monitorPoints[7]['score']),
                },
                {
                    color: networkColor(monitorPoints[8]['score']),
                },
                {
                    color: networkColor(monitorPoints[9]['score']),
                },
                {
                    color: networkColor(monitorPoints[10]['score']),
                },
                {
                    color: networkColor(monitorPoints[4]['score']),
                },
                {
                    color: networkColor(monitorPoints[5]['score']),
                },
                {
                    color: networkColor(monitorPoints[6]['score']),
                },
                {
                    color: networkColor(monitorPoints[7]['score']),
                },
                {
                    color: networkColor(monitorPoints[8]['score']),
                },
                {
                    color: networkColor(monitorPoints[9]['score']),
                },
                {
                    color: networkColor(monitorPoints[10]['score']),
                }

                ]}]})
    }, [monitorPoints]);

    React.useEffect(() => {window.scrollTo(0, 0)}, [])

    const handleOnClick = (monitorpointId) => {
        history.push("/viewerdigitaltwin/monitorpoint/"+ monitorpointId);
    }

    // console.log(alarms)
    return (
        <ThemeProvider theme={myTheme}>
            <div className="section" style={{paddingBottom:0, paddingTop:0, backgroundColor: '#000000'}}>
                <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
                    <View width={'1024px'} backgroundColor={"black"}>
                        {/*Header*/}
                        <Flex direction={"column"} gap={0} alignItems={"center"}>
                            <View
                                height={"120px"}
                                width={"100%"}
                                backgroundColor={"black"}
                            >
                                <Flex
                                    direction={"row"}
                                    padding={"20px"}
                                    gap={"0px"}
                                    alignItems={"center"}
                                >
                                    <Image
                                        height={'57px'}
                                        src="https://www.synamedia.com/wp-content/themes/synamedia/assets/svg/synamedia-icon-white.svg"
                                        alt="Synamedia"/>
                                    <Image
                                        style={{marginTop: '17px', marginLeft:'27px'}}
                                        height={'30px'}
                                        src="https://www.synamedia.com/wp-content/themes/synamedia/assets/svg/synamedia-white.svg"
                                        alt="Synamedia"/>
                                    <Heading level={1} color={"white"} padding={"8px"} style={{marginLeft: '75px'}}>Viewer Digital Twin</Heading>
                                </Flex>
                            </View>
                            <View
                                minHeight={"480px"}
                                height={"480px"}
                                width={"100%"}
                                style={{
                                    backgroundSize: "cover",
                                    backgroundPosition: "center top",
                                    backgroundRepeat: "no-repeat",
                                    backgroundImage: `url(${background})`,
                                    overflow: "hidden"
                                }}
                            >
                                <View
                                    minHeight={"100%"}
                                    height={"100%"}
                                    width={"400px"}
                                    style={{
                                        backgroundSize: "contain",
                                        backgroundPosition: "left top",
                                        backgroundRepeat: "no-repeat",
                                        backgroundImage: `url(${heroBgMd})`,
                                        opacity: '0.9',
                                        overflow: "hidden"
                                    }}
                                >
                                    <Flex direction={"column"} gap={0}>
                                        <Text color={"white"} style={{fontSize: '14px'}}>Home > Viewer Digital Twin > Channel View</Text>
                                        <Text
                                            color={"white"}
                                            style={{fontSize: '40px', lineHeight: '46px', fontWeight: '700', marginBottom: '20px'}}
                                        >
                                            Real-time Video Quality Measurement and Monitoring
                                        </Text>
                                        <Text
                                            color={"white"}
                                            style={{fontSize: '16px', lineHeight: '26px', fontWeight: '400', marginBottom: '8px'}}
                                        >
                                            Video Quality Monitoring the End-to-End system: alarm management, automatic recording, collect analytic data, root cause analysis, ….
                                        </Text>
                                        <Text
                                            color={"white"}
                                            style={{fontSize: '16px', lineHeight: '26px', fontWeight: '400', marginBottom: '8px'}}
                                        >
                                            Video Quality Analysis when quality problems occur
                                        </Text>
                                        <Text
                                            color={"white"}
                                            width={'350px'}
                                            style={{fontSize: '16px', lineHeight: '26px', fontWeight: '400', marginBottom: '8px'}}
                                        >
                                            The Video Quality Dashboard – where it’s easy to see the problems and additional suggestions on what the cause is (future)
                                        </Text>
                                    </Flex>
                                </View>
                            </View>
                        </Flex>
                        {/*Channel Topology*/}
                        <Flex direction={"column"} justifyContent={"flex-start"}>
                            <Flex direction={"row"} justifyContent={"space-between"}>
                                <Heading
                                    level={3}
                                    color={'white'}
                                    style={{marginTop: '5px'}}
                                >
                                    Mountain Bike Channel
                                </Heading>
                                <Flex direction={"row"} justifyContent={'space-between'} paddingTop={'10px'}>
                                    <Button
                                        size="small"
                                        className="reference-card-button"
                                        isDisabled={false}
                                        variation="primary"
                                        style={{paddingTop: '2px', paddingBottom: '2px'}}
                                        gap={"0.5rem"}
                                        // onClick={(e) => setCompareCollection([])}
                                    >
                                        <BsRecordCircle color={'black'}/>
                                        Record
                                    </Button>
                                    <Button
                                        size="small"
                                        className="reference-card-button"
                                        isDisabled={false}
                                        variation="primary"
                                        style={{paddingTop: '2px', paddingBottom: '2px'}}
                                        onClick={(e) => onBackToDashboard()}
                                    >
                                        Back to dashboard
                                    </Button>
                                </Flex>
                            </Flex>
                            <Flex width={"100%"} paddingTop={'10px'}>
                                <Collection
                                    type="grid"
                                    templateColumns="1fr 1fr 1fr 1fr 1fr"
                                    gap="5px"
                                    items={monitorPoints.filter((item) => item.id)}
                                >
                                    {(monitorPoint, index) => (
                                        <ViewerDigitalTwinMonitorCard
                                            key={index}
                                            monitorId={monitorPoint.id}
                                            monitorName={monitorPoint.name}
                                            monitorFormat={monitorPoint.format}
                                            monitorCodec={monitorPoint.codec}
                                            monitorBitrate={monitorPoint.bitrate}
                                            monitorScore={monitorPoint.score}
                                            monitorInAlarm={monitorPoint.inAlarm}
                                            monitorUnAcknowledged={monitorPoint.unAcknowledged}
                                            onClick={()=>handleOnClick(monitorPoint.id)}
                                            onScore={(score)=> setMonitorPoints(oldMonitorPoints =>
                                                oldMonitorPoints.map(oldMonitorPoint =>
                                                    oldMonitorPoint.id === monitorPoint.id ?
                                                        {
                                                            id: oldMonitorPoint.id,
                                                            from: oldMonitorPoint.from,
                                                            to: oldMonitorPoint.to,
                                                            name: oldMonitorPoint.name,
                                                            format: oldMonitorPoint.format,
                                                            codec: oldMonitorPoint.codec,
                                                            bitrate: oldMonitorPoint.bitrate,
                                                            score: score,
                                                            inAlarm: oldMonitorPoint.inAlarm,
                                                            unAcknowledged: oldMonitorPoint.unAcknowledged
                                                        } :
                                                        {
                                                            id: oldMonitorPoint.id,
                                                            from: oldMonitorPoint.from,
                                                            to: oldMonitorPoint.to,
                                                            name: oldMonitorPoint.name,
                                                            format: oldMonitorPoint.format,
                                                            codec: oldMonitorPoint.codec,
                                                            bitrate: oldMonitorPoint.bitrate,
                                                            score: oldMonitorPoint.score,
                                                            inAlarm: oldMonitorPoint.inAlarm,
                                                            unAcknowledged: oldMonitorPoint.unAcknowledged
                                                        }
                                                ))}
                                        />
                                    )}
                                </Collection>
                            </Flex>
                            <Flex direction={"column"} justifyContent={"flex-start"} width={"100%"}>
                                <Flex direction={"column"} justifyContent={"flex-start"}>
                                    <Flex padding={"10px"} width={"100%"} height={"800px"}>
                                        <View width={"100%"}>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                options={networkGraphOptions}
                                                ref={networkGraphRef}
                                            />
                                        </View>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </View>
                </Flex>
            </div>
        </ThemeProvider>
    );
}

export default ViewerDigitalTwinChannel;

import React from "react";
import {Flex, Heading, Image, Text, View} from '@aws-amplify/ui-react';
import background from 'assets/img/synamedia/viewer-digital-twin.jpg';
import heroBgMd from 'assets/img/synamedia/hero-bg-md.png';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import networkGraph from 'highcharts/modules/networkgraph.js';
import colorAxis from 'highcharts/modules/coloraxis.js';
import ViewerDigitalTwinServiceCard from "../../components/vqaas/ViewerDigitalTwinServiceCard";
import {useParams} from "react-router-dom";

highchartsMore(Highcharts);
networkGraph (Highcharts);
colorAxis(Highcharts);


function ViewerDigitalTwinMonitorPoint(props) {
    const {monitorpoint_id} = useParams()
    React.useEffect(() => {window.scrollTo(0, 0)}, [])

    // console.log(alarms)
    return (
        <>
            <div className="section" style={{paddingBottom:0, paddingTop:0, backgroundColor: '#000000'}}>
                <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
                    <View width={'1024px'} height={'1400px'} backgroundColor={"black"}>
                        {/*Header*/}
                        <Flex direction={"column"} gap={0} alignItems={"center"}>
                            <View
                                height={"120px"}
                                width={"100%"}
                                backgroundColor={"black"}
                            >
                                <Flex
                                    direction={"row"}
                                    padding={"20px"}
                                    gap={"0px"}
                                    alignItems={"center"}
                                >
                                    <Image
                                        height={'57px'}
                                        src="https://www.synamedia.com/wp-content/themes/synamedia/assets/svg/synamedia-icon-white.svg"
                                        alt="Synamedia"/>
                                    <Image
                                        style={{marginTop: '17px', marginLeft:'27px'}}
                                        height={'30px'}
                                        src="https://www.synamedia.com/wp-content/themes/synamedia/assets/svg/synamedia-white.svg"
                                        alt="Synamedia"/>
                                    <Heading level={1} color={"white"} padding={"8px"} style={{marginLeft: '75px'}}>Viewer Digital Twin</Heading>
                                </Flex>
                            </View>
                            {/*<View*/}
                            {/*    minHeight={"480px"}*/}
                            {/*    height={"480px"}*/}
                            {/*    width={"100%"}*/}
                            {/*    style={{*/}
                            {/*        backgroundSize: "cover",*/}
                            {/*        backgroundPosition: "center top",*/}
                            {/*        backgroundRepeat: "no-repeat",*/}
                            {/*        backgroundImage: `url(${background})`,*/}
                            {/*        overflow: "hidden"*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <View*/}
                            {/*        minHeight={"100%"}*/}
                            {/*        height={"100%"}*/}
                            {/*        width={"400px"}*/}
                            {/*        style={{*/}
                            {/*            backgroundSize: "contain",*/}
                            {/*            backgroundPosition: "left top",*/}
                            {/*            backgroundRepeat: "no-repeat",*/}
                            {/*            backgroundImage: `url(${heroBgMd})`,*/}
                            {/*            opacity: '0.9',*/}
                            {/*            overflow: "hidden"*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <Flex direction={"column"} gap={0}>*/}
                            {/*            <Text color={"white"} style={{fontSize: '14px'}}>Home > Viewer Digital Twin > Inspection point</Text>*/}
                            {/*            <Text*/}
                            {/*                color={"white"}*/}
                            {/*                style={{fontSize: '40px', lineHeight: '46px', fontWeight: '700', marginBottom: '20px'}}*/}
                            {/*            >*/}
                            {/*                Real-time Video Quality Measurement and Monitoring*/}
                            {/*            </Text>*/}
                            {/*            <Text*/}
                            {/*                color={"white"}*/}
                            {/*                style={{fontSize: '16px', lineHeight: '26px', fontWeight: '400', marginBottom: '8px'}}*/}
                            {/*            >*/}
                            {/*                Video Quality Monitoring the End-to-End system: alarm management, automatic recording, collect analytic data, root cause analysis, ….*/}
                            {/*            </Text>*/}
                            {/*            <Text*/}
                            {/*                color={"white"}*/}
                            {/*                style={{fontSize: '16px', lineHeight: '26px', fontWeight: '400', marginBottom: '8px'}}*/}
                            {/*            >*/}
                            {/*                Video Quality Analysis when quality problems occur*/}
                            {/*            </Text>*/}
                            {/*            <Text*/}
                            {/*                color={"white"}*/}
                            {/*                width={'350px'}*/}
                            {/*                style={{fontSize: '16px', lineHeight: '26px', fontWeight: '400', marginBottom: '8px'}}*/}
                            {/*            >*/}
                            {/*                The Video Quality Dashboard – where it’s easy to see the problems and additional suggestions on what the cause is (future)*/}
                            {/*            </Text>*/}
                            {/*        </Flex>*/}
                            {/*    </View>*/}
                            {/*</View>*/}
                        </Flex>
                        {/*Service*/}
                        <Flex id="vdtmpheader" direction={"row"} justifyContent={"flex-start"} width={'100%'}>
                            <Flex direction={"column"} justifyContent={"flex-start"} padding={"10px"}>
                                <ViewerDigitalTwinServiceCard
                                    monitorPointId={monitorpoint_id}
                                />
                            </Flex>
                        </Flex>
                    </View>
                </Flex>
            </div>
        </>
    );
}

export default ViewerDigitalTwinMonitorPoint;

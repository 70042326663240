import React from "react";
import { useHistory } from "react-router-dom";

import {
    Collection, Flex, Heading, Image, SelectField, Text, View,  ThemeProvider, Theme} from '@aws-amplify/ui-react';
import background from 'assets/img/synamedia/viewer-digital-twin.jpg';
import {API, graphqlOperation} from 'aws-amplify';
import ChannelCard from "../../components/vqaas/ChannelCard";
import heroBgMd from "../../assets/img/synamedia/hero-bg-md.png";

const myTheme = {
    name: 'myCollection-theme',
    tokens: {
        components: {
            fieldcontrol: {
                color: { value: 'white' },
            },
            select: {
                icon: {
                    wrapper: {
                        color: {value: 'white'},
                    },
                }
            },
            pagination: {
                current: {
                    color: { value: 'black' },
                    backgroundColor: {
                        value: 'white',
                    },
                },
                button: {
                    color: { value: 'white' },
                    _hover: {
                        backgroundColor: {
                            value: 'whitesmoke',
                        },
                        color: { value: 'black' },
                    },
                },
            },
            button: {
                color: { value: 'white' },
                _focus: {
                    backgroundColor: {
                        value: 'white',
                    },
                    color: {
                        value: 'black',
                    },
                },
                _hover: {
                    backgroundColor: {
                        value: 'white',
                    },
                    color: {
                        value: 'black',
                    },
                },
            },
            collection: {
                pagination: {
                    current: {
                        color: { value: 'black' },
                        backgroundColor: {
                            value: 'white',
                        },
                    },
                    button: {
                        color: { value: 'white' },
                        _hover: {
                            backgroundColor: {
                                value: 'white',
                            },
                            color: { value: 'black' },
                        },
                    },
                },
                search: {
                    input: {
                        color: { value: 'white' },
                    },
                    button: {
                        color: { value: 'white' },
                        _focus: {
                            backgroundColor: {
                                value: 'white',
                            },
                            color: {
                                value: 'black',
                            },
                        },
                        _hover: {
                            backgroundColor: {
                                value: 'white',
                            },
                            color: {
                                value: 'black',
                            },
                        },
                    },
                },
            },
        },
    },
};

const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        rootID
        root {
          createdAt
          description
          id
          inAlarm
          unAcknowledged
          owner
          updatedAt
          parentID
        }
        inAlarm
        unAcknowledged
      }
      nextToken
    }
  }
`;

function dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}


function ViewerDigitalTwinDashboard(props) {
    const [channels, setChannels] = React.useState([])
    // console.log(props)
    const history  = useHistory();

    const onChannelClick = (channelId) => {
        if (channelId === '966d35ec-b09a-4cde-aa44-d1ab43e320cb') history.push("/viewerdigitaltwin/channel/"+ channelId);
    }

    React.useEffect(() => {
        async function listMyChannels() {
            let channelItems = []
            let nextToken = null
            do {
                const result = await API.graphql(graphqlOperation(listChannels, {
                    limit: 999,
                    nextToken: nextToken
                }))
                nextToken = result.data['listChannels']['nextToken']
                channelItems = channelItems.concat(result.data['listChannels']['items'])
            } while (nextToken != null)
            if (mounted) {
                setChannels(channelItems.sort(dynamicSort("name")))
            }
        }

        let mounted = true
        // console.log('List my channels')
        listMyChannels().then()
        return function cleanup() {
            mounted = false;
        };

    }, [])

    // console.log(channels)
    return (
        <ThemeProvider theme={myTheme}>
            <div className="section" style={{paddingBottom:0, paddingTop:0, backgroundColor: '#000000'}}>
                <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
                    <View width={'1024px'} backgroundColor={"black"}>
                        {/*Header*/}
                        <Flex direction={"column"} gap={0} alignItems={"center"}>
                            <View
                                height={"120px"}
                                width={"100%"}
                                backgroundColor={"black"}
                            >
                                <Flex
                                    direction={"row"}
                                    padding={"20px"}
                                    gap={"0px"}
                                    alignItems={"center"}
                                >
                                    <Image
                                        height={'57px'}
                                        src="https://www.synamedia.com/wp-content/themes/synamedia/assets/svg/synamedia-icon-white.svg"
                                        alt="Synamedia"/>
                                    <Image
                                        style={{marginTop: '17px', marginLeft:'27px'}}
                                        height={'30px'}
                                        src="https://www.synamedia.com/wp-content/themes/synamedia/assets/svg/synamedia-white.svg"
                                        alt="Synamedia"/>
                                    <Heading level={1} color={"white"} padding={"8px"} style={{marginLeft: '75px'}}>Viewer Digital Twin</Heading>
                                </Flex>
                            </View>
                            <View
                                minHeight={"480px"}
                                height={"480px"}
                                width={"100%"}
                                style={{
                                    backgroundSize: "cover",
                                    backgroundPosition: "center top",
                                    backgroundRepeat: "no-repeat",
                                    backgroundImage: `url(${background})`,
                                    overflow: "hidden"
                                }}
                            >
                                <View
                                    minHeight={"100%"}
                                    height={"100%"}
                                    width={"400px"}
                                    style={{
                                        backgroundSize: "contain",
                                        backgroundPosition: "left top",
                                        backgroundRepeat: "no-repeat",
                                        backgroundImage: `url(${heroBgMd})`,
                                        opacity: '0.9',
                                        overflow: "hidden"
                                    }}
                                >
                                    <Flex direction={"column"} gap={0}>
                                        <Text color={"white"} style={{fontSize: '14px'}}>Home > Viewer Digital Twin > Video Quality Dashboard</Text>
                                        <Text
                                            color={"white"}
                                            style={{fontSize: '40px', lineHeight: '46px', fontWeight: '700', marginBottom: '20px'}}
                                        >
                                            Real-time Video Quality Measurement and Monitoring
                                        </Text>
                                        <Text
                                            color={"white"}
                                            style={{fontSize: '16px', lineHeight: '26px', fontWeight: '400', marginBottom: '8px'}}
                                        >
                                            Video Quality Monitoring the End-to-End system: alarm management, automatic recording, collect analytic data, root cause analysis, ….
                                        </Text>
                                        <Text
                                            color={"white"}
                                            style={{fontSize: '16px', lineHeight: '26px', fontWeight: '400', marginBottom: '8px'}}
                                        >
                                            Video Quality Analysis when quality problems occur
                                        </Text>
                                        <Text
                                            color={"white"}
                                            width={'350px'}
                                            style={{fontSize: '16px', lineHeight: '26px', fontWeight: '400', marginBottom: '8px'}}
                                        >
                                            The Video Quality Dashboard – where it’s easy to see the problems and additional suggestions on what the cause is (future)
                                        </Text>
                                    </Flex>
                                </View>
                            </View>
                        </Flex>
                        {/*Channels*/}
                        <Flex direction={"column"} alignItems={"center"} padding={"5px"} backgroundColor={'black'} height={"1500px"}>

                                <Collection
                                    type="grid"
                                    templateColumns="1fr 1fr 1fr"
                                    gap="6px"
                                    items={channels}
                                    isSearchable
                                    isPaginated
                                    itemsPerPage={9}
                                    searchPlaceholder="Type to search..."
                                    searchFilter={(regions, keyword) =>
                                        regions.name.toLowerCase().startsWith(keyword.toLowerCase())
                                    }
                                >
                                    {(channel, index) => (
                                        <ChannelCard
                                            key={channel.id}
                                            channelId={channel.id}
                                            channelName={channel.name}
                                            channelInAlarm={channel.inAlarm}
                                            channelUnAcknowledged={channel.unAcknowledged}
                                            onClick={() => onChannelClick(channel.id)}
                                        />
                                    )}
                                </Collection>
                                <SelectField
                                    label="ServiceSelection"
                                    labelHidden={true}
                                    width={"300px"}
                                    style={{marginTop: '20px'}}
                                >
                                    <option value="default">Select Channels ...</option>
                                    <option value="all">All</option>
                                    <option value="priority_1">Priority 1</option>
                                </SelectField>

                        </Flex>
                    </View>
                </Flex>
            </div>
        </ThemeProvider>
    );
}

export default ViewerDigitalTwinDashboard;

import React from "react";
import {Button, Card, Collection, Flex, Heading, SearchField, View} from '@aws-amplify/ui-react';
import alarmBell from 'assets/img/bell.png';
import {API, graphqlOperation, Storage} from 'aws-amplify';
import AlarmCard from "../../components/vqaas/AlarmCard";
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import networkGraph from 'highcharts/modules/networkgraph.js';
import colorAxis from 'highcharts/modules/coloraxis.js';
import HighchartsReact from 'highcharts-react-official';
import {BsFillLightningFill} from 'react-icons/bs'
import {FcHighPriority} from 'react-icons/fc'
import {IconContext} from "react-icons";
import {putChannel, putEncodeAnalysis, putMediaRenew} from "./ApiFunctions";
import {useHistory} from "react-router-dom";
import ReactPlayer from "react-player";

highchartsMore(Highcharts);
networkGraph (Highcharts);
colorAxis(Highcharts);

function dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export const onCreateVqm = /* GraphQL */ `
  subscription OnCreateVqm($measurementPointId: ID!) {
    onCreateVqm(measurementPointId: $measurementPointId) {
      chunk {
        epoch
        value
      }
    }
  }
`;

const onCreateEncodeAnalysis = /* GraphQL */ `
  subscription OnCreateEncodeAnalysis($measurementPointId: ID!) {
    onCreateEncodeAnalysis(measurementPointId: $measurementPointId) {
      triggerEpoch
      id
      owner
      unAcknowledged
      user_identity_id
      encMediaID
      encMedia {
        updatedAt
      }
      status
      updatedAt
    }
  }
`;

const getMeasurementPoint = /* GraphQL */ `
  query GetMeasurementPoint($id: ID!) {
    getMeasurementPoint(id: $id) {
      createdAt
      description
      id
      encodeAnalyses {
        items {
          createdAt
          triggerEpoch
          id
          owner
          metric_psnr
          metric_vmaf
          encMediaID
          encMedia {
            updatedAt
          }
          status
          updatedAt
          unAcknowledged
          user_identity_id
        }
        nextToken
      }
      inAlarm
      unAcknowledged
      owner
      updatedAt
    }
  }
`;

export const listVqms = /* GraphQL */ `
  query ListVqms(
    $filter: ModelVqmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVqms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        chunk {
          epoch
          value
        }
      }
      nextToken
    }
  }
`;

const vqmByDate = /* GraphQL */ `
  query VqmByDate(
    $measurementPointId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVqmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vqmByDate(
      measurementPointId: $measurementPointId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        measurementPointId
        chunk {
          epoch
          value
        }
        owner
      }
      nextToken
    }
  }
`;

function ViewerDigitalTwinServiceCard(props) {
    const {monitorPointId} = props
    let history = useHistory();

    const [alarmList, setAlarmList] = React.useState([])
    const [plotbandList, setPlotbandList] = React.useState([])
    const [selectedAlarm, setSelectedAlarm] = React.useState()
    const [signedUrlCapture, setSignedUrlCapture] = React.useState()
    const [searchFieldMin, setSearchFieldMin] = React.useState(Date.now() - 60 * 60 * 1000)
    const [searchFieldMax, setSearchFieldMax] = React.useState(Date.now())
    const [zoom, setZoom] = React.useState(60 * 60 * 1000)
    const [vqChartOptions, setVqChartOptions] = React.useState({
        chart: {
            zoomType: 'x',
            resetZoomButton: {
                theme: {
                    fill: '#FFB400'
                },
                position: {
                    align: 'right', // by default
                    // verticalAlign: 'top', // by default
                    y: -40
                }
            },
            height: '250px',
        },
        credits: {
            enabled: false
        },
        title: null,
        time: {
            useUTC: false
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
        },
        xAxis: {
            type: 'datetime',
            events: {
                afterSetExtremes: function(event) {
                    setSearchFieldMin(new Date(Math.round(event.min)))
                    setSearchFieldMax(new Date(Math.round(event.max)))
                    // console.log('setExtremes event', event)
                }
            },
            minRange: 120000
        },
        yAxis: [{
            alignTicks: false,
            title: {
                text: 'Video quality'
            },
            max: 100,
        },{ // Secondary yAxis
            alignTicks: false,
            gridLineWidth: 0,
            title: null,
            labels: {enabled: false},
            enabled: false,
            opposite: true,
            max: 100,
        }],
        tooltip: {enabled: false},
        legend: {
            align: 'left',
            verticalAlign: 'top',
            borderWidth: 0
        },
        series: [
            {
                type: 'spline',
                name: 'VQM',
                color: 'rgb(0,0,0)',
                marker: {enabled:false},
                data: []
            },
            {
                type: 'scatter',
                showInLegend: false,
                name: 'Alarms',
                yAxis: 1,
                data: []
            }
        ]
    })
    const [serviceName, setServiceName] = React.useState('')
    const [vqmData, setVqmData] = React.useState([])
    const [showLightningIcon, setShowLightningIcon] = React.useState((monitorPointId === '68eff4bf-1137-43a7-afd0-1e2040c446e8'))
    const [showAlarmIcon, setShowAlarmIcon] = React.useState(false)

    const vqChartRef = React.useRef()
    const playerReference = React.useRef()

    React.useEffect(()=> {
        let mounted = true
        async function getAWSUrl() {
            if (selectedAlarm && mounted){
                // console.log(selectedAlarm)
                let certificateAge = (Date.now() - (new Date(selectedAlarm.encMedia.updatedAt)).getTime())/1000
                console.log('Certificate age [s]: ', certificateAge)
                if (certificateAge > 18000){ // 18000
                    console.log('Renew certificate')
                    await putMediaRenew(selectedAlarm.encMediaID)
                    console.log('Certificate renewed')
                }
                const fileAccessURLCapture = await Storage.get(
                    selectedAlarm.encMediaID + "/media_out_signed.m3u8",
                    {
                        identityId: selectedAlarm.identityId,
                        level: 'protected',
                        expires: 86400 });
                if (mounted) setSignedUrlCapture(fileAccessURLCapture);
            }
        }
        getAWSUrl().then()
        return () => {
            mounted = false
        }
    }, [selectedAlarm])

    // Get MonitorPoint -> alarmList, serviceName
    React.useEffect(() => {
        let mounted = true

        async function getMyMonitorPoint(){
            // console.log(monitorPointId)
            let response = await API.graphql(graphqlOperation(getMeasurementPoint, {id: monitorPointId}))
            return (response['data']['getMeasurementPoint'])
        }

        getMyMonitorPoint().then(data => {
            if (mounted && data) {
                // console.log(data)
                setServiceName(data['description'])
                setAlarmList(data['encodeAnalyses']['items'].sort(dynamicSort('-triggerEpoch')).map((item) => {
                    let newItem = {}
                    newItem.id = item.id
                    newItem.owner = item.owner
                    newItem.name = 'Video Quality drop'
                    newItem.unAcknowledged = item.unAcknowledged
                    newItem.x = item.triggerEpoch
                    newItem.y = 0
                    newItem.identityId = item.user_identity_id
                    newItem.encMediaID = item.encMediaID
                    newItem.encMedia = item.encMedia
                    newItem.updatedAt = item.updatedAt
                    newItem.status = item.status
                    newItem.marker = { symbol: `url(${alarmBell})` }
                    return newItem
                }))
                let tmpPlotBandList = []
                data['encodeAnalyses']['items'].sort(dynamicSort('-triggerEpoch')).forEach((item) => {
                    tmpPlotBandList.push({from:item.triggerEpoch-60000, to: item.triggerEpoch+30000, color: 'rgba(51, 128, 255, .5)'})
                })
                setPlotbandList(tmpPlotBandList)
            }
        })
        return () => {
            mounted = false
        }
    }, [monitorPointId])

    // List VQM data
    React.useEffect(() => {
        let mounted = true

        async function getMyVQM(){
            let nextToken = null
            let tmpVqmData = []
            if (vqChartRef.current) vqChartRef.current.chart.showLoading()
            do {
                const response = await API.graphql(graphqlOperation(vqmByDate, {
                    // filter: {
                    //     'measurementPointId': {'eq': monitorPointId}
                    // },
                    measurementPointId: monitorPointId,
                    sortDirection: 'DESC',
                    nextToken: nextToken,
                    limit: 999
                }))
                nextToken = response.data['vqmByDate'].nextToken
                // console.log(response.data['vqmByDate'].items)
                const newVqmData = [].concat.apply([], response.data['vqmByDate'].items.map(item => item.chunk))
                // console.log(newVqmData)
                tmpVqmData = [...tmpVqmData, ...newVqmData]
                if (mounted) setVqmData(tmpVqmData.sort(dynamicSort("epoch")).map(item => [item.epoch, item.value]))
            } while (nextToken != null && tmpVqmData.length < 30000 )
            if (vqChartRef.current) vqChartRef.current.chart.hideLoading()
            return (tmpVqmData.sort(dynamicSort("epoch")).map(item => [item.epoch, item.value]))
        }

        getMyVQM().then(data => {
            if (mounted && data) {
                // console.log('Loaded All data')
                // setVqmData(data)
            }
        })
        return () => {
            mounted = false
        }
    }, [monitorPointId])

    // Subscribe to create VQM mutation
    React.useEffect(() => {
        let subscription
        let mounted = true

        async function setupSubscription() {
            subscription = API.graphql(
                graphqlOperation(
                    onCreateVqm,
                    {measurementPointId: monitorPointId}
                )).subscribe({
                next: (data) => {
                    if (mounted) {
                        if (data['value']['data']['onCreateVqm']['chunk']){
                            const chunk = data['value']['data']['onCreateVqm']['chunk'].map(item => [item.epoch, item.value])
                            setShowAlarmIcon((chunk[0][1] < 80))
                            setVqmData( oldVqm => [...oldVqm, ...chunk])

                        }
                    }
                },
                error: error => {
                    console.warn(error);
                }
            })
        }
        if (monitorPointId) {
            setupSubscription().then()
            return () => {
                mounted = false
                if (subscription) {
                    subscription.unsubscribe()
                }
            }
        }
    }, [monitorPointId])

    // Subscribe to create EncodeAnalysis mutation
    React.useEffect(() => {
        let subscriptions = []
        let mounted = true

        async function setupSubscription() {
            subscriptions.push(API.graphql(
                graphqlOperation(
                    onCreateEncodeAnalysis,
                    {measurementPointId: monitorPointId}
                )).subscribe({
                next: (data) => {
                    if (mounted) {
                        if (data['value']['data']['onCreateEncodeAnalysis']){
                            let newItem = {}
                            newItem.id = data['value']['data']['onCreateEncodeAnalysis']['id']
                            newItem.owner = data['value']['data']['onCreateEncodeAnalysis']['owner']
                            newItem.name = 'Video Quality drop'
                            newItem.unAcknowledged = data['value']['data']['onCreateEncodeAnalysis']['unAcknowledged']
                            newItem.x = data['value']['data']['onCreateEncodeAnalysis']['triggerEpoch']
                            newItem.y = 0
                            newItem.identityId = data['value']['data']['onCreateEncodeAnalysis']['user_identity_id']
                            newItem.encMediaID = data['value']['data']['onCreateEncodeAnalysis']['encMediaID']
                            newItem.encMedia = data['value']['data']['onCreateEncodeAnalysis']['encMedia']
                            newItem.updatedAt = data['value']['data']['onCreateEncodeAnalysis']['updatedAt']
                            newItem.status = data['value']['data']['onCreateEncodeAnalysis']['status']
                            newItem.marker = { symbol: `url(${alarmBell})` }
                            console.log('Adding new alarm', newItem)
                            setAlarmList(oldAlarmList => [newItem, ...oldAlarmList])
                            console.log(data['value']['data']['onCreateEncodeAnalysis'])
                            setPlotbandList(oldPlotbandList => [...oldPlotbandList, {
                                from: newItem.x -60000,
                                to: newItem.x + 30000,
                                color: 'rgba(51, 128, 255, .5)'
                            }])
                        }
                    }
                },
                error: error => {
                    console.warn(error);
                }
            }))
        }
        if (monitorPointId) {
            setupSubscription().then()
            return () => {
                mounted = false
                if (subscriptions) {
                    subscriptions.forEach((subscription) => subscription.unsubscribe())
                }
            }
        }
    }, [monitorPointId])

    // Update graph with new data
    React.useEffect(() => {
        let mounted = true

        if (mounted) {
            setVqChartOptions({
                series: [
                    {
                        data: vqmData
                    },
                    {
                        data: alarmList.sort(dynamicSort('triggerEpoch'))
                    }
                ],
                xAxis: {
                    min: Date.now() - zoom,
                    max: null
                }
            })
            return () => {
                mounted = false
            }
        }
    }, [alarmList, zoom, vqmData])

    React.useEffect(() => {
        let mounted = true
        if (mounted) {
            setVqChartOptions({
                xAxis: {
                    plotBands: plotbandList
                }
            })
            return () => {
                mounted = false
            }
        }
    }, [plotbandList])

    const onImpactVQ = async () => {
        setShowLightningIcon(false)
        putChannel('966d35ec-b09a-4cde-aa44-d1ab43e320cb').then((response) => console.log(response));
        setTimeout(() => { setShowLightningIcon(true)}, 5000)
    }

    const handleVqaasClick = (encode_analysis_id) => {
        history.push("/encode-analysis/"+ encode_analysis_id);
    }

    const handleAcknowledgeClick = async (alarm_id) => {
        console.log('Handle Acknowledge click')
        const body = {unAcknowledged: false}
        putEncodeAnalysis(alarm_id, body)
    }

    // console.log(props.monitorPointId)
    return (
        <>
            <Flex direction={"row"} justifyContent={"space-between"}  backgroundColor={"black"} width={"100%"}>
                <Flex direction={"row"} justifyContent={'flex-start'} alignItems={"center"} alignContent={"center"}>
                    <Heading level={3} color={'white'}>{'Mountain Bike Channel - ' + serviceName + ' '} - Capture & Alarm history</Heading>
                    { showAlarmIcon ?
                    <div style={{width:'25px', height:'25px', padding:'2px'}}>
                        <IconContext.Provider value={{ style:{width:'25px', height:'25px'} }}>
                            <div>
                                <FcHighPriority  className={"customClass"}/>
                            </div>
                        </IconContext.Provider>
                    </div> : <div style={{width:'25px', height:'25px', padding:'2px'}}/>}
                </Flex>
                <Flex direction={"row"} justifyContent={'space-between'}>
                    <Button
                        className="reference-card-button"
                        minWidth={"100px"}
                        variation="primary"
                        size={'small'}
                        onClick={()=> history.goBack()}>
                        Back
                    </Button>
                    {showLightningIcon ?
                    <Button
                        className="reference-card-button"
                        variation="primary"
                        size={'small'}
                        minHeight={"30px"}
                        minWidth={"100px"}
                        paddingTop={0}
                        paddingBottom={0}
                        paddingLeft={'4px'}
                        paddingRight={'4px'}
                        onClick={(e) => onImpactVQ()}
                    >
                        <IconContext.Provider
                            value={{color: "black", style: {width: '20px', height: '20px', padding:0}}}>
                            <BsFillLightningFill/>
                        </IconContext.Provider>
                    </Button> : <div/> }
                </Flex>
            </Flex>
            <Card
                padding="0rem"
                variation="elevated"
            >
                <Flex direction={"column"} justifyContent={"flex-start"} padding={"5px"} width={"100%"} >
                    <Flex direction={"row"} justifyContent={'space-between'}>
                        <View width={"920px"} backgroundColor={"black"}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={vqChartOptions}
                                ref={vqChartRef}
                            />
                        </View>
                        <Flex direction={'column'} justifyContent={'flex-start'} style={{paddingTop: '10px'}}>
                            <Button onClick={() => {
                                setZoom(60 * 60 * 1000)}}>
                                1h
                            </Button>
                            <Button onClick={() => {
                                setZoom(3 * 60 * 60 * 1000)}}>
                                3h
                            </Button>
                            <Button onClick={() => {
                                setZoom(6 * 60 * 60 * 1000)}}>
                                6h
                            </Button>
                            <Button onClick={() => {
                                setZoom(24 * 60 * 60 * 1000)}}>
                                1d
                            </Button>
                        </Flex>
                    </Flex>

                    <Flex direction={"row"} justifyContent={"space-between"} padding={0} gap={'2px'}>
                        <Flex direction={"column"} alignItems={"flex-end"} gap={0}>
                            <View width={"500px"} height={"281px"} backgroundColor={"black"}>
                                <ReactPlayer
                                    width='500px'
                                    height='281px'
                                    url={signedUrlCapture}
                                    ref={playerReference}
                                    pip={false}
                                    playing={false}
                                    volume={0}
                                    muted={true}
                                    controls={true}
                                    light={false}
                                    loop={true}
                                    playbackRate={1.0}
                                />
                            </View>
                        </Flex>
                        <Flex direction={"column"} justifyContent={"flex-start"} alignContent={"center"} width={"500px"} minWidth={"500px"}>
                            <SearchField
                                label="Search"
                                size="small"
                                placeholder="TimeSpan"
                                value={searchFieldMin.toLocaleString() + ' - ' + searchFieldMax.toLocaleString()}
                            />
                            <Collection
                                type="list"
                                gap="15px"
                                items={alarmList.filter(alarm => (alarm.x >= searchFieldMin && alarm.x <= searchFieldMax)).sort(dynamicSort('-triggerEpoch'))}
                                isPaginated
                                itemsPerPage={2}
                            >
                                {(alarm, index) => (
                                    <AlarmCard
                                        key={index}
                                        alarmId={alarm.id}
                                        alarmOwner={alarm.owner}
                                        alarmName={alarm.name}
                                        alarmTimestamp={alarm.x}
                                        alarmStatus={alarm.status}
                                        alarmActive={true}
                                        alarmUnAcknowledged={alarm.unAcknowledged}
                                        alarmRecommendation={'Recommendation: This drop was caused by a too low encoding bitrate'}
                                        onReplayClick={() => {
                                            setSelectedAlarm(alarm)
                                            setPlotbandList(oldPlotbandList => oldPlotbandList.map(oldPlotband => oldPlotband.from === alarm.x -60000 ?
                                                {
                                                    from: oldPlotband.from,
                                                    to: oldPlotband.to,
                                                    color: 'rgba(255, 180, 0, .5)'
                                                } :
                                                {
                                                    from: oldPlotband.from,
                                                    to: oldPlotband.to,
                                                    color: 'rgba(51, 128, 255, .5)'
                                                }
                                            ))
                                        }}
                                        onAcknowledgeClick={() => handleAcknowledgeClick(alarm.id)}
                                        onVqaasClick={() => handleVqaasClick(alarm.id)}
                                    />
                                )}
                            </Collection>
                        </Flex>
                    </Flex>
                </Flex>
            </Card>
        </>
    );
}

export default ViewerDigitalTwinServiceCard;

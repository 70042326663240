import React from 'react';
import './App.css';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import MediaPage from "./views/examples/MediaPage";
import ProfilePage from "./components/vqaas/ProfilePage";
import AddTeam from "./views/examples/AddTeam";
import EditTeam from "./views/examples/EditTeam";
import DeleteTeam from "./views/examples/DeleteTeam";
// import ShowTeam from "./views/examples/ShowTeam";
import ListTeams from "./views/examples/ListTeams";
import ListAccounts from "./views/examples/ListAccounts";
import DashboardAccounts from "./views/examples/DashboardAccounts";
import S3Page from "./views/examples/S3Page";
import {Amplify, API, Auth, graphqlOperation} from 'aws-amplify';
import {Authenticator, Button, Heading, Text, Image, Link, useAuthenticator, useTheme, View} from '@aws-amplify/ui-react';
import {deleteMedia, putMediaShare} from './components/vqaas/ApiFunctions';
import awsExports from './aws-exports';
import SplashScreen from "./views/examples/SplashScreen";
import {updateAccount} from "./graphql/mutations";
import MediaDetailPage from "./views/examples/MediaDetailPage";
import EncodeAnalysisPage from "./views/examples/EncodeAnalysisPage";
import CompareAnalysisPage from "./views/examples/CompareAnalysis";
import ChannelPage from "./views/examples/ChannelPage.js";
import SmxPage from "./views/examples/SmxPage";
import background from 'assets/img/synamedia/GettyImages-136591756-GRADED.jpg';
import ViewerDigitalTwinDashboard from "./views/examples/ViewerDigitalTwinDashboard";
import ViewerDigitalTwinChannel from "./views/examples/ViewerDigitalTwinChannel";
import ViewerDigitalTwinMonitorPoint from "./views/examples/ViewerDigitalTwinMonitorPoint";

Amplify.configure(awsExports);

const formFields = {
    setupTOTP: {
        QR: {
            totpIssuer: 'VQaaS'
        },
    },
}

const components = {
    Header() {
        return (
            <View textAlign="center" padding={"10%"}>
            </View>
        );
    },
    SetupTOTP: {
        Header() {
            // eslint-disable-next-line no-unused-vars
            const { tokens } = useTheme();
            return (
                <Heading
                    level={3}
                >
                    Enable 2-factor authentication
                </Heading>
            );
        },
        Footer() {
            return (
                <>
                    <Text>Please setup 2-factor authentication by installing the <Link color={"#3380FF"} href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" isExternal={true}>Microsoft Authenticator</Link> or <Link color={"#3380FF"} href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB&gl=US" isExternal={true}>Google Authenticator</Link> app on your phone and scan the above QR code.</Text>
                    <Text>Once scanned, you will be presented with a one-time password code (valid for 30 seconds).</Text>
                    <Text>Still can't login?</Text>
                    <Text>Refresh the page or contact <Link color={"#3380FF"} href={"mailto:vqaas-admin@synamedia.com"}>vqaas-admin@synamedia.com</Link></Text>
                </>);
        },
    },
    SignIn: {
        Header() {
            const {tokens} = useTheme();

            return (
                <>
                    <View
                        backgroundColor={"#3380FF"}
                        padding={"5px"}
                    />
                    <Image
                        padding={"5px"}
                        alt="Synamedia logo"
                        src="https://www.synamedia.com/wp-content/themes/synamedia-wp-theme/images/brand/synamedia-logo.gif"
                    />
                    <Heading
                        textAlign="center"
                        padding={`${tokens['space'].xl} 0 0 ${tokens['space'].xl}`}
                        level={3}
                    >
                        VQaaS Sign in
                    </Heading>
                </>
            );
        },
        Footer() {
            const {toResetPassword} = useAuthenticator();
            return (
                <View textAlign="center">
                    <Button
                        color={`#3380FF`}
                        fontWeight="normal"
                        onClick={toResetPassword}
                        size="small"
                        variation="link"
                    >
                        Forgot your Password?
                    </Button>
                </View>
            );
        },
    },

};

const getAccountWithSubscriptionsTeamsS3 = /* GraphQL */ `
    query GetAccount(
      $id: ID!
      $crossNextToken: String) {
          getAccount(id: $id) {
            id
            email
            owner
            first_name
            last_name
            company_name
            description
            comparisons {
                items {
                  comparison {
                    account {
                      id
                      company_name
                      credits
                      description
                      email
                      first_name
                      last_login
                      last_upload
                      last_name
                      num_references
                      num_distorteds
                      owner
                      refresh
                      s3_bucket
                      s3_bucket_region
                      user_identity_id
                      createdAt
                      updatedAt
                    }
                    accountID
                    createdAt
                    description
                    encodeAnalyses {
                        items {
                          encodeAnalysis {
                              refMedia {
                                accountID
                                build_number
                                createdAt
                                cross_account_s3_id
                                description
                                filename
                                id
                                owner
                                progress_create_hls
                                progress_cross_copy
                                progress_decode
                                progress_deploy
                                progress_extract_es
                                progress_upload
                                spec_codec
                                spec_duration_frames
                                spec_duration_time
                                spec_frame_rate
                                spec_height
                                spec_pid
                                spec_pix_fmt
                                spec_width
                                status
                                scan_type
                                scan_order
                                scan_mode
                                fps
                                bitrate_list_per_sec
                                width
                                height
                                extension
                                es_filename
                                updatedAt
                                user_identity_id
                              }
                              refMediaID
                              encMedia {
                                accountID
                                build_number
                                createdAt
                                cross_account_s3_id
                                description
                                filename
                                id
                                owner
                                progress_create_hls
                                progress_cross_copy
                                progress_decode
                                progress_deploy
                                progress_extract_es
                                progress_upload
                                spec_codec
                                spec_duration_frames
                                spec_duration_time
                                spec_frame_rate
                                spec_height
                                spec_pid
                                spec_pix_fmt
                                spec_width
                                status
                                scan_type
                                scan_order
                                scan_mode
                                fps
                                bitrate_list_per_sec
                                width
                                height
                                extension
                                es_filename
                                updatedAt
                                user_identity_id
                              }
                              encMediaID
                              align_offset
                              build_number
                              createdAt
                              cross_account_s3_id
                              description
                              encode_id
                              filename
                              interlace_rep {
                                Legacy
                                DeInterlaceOff
                                BWDifSendFrameTFF
                                BWDifSendFrameBFF
                                BWDifSendFieldTFF
                                BWDifSendFieldBFF
                                DeInterleave
                              }
                            persons {
                              items {
                                account {
                                  id
                                  company_name
                                  email
                                  first_name
                                  last_name
                                }
                              }
                              nextToken
                            }
                              subscriptions
                              teams {
                                items {
                                  team {
                                    name
                                    id
                                  }
                                }
                                nextToken
                              }
                              id
                              vmaf_reduct
                              psnr_reduct
                              metric_psnr
                              metric_vmaf
                              owner
                              progress_calc_metric
                              progress_create_hls
                              progress_deploy
                              progress_extract_feat
                              progress_upload
                              reference_id
                              ana_fps_nominator
                              ana_fps_denominator
                              spec_codec
                              spec_duration_frames
                              spec_duration_time
                              spec_frame_rate
                              spec_height
                              spec_pid
                              spec_pix_fmt
                              spec_width
                              status
                              scan_type
                              scan_order
                              scan_mode
                              updatedAt
                              user_identity_id
                            }
                          }
                     nextToken
                    }
                    id
                    owner
                    persons {
                      items {
                        account {
                          id
                          company_name
                          email
                          first_name
                          last_name
                        }
                      }
                      nextToken
                    }
                    teams {
                      nextToken
                    }
                    updatedAt
                  }
                }
                nextToken
              }
            media {
                items {
                  media {
                    account {
                      id
                      company_name
                      email
                      first_name
                      last_name
                      owner
                    }
                    accountID
                    build_number
                    referenceMedia {
                      nextToken
                    }
                    encodedMedia {
                      nextToken
                    }
                    createdAt
                    cross_account_s3_id
                    description
                    filename
                    interlace_rep {
                      Legacy
                      DeInterlaceOff
                      BWDifSendFrameTFF
                      BWDifSendFrameBFF
                      BWDifSendFieldTFF
                      BWDifSendFieldBFF
                      DeInterleave
                    }
                    id
                    owner
                    persons {
                      items {
                        account {
                          id
                          company_name
                          email
                          first_name
                          last_name
                        }
                      }
                      nextToken
                    }
                    teams {
                      nextToken
                    }
                    progress_create_hls
                    progress_cross_copy
                    progress_decode
                    progress_deploy
                    progress_extract_es
                    progress_upload
                    spec_codec
                    spec_duration_frames
                    spec_duration_time
                    spec_frame_rate
                    spec_height
                    spec_pid
                    spec_pix_fmt
                    spec_width
                    status
                    scan_type
                    scan_order
                    scan_mode
                    fps
                    bitrate_list_per_sec
                    width
                    height
                    extension
                    es_filename
                    updatedAt
                    user_identity_id
                  }
                }
                nextToken
              }
            encode_analyses {
                    items {
                      encodeAnalysis {
                              account {
                                id
                                company_name
                                email
                                first_name
                                last_name
                                owner
                              }
                              accountID
                              refMedia {
                                accountID
                                build_number
                                createdAt
                                cross_account_s3_id
                                description
                                filename
                                id
                                owner
                                progress_create_hls
                                progress_cross_copy
                                progress_decode
                                progress_deploy
                                progress_extract_es
                                progress_upload
                                spec_codec
                                spec_duration_frames
                                spec_duration_time
                                spec_frame_rate
                                spec_height
                                spec_pid
                                spec_pix_fmt
                                spec_width
                                status
                                scan_type
                                scan_order
                                scan_mode
                                fps
                                bitrate_list_per_sec
                                width
                                height
                                extension
                                es_filename
                                updatedAt
                                user_identity_id
                              }
                              refMediaID
                              encMedia {
                                accountID
                                build_number
                                createdAt
                                cross_account_s3_id
                                description
                                filename
                                id
                                owner
                                progress_create_hls
                                progress_cross_copy
                                progress_decode
                                progress_deploy
                                progress_extract_es
                                progress_upload
                                spec_codec
                                spec_duration_frames
                                spec_duration_time
                                spec_frame_rate
                                spec_height
                                spec_pid
                                spec_pix_fmt
                                spec_width
                                status
                                scan_type
                                scan_order
                                scan_mode
                                fps
                                bitrate_list_per_sec
                                width
                                height
                                extension
                                es_filename
                                updatedAt
                                user_identity_id
                              }
                              encMediaID
                              align_offset
                              build_number
                              createdAt
                              cross_account_s3_id
                              description
                              encode_id
                              filename
                              interlace_rep {
                                Legacy
                                DeInterlaceOff
                                BWDifSendFrameTFF
                                BWDifSendFrameBFF
                                BWDifSendFieldTFF
                                BWDifSendFieldBFF
                                DeInterleave
                              }
                            persons {
                              items {
                                account {
                                  id
                                  company_name
                                  email
                                  first_name
                                  last_name
                                }
                              }
                              nextToken
                            }
                              subscriptions
                              teams {
                                items {
                                  team {
                                    name
                                    id
                                  }
                                }
                                nextToken
                              }
                              id
                              vmaf_reduct
                              psnr_reduct
                              metric_psnr
                              metric_vmaf
                              owner
                              progress_calc_metric
                              progress_create_hls
                              progress_deploy
                              progress_extract_feat
                              progress_upload
                              reference_id
                              ana_fps_nominator
                              ana_fps_denominator
                              spec_codec
                              spec_duration_frames
                              spec_duration_time
                              spec_frame_rate
                              spec_height
                              spec_pid
                              spec_pix_fmt
                              spec_width
                              status
                              scan_type
                              scan_order
                              scan_mode
                              updatedAt
                              user_identity_id
                        }
                      }
                     nextToken
            }
            s3_bucket
            s3_bucket_region
            subscriptions {
              items {
                subscription {
                  name
                  id
                }
              }
            }
            teams {
              items {
                team {
                  name
                  id
                  comparisons {
                        items {
                              comparison {
                                account {
                                  id
                                  company_name
                                  credits
                                  description
                                  email
                                  first_name
                                  last_login
                                  last_upload
                                  last_name
                                  num_references
                                  num_distorteds
                                  owner
                                  refresh
                                  s3_bucket
                                  s3_bucket_region
                                  user_identity_id
                                  createdAt
                                  updatedAt
                                }
                                accountID
                                createdAt
                                description
                                encodeAnalyses {
                                  nextToken
                                }
                                id
                                owner
                                persons {
                                  items {
                                    account {
                                      id
                                      company_name
                                      email
                                      first_name
                                      last_name
                                    }
                                  }
                                  nextToken
                                }
                                teams {
                                  nextToken
                                }
                                updatedAt
                              }
                        }
                        nextToken
                      }
                  media {
                    items {
                      media {
                        account {
                          id
                          company_name
                          credits
                          description
                          email
                          first_name
                          last_login
                          last_upload
                          last_name
                          num_references
                          num_distorteds
                          owner
                          refresh
                          s3_bucket
                          s3_bucket_region
                          user_identity_id
                          createdAt
                          updatedAt
                        }
                        accountID
                        build_number
                        referenceMedia {
                          nextToken
                        }
                        encodedMedia {
                          nextToken
                        }
                        createdAt
                        cross_account_s3_id
                        description
                        filename
                        interlace_rep {
                          Legacy
                          DeInterlaceOff
                          BWDifSendFrameTFF
                          BWDifSendFrameBFF
                          BWDifSendFieldTFF
                          BWDifSendFieldBFF
                          DeInterleave
                        }
                        id
                        owner
                        persons {
                          items {
                            account {
                              id
                              company_name
                              email
                              first_name
                              last_name
                            }
                          }
                          nextToken
                        }
                        teams {
                          nextToken
                        }
                        progress_create_hls
                        progress_cross_copy
                        progress_decode
                        progress_deploy
                        progress_extract_es
                        progress_upload
                        spec_codec
                        spec_duration_frames
                        spec_duration_time
                        spec_frame_rate
                        spec_height
                        spec_pid
                        spec_pix_fmt
                        spec_width
                        status
                        scan_type
                        scan_order
                        scan_mode
                        fps
                        bitrate_list_per_sec
                        width
                        height
                        extension
                        es_filename
                        updatedAt
                        user_identity_id
                      }
                    }
                    nextToken
                  }
                  encode_analyses {
                    items {
                      encodeAnalysis {
                              account {
                                id
                                company_name
                                email
                                first_name
                                last_name
                              }
                              accountID
                              refMedia {
                                  accountID
                                  build_number
                                  createdAt
                                  cross_account_s3_id
                                  description
                                  filename
                                  id
                                  owner
                                  progress_create_hls
                                  progress_cross_copy
                                  progress_decode
                                  progress_deploy
                                  progress_extract_es
                                  progress_upload
                                  spec_codec
                                  spec_duration_frames
                                  spec_duration_time
                                  spec_frame_rate
                                  spec_height
                                  spec_pid
                                  spec_pix_fmt
                                  spec_width
                                  status
                                  scan_type
                                  scan_order
                                  scan_mode
                                  fps
                                  bitrate_list_per_sec
                                  width
                                  height
                                  extension
                                  es_filename
                                  updatedAt
                                  user_identity_id
                                }
                                refMediaID
                                encMedia {
                                  accountID
                                  build_number
                                  createdAt
                                  cross_account_s3_id
                                  description
                                  filename
                                  id
                                  owner
                                  progress_create_hls
                                  progress_cross_copy
                                  progress_decode
                                  progress_deploy
                                  progress_extract_es
                                  progress_upload
                                  spec_codec
                                  spec_duration_frames
                                  spec_duration_time
                                  spec_frame_rate
                                  spec_height
                                  spec_pid
                                  spec_pix_fmt
                                  spec_width
                                  status
                                  scan_type
                                  scan_order
                                  scan_mode
                                  fps
                                  bitrate_list_per_sec
                                  width
                                  height
                                  extension
                                  es_filename
                                  updatedAt
                                  user_identity_id
                                }
                                encMediaID
                                align_offset
                                build_number
                                createdAt
                                cross_account_s3_id
                                description
                                encode_id
                                filename
                                interlace_rep {
                                  Legacy
                                  DeInterlaceOff
                                  BWDifSendFrameTFF
                                  BWDifSendFrameBFF
                                  BWDifSendFieldTFF
                                  BWDifSendFieldBFF
                                  DeInterleave
                                }
                                persons {
                                  items {
                                    account {
                                      id
                                      company_name
                                      email
                                      first_name
                                      last_name
                                    }
                                  }
                                  nextToken
                                }
                                subscriptions
                                teams {
                                  items {
                                    team {
                                      name
                                      id
                                    }
                                  }
                                  nextToken
                                }
                                id
                                vmaf_reduct
                                psnr_reduct
                                metric_psnr
                                metric_vmaf
                                owner
                                progress_calc_metric
                                progress_create_hls
                                progress_deploy
                                progress_extract_feat
                                progress_upload
                                reference_id
                                ana_fps_nominator
                                ana_fps_denominator
                                spec_codec
                                spec_duration_frames
                                spec_duration_time
                                spec_frame_rate
                                spec_height
                                spec_pid
                                spec_pix_fmt
                                spec_width
                                status
                                scan_type
                                scan_order
                                scan_mode
                                updatedAt
                                user_identity_id

                      }
                    }
                   nextToken
                  }
                  accounts {
                    items {
                      account {
                        id
                        email
                        first_name
                        last_name
                        company_name
                        }
                    }
                    nextToken
                  }
                }
              }
              nextToken
            }
            following {
              name
              ownerID
              accepted
            }
            followed_by {
              name
              ownerID
              accepted
            }
            cross_account_s3(nextToken: $crossNextToken) {
              items {
                id
                Key
                ETag
                Size
                LastModified
                LastSeen
                accountID
                referenceID
                createdAt
                updatedAt
              }
              nextToken
            }
            credits
            createdAt
            updatedAt
            user_identity_id
          }
    }
  `;
const listEncodeAnalysesTeams = /* GraphQL */ `
  query ListEncodeAnalyses(
    $filter: ModelEncodeAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEncodeAnalyses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          description
          filename
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          description
          filename
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
          persons {
            items {
              id
              accountID
              account {
                id
                email
              }
              encodeAnalysisID
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
        subscriptions
        teams {
          items {
            team {
              name
              id
            }
          }
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        updatedAt
        user_identity_id
      }
      nextToken
    }
  }
`;
const listMedia = /* GraphQL */ `
  query ListMedia(
    $filter: ModelMediaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        description
        deleted
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        owner
        persons {
          items {
            account {
              id
              company_name
              email
              first_name
              last_name
            }
          }
          nextToken
        }
        teams {
          items {
            team {
              name
              id
            }
          }
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      nextToken
    }
  }
`;
const listComparisonsTeams = /* GraphQL */ `
  query ListComparisons(
    $filter: ModelComparisonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComparisons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
            items {
              encodeAnalysis {
                  refMedia {
                    accountID
                    build_number
                    createdAt
                    cross_account_s3_id
                    description
                    filename
                    id
                    owner
                    progress_create_hls
                    progress_cross_copy
                    progress_decode
                    progress_deploy
                    progress_extract_es
                    progress_upload
                    spec_codec
                    spec_duration_frames
                    spec_duration_time
                    spec_frame_rate
                    spec_height
                    spec_pid
                    spec_pix_fmt
                    spec_width
                    status
                    scan_type
                    scan_order
                    scan_mode
                    fps
                    bitrate_list_per_sec
                    width
                    height
                    extension
                    es_filename
                    updatedAt
                    user_identity_id
                  }
                  refMediaID
                  encMedia {
                    accountID
                    build_number
                    createdAt
                    cross_account_s3_id
                    description
                    filename
                    id
                    owner
                    progress_create_hls
                    progress_cross_copy
                    progress_decode
                    progress_deploy
                    progress_extract_es
                    progress_upload
                    spec_codec
                    spec_duration_frames
                    spec_duration_time
                    spec_frame_rate
                    spec_height
                    spec_pid
                    spec_pix_fmt
                    spec_width
                    status
                    scan_type
                    scan_order
                    scan_mode
                    fps
                    bitrate_list_per_sec
                    width
                    height
                    extension
                    es_filename
                    updatedAt
                    user_identity_id
                  }
                  encMediaID
                  align_offset
                  build_number
                  createdAt
                  cross_account_s3_id
                  description
                  encode_id
                  filename
                  interlace_rep {
                    Legacy
                    DeInterlaceOff
                    BWDifSendFrameTFF
                    BWDifSendFrameBFF
                    BWDifSendFieldTFF
                    BWDifSendFieldBFF
                    DeInterleave
                  }
                persons {
                  items {
                    account {
                      id
                      company_name
                      email
                      first_name
                      last_name
                    }
                  }
                  nextToken
                }
                  subscriptions
                  teams {
                    items {
                      team {
                        name
                        id
                      }
                    }
                    nextToken
                  }
                  id
                  vmaf_reduct
                  psnr_reduct
                  metric_psnr
                  metric_vmaf
                  owner
                  progress_calc_metric
                  progress_create_hls
                  progress_deploy
                  progress_extract_feat
                  progress_upload
                  reference_id
                  ana_fps_nominator
                  ana_fps_denominator
                  spec_codec
                  spec_duration_frames
                  spec_duration_time
                  spec_frame_rate
                  spec_height
                  spec_pid
                  spec_pix_fmt
                  spec_width
                  status
                  scan_type
                  scan_order
                  scan_mode
                  updatedAt
                  user_identity_id
                }
              }
         nextToken
        }
        id
        owner
        persons {
          items {
            account {
              id
              company_name
              email
              first_name
              last_name
            }
          }
          nextToken
        }
        teams {
          items {
            team {
              name
              id
            }
          }
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;


export default function App() {
    const [signedIn, setSignedIn] = React.useState(false)
    const [appUser, setAppUser] = React.useState()
    const [dataReady, setDataReady] = React.useState(false)
    const [account, setAccount] = React.useState()
    const [adminVisible, setAdminVisible] = React.useState(false);
    const [media, setMedia] = React.useState([])
    const [encodeAnalyses, setEncodeAnalyses] = React.useState([])
    const [accountChanged, setAccountChanged] = React.useState(false)
    // const [refUploadProgress, setRefUploadProgress] = React.useState(0)
    // const [mediaPageImportAlarmList, setMediaPageImportAlarmList] = React.useState([{
    //     key: (new Date()).getTime(),
    //     variation: 'info',
    //     isDismissible: true,
    //     heading:'Browser compatibility',
    //     text: 'The drag and drop functionality has been tested on Chrome, Edge and Firefox. Mobile devices are not supported.'}])
    const [encodeAnalysesSharedWithMe, setEncodeAnalysesSharedWithMe] = React.useState([])
    const [encodeAnalysesSharedWithTeam, setEncodeAnalysesSharedWithTeam] = React.useState([])
    const [mediaSharedWithMe, setMediaSharedWithMe] = React.useState([])
    const [mediaSharedWithTeam, setMediaSharedWithTeam] = React.useState([])
    const [comparisons, setComparisons] = React.useState([])
    const [comparisonsSharedWithMe, setComparisonsSharedWithMe] = React.useState([])
    const [comparisonsSharedWithTeam, setComparisonsSharedWithTeam] = React.useState([])

    function dynamicSort(property) {
        let sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    /**
     * ***************************************************************************************************************
     * Sign in / out Logic
     * ***************************************************************************************************************
     */

        // Logic to handle a click on the Sign-Out button
    const handleSignOutClick = async () => {
            try {
                await Auth.signOut();
            } catch (error) {
                console.log('error signing out: ', error);
            }
            setSignedIn(false)
            setAppUser()
            setDataReady(false)
            setAccount()
        };

    // Logic to handle Account changes
    const handleChangeAccount = async (field, value) => {
        if (field === 'first_name') setAccount(prevState => {
            return {...prevState, first_name: value}
        })
        if (field === 'last_name') setAccount(prevState => {
            return {...prevState, last_name: value}
        })
        if (field === 'company_name') setAccount(prevState => {
            return {...prevState, company_name: value}
        })
        if (field === 's3_bucket') setAccount(prevState => {
            return {...prevState, s3_bucket: value}
        })
        if (field === 's3_bucket_region') setAccount(prevState => {
            return {...prevState, s3_bucket_region: value}
        })
        const credentials = await Auth.currentCredentials();
        setAccount(prevState => {
            return {...prevState, user_identity_id: credentials.identityId}})
        setAccountChanged(true)
    };

    // Logic to store changes to Account in database
    React.useEffect(() => {
        async function updateAccountData() {
            const accountData = {
                id: account.id,
                first_name: account.first_name,
                last_name: account.last_name,
                company_name: account.company_name,
                s3_bucket: account.s3_bucket,
                s3_bucket_region: account.s3_bucket_region,
                description: account.description,
                user_identity_id: account.user_identity_id
            }
            await API.graphql({query: updateAccount, variables: {input: accountData}});
            console.log('Ready with await')
        }

        if (account && accountChanged) {
            updateAccountData().then(() => {
                console.log('Account Updated')
                // setDataReady(false)
            })
            setAccountChanged(false)
        }
        console.log('triggered by change in account, accountChanged')

    }, [account, accountChanged])

    // Logic to determine who is signed in
    const handleOnUserChange = async () => {
        if (!signedIn) {
            try {
                const user = await Auth.currentAuthenticatedUser()
                console.log('Ready with await')

                if (!appUser && !signedIn) {
                    setAppUser(user)
                    console.log('Signed In')
                    setSignedIn(true)
                }
            } catch (e) {
                // Do nothing
            }
        }
    };

    // Remove duplicates from List
    function uniqueByKeepLast(data,key){
        return[ ...new Map(data.map( x => [key(x), x])).values()]}

    // State logic for loading account including shared media
    React.useEffect(() => {
        async function getMyAccount() {
            let myAccount
            const result = await API.graphql(graphqlOperation(getAccountWithSubscriptionsTeamsS3, {
                id: appUser.username
            }))
            // console.log(result)
            myAccount = result.data['getAccount']
            if (mounted) {
                const credentials = await Auth.currentCredentials();
                console.log('Ready with await')

                myAccount.user_identity_id = credentials.identityId
                setAccount(myAccount)
                setAccountChanged(true)
                let tmpTeamList = myAccount['teams']['items']

                let tmpEAList = myAccount['encode_analyses']['items'].map(item => item.encodeAnalysis)
                setEncodeAnalysesSharedWithMe(uniqueByKeepLast(tmpEAList, it => it.id ))
                tmpEAList = []
                for (let i=0; i< tmpTeamList.length;i++){
                    tmpEAList = tmpEAList.concat(tmpTeamList[i]['team']['encode_analyses']['items'].map(item => item.encodeAnalysis))
                }
                setEncodeAnalysesSharedWithTeam(uniqueByKeepLast(tmpEAList, it => it.id ))

                let tmpMediaList = myAccount['media']['items'].map(item => item.media)
                setMediaSharedWithMe(uniqueByKeepLast(tmpMediaList, it => it.id ))
                tmpMediaList = []
                for (let i=0; i< tmpTeamList.length;i++){
                    tmpMediaList = tmpMediaList.concat(tmpTeamList[i]['team']['media']['items'].map(item => item.media))
                }
                setMediaSharedWithTeam(uniqueByKeepLast(tmpMediaList, it => it.id ))

                // console.log(myAccount['comparisons']['items'])
                let tmpCompList = myAccount['comparisons']['items'].map(item => item.comparison)
                setComparisonsSharedWithMe(uniqueByKeepLast(tmpCompList, it => it.id ))
                tmpCompList = []
                for (let i=0; i< tmpTeamList.length;i++){
                    tmpCompList = tmpCompList.concat(tmpTeamList[i]['team']['comparisons']['items'].map(item => item.comparison))
                }
                // console.log(tmpCompList)
                setComparisonsSharedWithTeam(uniqueByKeepLast(tmpCompList, it => it.id ))
            }
        }
        async function getMyAccessRights() {
            if (appUser['signInUserSession']['idToken']['payload']['cognito:groups']) {
                if (appUser['signInUserSession']['idToken']['payload']['cognito:groups'].includes("SystemAdmin")){
                    setAdminVisible(true)
                } else {
                    setAdminVisible(false)
                }
            } else {
                setAdminVisible(false)
            }
        }

        let mounted = true
        if (signedIn && appUser) {
            console.log('Loading data ...')
            getMyAccount().then()
            getMyAccessRights().then()
        }
        console.log('triggered by change in appUser, signedIn')

        return function cleanup() {
            mounted = false;
        };
    }, [appUser, signedIn])

    // console.log(encodeAnalysesSharedWithMe)

    // console.log(account)
    // State logic for dataReady
    React.useEffect(() => {
        if (signedIn && appUser && account) {
            if (!dataReady) {
                console.log('Data is loaded')
                setDataReady(false)
            }
        } else {
            if (dataReady) {
                console.log('Data is cleared')
                // setDataReady(false)
            }
        }
        console.log('triggered by change in account, appUser, dataReady, signedIn')

    }, [account, appUser, dataReady, signedIn])

    /**
     * ***************************************************************************************************************
     * Media
     * ***************************************************************************************************************
     */

    // List Media
    React.useEffect(() => {
        async function listMyMedia() {
            let media_items = []
            let nextToken = null
            do {
                const result = await API.graphql(graphqlOperation(listMedia, {
                    filter: {'accountID': {'eq': account.id}, 'deleted': {'attributeExists': false}},
                    limit: 999,
                    nextToken: nextToken
                }))
                nextToken = result.data['listMedia']['nextToken']
                media_items = media_items.concat(result.data['listMedia']['items'])
            } while (nextToken != null)
            console.log('Ready with await')

            setMedia(media_items.sort(dynamicSort("-createdAt")))
        }

        if (account) {
            listMyMedia().then()
        }
        console.log('triggered by change in account')

    }, [account])
    // console.log(media)

    const handleRemoveMedia = async (media_id, tabIndexMedia) => {
        // eslint-disable-next-line no-unused-vars
        const response = await deleteMedia(media_id);
        console.log('Ready with await')

        console.log(response)
        console.log(media_id, tabIndexMedia)
        if (tabIndexMedia === '0'){
            setMedia(media.filter(item => item.id !== media_id))
            console.log('Removed ', media_id)
        } else if (tabIndexMedia === '1'){
            setMediaSharedWithMe(mediaSharedWithMe.filter(item => item.id !== media_id))
        }
    }

    // const handleMediaImportFromS3 = async (e) => {
    //     const s3_object_key = e.target.attributes.getNamedItem('data-s3_object_key').value
    //     const filename = "arn:aws:s3:::" + account.s3_bucket + "/" + s3_object_key
    //     let response = await postMedia(filename);
    //     console.log('Ready with await')
    //
    //     // console.log(response)
    //     const media_id = response['id']
    //     response = await putMedia(media_id);
    //     console.log('Ready with await')
    //
    //     // console.log(response)
    //     setMedia(prevMedia => {
    //         return [...prevMedia, {
    //             'id': media_id,
    //             'filename': s3_object_key,
    //             'status': 'Deploying VQaaS',
    //             'createdAt': 'Just now',
    //             'account': account,
    //             'teams': {'items':[]},
    //             'persons': {'items':[]}
    //         }].sort(dynamicSort("-createdAt"))
    //     })
    // }

    // const handleMediaDropFromS3 = async (s3_bucket, s3_object_key) => {
    //     const filename = "arn:aws:s3:::" + s3_bucket + "/" + s3_object_key
    //     const now = new Date()
    //     const alarm_id = now.getTime()
    //     setMediaPageImportAlarmList(prevAlarms => [...prevAlarms, {
    //         key: alarm_id,
    //         variation: 'info',
    //         isDismissible: false,
    //         heading:'Checking access rights ' + filename,
    //         text: ''}])
    //     let response = await postMedia(filename);
    //     console.log('Ready with await')
    //
    //     // console.log(response)
    //     const media_id = response['id']
    //     response = await putMedia(media_id);
    //     console.log('Ready with await')
    //
    //     // console.log(response)
    //     setMediaPageImportAlarmList(prevAlarms => {
    //         return prevAlarms.map(item =>
    //             item.key === alarm_id ? {
    //                     key: alarm_id,
    //                     variation: 'success',
    //                     isDismissible: true,
    //                     heading:'Checking access rights ' + filename,
    //                     text: 'Success'}
    //                 : item)
    //     })
    //     setMedia(prevMedia => {
    //         return [...prevMedia, {
    //             'id': media_id,
    //             'filename': s3_object_key,
    //             'status': 'Deploying VQaaS',
    //             'createdAt': 'Just now',
    //             'account': account,
    //             'teams': {'items':[]},
    //             'persons': {'items':[]}
    //         }].sort(dynamicSort("-createdAt"))
    //     })
    // }

    // const handleMediaImportFromPC = async (file) => {
    //     setRefUploadProgress(0)
    //     let response = await postMedia(file.name);
    //     // console.log(response)
    //     const media_id = response['id']
    //
    //     setMediaPageImportAlarmList(prevAlarms => [...prevAlarms, {
    //         key: media_id,
    //         variation: 'info',
    //         heading:'Uploading ' + file.name,
    //         text: 'Progress: 0%'}])
    //     await Storage.put(
    //         media_id + "/" + file.name,
    //         file,
    //         {
    //             level: 'protected',
    //             metadata: {
    //                 owner: appUser.username,
    //             },
    //             progressCallback(progress) {
    //                 if ((Math.floor(100 * progress.loaded / progress.total)) === 100) {
    //                     setMediaPageImportAlarmList(prevAlarms => {
    //                         return prevAlarms.map(item =>
    //                             item.key === media_id ? {
    //                                     key: media_id,
    //                                     variation: 'success',
    //                                     isDismissible: true,
    //                                     heading:'Uploaded ' + file.name}
    //                                 : item)
    //                     })
    //                 } else {
    //                     setMediaPageImportAlarmList(prevAlarms => {
    //                         return prevAlarms.map(item =>
    //                             item.key === media_id ? {
    //                                     key: media_id,
    //                                     variation: 'info',
    //                                     heading:'Uploading ' + file.name,
    //                                     text: 'Progress: '+ Math.floor(100 * progress.loaded / progress.total) + '%'}
    //                                 : item)
    //                     })
    //                 }
    //             }
    //         }
    //     );
    //
    //     response = await putMedia(media_id);
    //     console.log('Ready with await')
    //
    //     // console.log(response)
    //     setMedia(prevMedia => {
    //         return [...prevMedia, {
    //             'id': media_id,
    //             'filename': file.name,
    //             'status': 'Deploying VQaaS',
    //             'createdAt': 'Just now',
    //             'account': account,
    //             'teams': {'items':[]},
    //             'persons': {'items':[]}
    //         }].sort(dynamicSort("-createdAt"))
    //     })
    // }

    // const handleDismissImportAlert = async (key) => {
    //     // console.log(key)
    //     setMediaPageImportAlarmList(prevAlarms => prevAlarms.filter(item => item.key !== key))
    // }
    /**
     * ***************************************************************************************************************
     * Encode Analysis
     * ***************************************************************************************************************
     */

    async function postEncodeAnalysis(referenceMediaId, encodedMediaId, referenceDeinterlace, encodedDeinterlace,
                                      framerateSynchronizer, scaler, vmafModel) {
        const apiName = 'vqaasapi';
        const path = '/encode-analysis-cognito';
        const user = await Auth.currentAuthenticatedUser()
        console.log('Ready with await')

        const token = user['signInUserSession']['idToken']['jwtToken']
        let myInit =
            {
                headers: {
                    Authorization: token,
                },
                body:
                    {
                        'reference_id': referenceMediaId,
                        'encoded_id': encodedMediaId,
                        'referenceDeinterlace': referenceDeinterlace,
                        'encodedDeinterlace': encodedDeinterlace,
                        'framerateSynchronizer': framerateSynchronizer,
                        'scaler': scaler,
                        'vmafModel': vmafModel
                    }
            }
        return API.post(apiName, path, myInit);
    }

    async function deleteEncodeAnalysis(id) {
        const apiName = 'vqaasapi';
        const path = '/encode-analysis-cognito/' + id;
        const user = await Auth.currentAuthenticatedUser()
        console.log('Ready with await')

        const token = user['signInUserSession']['idToken']['jwtToken']
        let myInit = {
            headers: {
                Authorization: token,
            }
        }
        return API.del(apiName, path, myInit);
    }

    async function putEncodeAnalysis(id, shareWithId, type) {
        const apiName = 'vqaasapi';
        const path = '/encode-analysis-cognito/' + id + '/share';
        const user = await Auth.currentAuthenticatedUser()
        console.log('Ready with await')

        const token = user['signInUserSession']['idToken']['jwtToken']
        let myInit = {
            headers: {
                Authorization: token,
            },
            body:
                {
                    'share_with_id': shareWithId,
                    'type': type
                }
        }
        return API.put(apiName, path, myInit);
    }

    async function handleRefresh(){
        console.log('Refresh clicked')
        let media_items = []
        let nextToken = null
        do {
            const result = await API.graphql(graphqlOperation(listMedia, {
                filter: {'accountID': {'eq': account.id}, 'deleted': {'attributeExists': false}},
                limit: 999,
                nextToken: nextToken
            }))
            nextToken = result.data['listMedia']['nextToken']
            media_items = media_items.concat(result.data['listMedia']['items'])
        } while (nextToken != null)
        setMedia(media_items.sort(dynamicSort("-createdAt")))
        let encode_analysis_items = []
        nextToken = null
        do {
            const result = await API.graphql(graphqlOperation(listEncodeAnalysesTeams, {
                filter: {'accountID': {'eq': account.id}, 'deleted': {'attributeExists': false}},
                limit: 999,
                nextToken: nextToken
            }))
            nextToken = result.data['listEncodeAnalyses'].nextToken
            encode_analysis_items = encode_analysis_items.concat(result.data['listEncodeAnalyses'].items)
        } while (nextToken != null)
        console.log('Ready with await')

        setEncodeAnalyses(encode_analysis_items.sort(dynamicSort("-createdAt")))
    }

    // List Encode Analyses
    React.useEffect(() => {
        async function listMyEncodeAnalyses() {
            let myEncodeAnalyses = []
            let nextToken = null
            do {
                const result = await API.graphql(graphqlOperation(listEncodeAnalysesTeams, {
                    filter: {'accountID': {'eq': account.id}, 'deleted': {'attributeExists': false}},
                    nextToken: nextToken,
                    limit: 999,
                }))
                // console.log(result)
                nextToken = result.data['listEncodeAnalyses'].nextToken
                myEncodeAnalyses = myEncodeAnalyses.concat(result.data['listEncodeAnalyses'].items)
            } while (nextToken != null)
            setEncodeAnalyses(myEncodeAnalyses.sort(dynamicSort("-createdAt")))
            console.log('Ready with await')

        }

        if (account) {
            listMyEncodeAnalyses().then(() => {
                console.log('Setting DataReady True')
                setDataReady(true)
            })
        }
        console.log('triggered by change in account')

    }, [account])
    const handleEncodeAnalysis = async (referenceMediaId, encodedMediaId, referenceDeinterlace, encodedDeinterlace,
                                        framerateSynchronizer, scaler, vmafModel, referenceMedia, encodedMedia) => {
        let response = await postEncodeAnalysis(referenceMediaId, encodedMediaId, referenceDeinterlace, encodedDeinterlace,
            framerateSynchronizer, scaler, vmafModel)
        // console.log(response)
        console.log('Ready with await')

        setEncodeAnalyses(prevEncodeAnalyses => {
            return [...prevEncodeAnalyses, {
                'id': response['id'],
                'refMedia':referenceMedia,
                'encMedia': encodedMedia,
                'vmaf_reduct': "[]",
                'psnr_reduct': "[]",
                'status': 'Deploying VQaaS',
                'account': account,
                'teams': {'items':[]},
                'persons': {'items':[]},
                'createdAt': '9999'
            }].sort(dynamicSort("-createdAt"))
        })

    }

    const handleRemoveEncodeAnalysis = async (encode_analysis_id, tabIndex) => {
        // eslint-disable-next-line no-unused-vars
        const response = await deleteEncodeAnalysis(encode_analysis_id);
        console.log('Ready with await')

        // console.log(response)
        if (tabIndex === '0'){
            setEncodeAnalyses(encodeAnalyses.filter(item => item.id !== encode_analysis_id))
        } else if (tabIndex === '1'){
            setEncodeAnalysesSharedWithMe(encodeAnalysesSharedWithMe.filter(item => item.id !== encode_analysis_id))
        }
    }



    /**
     * ***************************************************************************************************************
     * Comparison
     * ***************************************************************************************************************
     */

    async function postComparison(compareCollection) {
        const apiName = 'vqaasapi';
        const path = '/comparison-cognito';
        const user = await Auth.currentAuthenticatedUser()
        console.log('Ready with await')

        const token = user['signInUserSession']['idToken']['jwtToken']
        let myInit =
            {
                headers: {
                    Authorization: token,
                },
                body:
                    {
                        'encodeAnalysis': compareCollection,
                    }
            }
        return API.post(apiName, path, myInit);
    }

    async function putComparison(id, shareWithId, type) {
        const apiName = 'vqaasapi';
        const path = '/comparison-cognito/' + id + '/share';
        const user = await Auth.currentAuthenticatedUser()
        console.log('Ready with await')

        const token = user['signInUserSession']['idToken']['jwtToken']
        let myInit = {
            headers: {
                Authorization: token,
            },
            body:
                {
                    'share_with_id': shareWithId,
                    'type': type
                }
        }
        return API.put(apiName, path, myInit);
    }

    async function deleteComparison(id) {
        const apiName = 'vqaasapi';
        const path = '/comparison-cognito/' + id;
        const user = await Auth.currentAuthenticatedUser()
        console.log('Ready with await')

        const token = user['signInUserSession']['idToken']['jwtToken']
        let myInit = {
            headers: {
                Authorization: token,
            }
        }
        return API.del(apiName, path, myInit);
    }

    async function handleCompareClick(compareCollection){
        // eslint-disable-next-line no-unused-vars
        let response = await postComparison(compareCollection)
        console.log(response)
        let data = await listMyComparisons(account.id)
        console.log('Ready with await')

        setComparisons(data)
    }

    const handleRemoveComparison = async (comparison_id, tabIndexComparison) => {
        // eslint-disable-next-line no-unused-vars
        const response = await deleteComparison(comparison_id);
        console.log('Ready with await')

        if (tabIndexComparison === '0') {
            setComparisons(comparisons.filter(item => item.id !== comparison_id))
        } else if (tabIndexComparison === '1'){
            setComparisonsSharedWithMe(comparisonsSharedWithMe.filter(item => item.id !== comparison_id))
        }
    }

    const listMyComparisons = async (accountID) => {
        // console.log(accountID)
        const result = await API.graphql(graphqlOperation(listComparisonsTeams, {
            filter: {'accountID': {'eq': accountID}, 'deleted': {'attributeExists': false}},
            limit: 999,
        }))
        console.log('Ready with await')

        // console.log(result)
        return result.data['listComparisons'].items.sort(dynamicSort("-createdAt"))
    }

    React.useEffect(() => {
        let mounted = true
        if (account) {
            listMyComparisons(account.id).then(data => {if (mounted) setComparisons(data)})
        }
        console.log('triggered by change in account')
        return function cleanup() {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    /**
     * ***************************************************************************************************************
     * Share Modal Window
     * ***************************************************************************************************************
     */
    const [userList, setUserList] = React.useState([])
    const [teamList, setTeamList] = React.useState([])
    const {teams} = account || {}


    // Initialise userList and teamList
    React.useEffect(() => {
        if (teams) {
            setTeamList(teams.items.map( item => {return {id:item['team']['id'], name:item['team']['name']}}))
            if (teams.items) {
                let tmpAccountList = teams.items.map(item => item.team.accounts.items)
                let tmpUserList = []
                for (let i=0; i<tmpAccountList.length; i++){
                    tmpUserList = tmpUserList.concat(tmpAccountList[i])
                }
                tmpUserList = tmpUserList.map(item => item.account)
                tmpUserList = uniqueByKeepLast(tmpUserList, it => it.id)
                tmpUserList = tmpUserList.map(item => {
                    if (!item['first_name']) item['first_name'] = item['email']
                    if (!item['last_name']) item['last_name'] = ''
                    return item})
                setUserList(tmpUserList)
            }
        } else {
            setTeamList([])
        }
        console.log('triggered by change in teams')
    },[teams])

    const handleShareItem = async (id, item, objectType) => {
        if (objectType === 'media') {
            putMediaShare(id, item.id, item.type).then()
        } else if (objectType === 'encodeAnalysis'){
            putEncodeAnalysis(id, item.id, item.type).then()
        } else if (objectType === 'Comparison'){
            putComparison(id, item.id, item.type).then()
        }
    }
    console.log('Change in app.js', dataReady)
    return (
            <div
                id={"background-image"}
                style={{
                    backgroundImage: `url(${background})`,
                }}
            >
                <Authenticator
                    components={components}
                    formFields={formFields}
                >
                    {({signOut, user}) => (
                        <>
                            {!dataReady ?
                                <SplashScreen
                                    signedIn={signedIn}
                                    user={user}
                                    onUserChanged={handleOnUserChange}
                                />
                                :
                                <BrowserRouter>
                                    <Switch>
                                        <Route
                                            path="/media/:media_id"
                                            render={(props) =>
                                                <MediaDetailPage
                                                    {...props}
                                                    signOut={signOut}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    user={user}
                                                    media={media}
                                                    onSignOutClick={handleSignOutClick}
                                                    onRefresh={handleRefresh}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/edit-team/:team_id"
                                            render={(props) =>
                                                <EditTeam
                                                    {...props}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    signOut={signOut}
                                                    user={user}
                                                    onSignOutClick={handleSignOutClick}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/delete-team/:team_id"
                                            render={(props) =>
                                                <DeleteTeam
                                                    {...props}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    signOut={signOut}
                                                    user={user}
                                                    onSignOutClick={handleSignOutClick}
                                                />
                                            }
                                        />
                                        {/*<Route*/}
                                        {/*    path="/show-team/:team_id"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <ShowTeam*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            user={user}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        <Route
                                            path="/encode-analysis/:encode_analysis_id"
                                            render={(props) =>
                                                <EncodeAnalysisPage
                                                    {...props}
                                                    signOut={signOut}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    encodeAnalyses={encodeAnalyses}
                                                    user={user}
                                                    onSignOutClick={handleSignOutClick}
                                                    onRefresh={handleRefresh}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/compare/:encode_analysis_id_1/:encode_analysis_id_2/:encode_analysis_id_3?/:encode_analysis_id_4?"
                                            render={(props) =>
                                                <CompareAnalysisPage
                                                    {...props}
                                                    signOut={signOut}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    user={user}
                                                    encodeAnalyses={encodeAnalyses}
                                                    onSignOutClick={handleSignOutClick}
                                                    onRefresh={handleRefresh}
                                                />
                                            }
                                        />
                                        <Route
                                            path='/synamedia'
                                            component={() => {
                                                window.location.href = 'https://www.synamedia.com/video-solutions/video-network/';
                                                return null;
                                            }}
                                        />
                                        <Route
                                            path="/media-page"
                                            render={(props) =>
                                                <MediaPage
                                                    {...props}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    user={user}
                                                    media={media}
                                                    mediaSharedWithMe={mediaSharedWithMe}
                                                    mediaSharedWithTeam={mediaSharedWithTeam}
                                                    encodeAnalyses={encodeAnalyses}
                                                    encodeAnalysesSharedWithMe={encodeAnalysesSharedWithMe}
                                                    encodeAnalysesSharedWithTeam={encodeAnalysesSharedWithTeam}
                                                    onStartEncodeAnalysis={handleEncodeAnalysis}
                                                    // onMediaImportFromPC={handleMediaImportFromPC}
                                                    // onMediaDropFromS3={handleMediaDropFromS3}
                                                    // mediaPageImportAlarmList={mediaPageImportAlarmList}
                                                    // onDismissImportAlert={handleDismissImportAlert}
                                                    onRefresh={handleRefresh}
                                                    onRemoveMedia={handleRemoveMedia}
                                                    onRemoveEncodeAnalysis={handleRemoveEncodeAnalysis}
                                                    onRemoveComparison={handleRemoveComparison}
                                                    onShareItem={handleShareItem}
                                                    userList={userList}
                                                    teamList={teamList}
                                                    onCompareClick={handleCompareClick}
                                                    comparisons={comparisons}
                                                    comparisonsSharedWithMe={comparisonsSharedWithMe}
                                                    comparisonsSharedWithTeam={comparisonsSharedWithTeam}
                                                />
                                            }
                                        />
                                        {/*<Route*/}
                                        {/*    path="/rate-quality-testing"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <RateQualityTesting*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        {/*<Route*/}
                                        {/*    path="/monitor"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <MonitorPage*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        {/*<Route*/}
                                        {/*    path="/rate-quality-wizard/:wizard_id/:action"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <RateQualityWizard*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        {/*<Route*/}
                                        {/*    path="/vod-dashboard"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <VodDashboard*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        {/*<Route*/}
                                        {/*    path="/vod-remote-source"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <VodRemoteSource*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        {/*<Route*/}
                                        {/*    path="/vod-local-source"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <VodLocalSource*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        {/*<Route*/}
                                        {/*    path="/vod-monitoring-points"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <VodMonitoringPoints*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        {/*<Route*/}
                                        {/*    path="/vod-configure-monitoring"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <VodConfigureMonitoring*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        {/*<Route*/}
                                        {/*    path="/vod-add-monitoring-point"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <VodAddMonitoringPoint*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        {/*<Route*/}
                                        {/*    path="/vod-file"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <VodFile*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        {/*<Route*/}
                                        {/*    path="/vod-monitor/:reference_id"*/}
                                        {/*    render={(props) =>*/}
                                        {/*        <VodMonitor*/}
                                        {/*            {...props}*/}
                                        {/*            account={account}*/}
                                        {/*            adminVisible={adminVisible}*/}
                                        {/*            signOut={signOut}*/}
                                        {/*            onSignOutClick={handleSignOutClick}*/}
                                        {/*            user={user}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                        <Route
                                            path="/channel/:channel_id"
                                            render={(props) =>
                                                <ChannelPage
                                                    {...props}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    user={user}
                                                    media={media}
                                                    encodeAnalyses={encodeAnalyses}
                                                    encodeAnalysesSharedWithMe={encodeAnalysesSharedWithMe}
                                                    mediaSharedWithMe={mediaSharedWithMe}
                                                    onStartEncodeAnalysis={handleEncodeAnalysis}
                                                    // onMediaImportFromPC={handleMediaImportFromPC}
                                                    // onMediaDropFromS3={handleMediaDropFromS3}
                                                    // mediaPageImportAlarmList={mediaPageImportAlarmList}
                                                    // onDismissImportAlert={handleDismissImportAlert}
                                                    onRemoveMedia={handleRemoveMedia}
                                                    onRemoveEncodeAnalysis={handleRemoveEncodeAnalysis}
                                                    onRemoveComparison={handleRemoveComparison}
                                                    onShareItem={handleShareItem}
                                                    userList={userList}
                                                    teamList={teamList}
                                                    onCompareClick={handleCompareClick}
                                                    comparisons={comparisons}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/viewerdigitaltwin/dashboard"
                                            render={(props) =>
                                                <ViewerDigitalTwinDashboard
                                                    {...props}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    adminVisible={adminVisible}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/viewerdigitaltwin/channel/:channel_id"
                                            render={(props) =>
                                                <ViewerDigitalTwinChannel
                                                    {...props}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    adminVisible={adminVisible}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/viewerdigitaltwin/monitorpoint/:monitorpoint_id"
                                            render={(props) =>
                                                <ViewerDigitalTwinMonitorPoint
                                                    {...props}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    adminVisible={adminVisible}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/smx/:smx_id"
                                            render={(props) =>
                                                <SmxPage
                                                    {...props}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    user={user}
                                                    userList={userList}
                                                    teamList={teamList}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/s3-page"
                                            render={(props) =>
                                                <S3Page
                                                    {...props}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    // onImportFromS3={handleMediaImportFromS3}
                                                    // onMediaImportFromPC={handleMediaImportFromPC}
                                                    // refUploadProgress={refUploadProgress}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    user={user}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/profile-page"
                                            render={(props) =>
                                                <ProfilePage
                                                    {...props}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    onChangeAccount={handleChangeAccount}
                                                    user={user}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/add-team"
                                            render={(props) =>
                                                <AddTeam
                                                    {...props}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    user={user}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/list-team"
                                            render={(props) =>
                                                <ListTeams
                                                    {...props}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    user={user}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/list-accounts"
                                            render={(props) =>
                                                <ListAccounts
                                                    {...props}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    user={user}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/dashboard-accounts"
                                            render={(props) =>
                                                <DashboardAccounts
                                                    {...props}
                                                    account={account}
                                                    adminVisible={adminVisible}
                                                    signOut={signOut}
                                                    onSignOutClick={handleSignOutClick}
                                                    user={user}
                                                />
                                            }
                                        />
                                        <Redirect
                                            to="/media-page"/>
                                    </Switch>
                                </BrowserRouter>}
                        </>
                    )}
                </Authenticator>
            </div>
    )
}

import React from "react";
import {Alert, Button, Collection, Flex, Heading, Icon, TabItem, Tabs, Text, View} from '@aws-amplify/ui-react';
import {Storage} from 'aws-amplify';
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import WhiteNavbar from "../../components/Navbars/WhiteNavbar";
import MediaCard from "../../components/vqaas/MediaCard";
import EncodeAnalysisCard from "../../components/vqaas/EncodeAnalysisCard";
// import ControlPanelCard from "../../components/vqaas/ControlPanelCard";
import ComparisonCard from "../../components/vqaas/ComparisonCard";
import {postMedia, putMedia} from "../../components/vqaas/ApiFunctions";
import Popup from "reactjs-popup"
import S3Browser from "../../components/vqaas/S3Browser";

function MediaPage(props) {
    const [showReference, setShowReference] = React.useState(false)
    const [showEncodedVersion, setShowEncodedVersion] = React.useState(false)
    const [referenceMedia, setReferenceMedia] = React.useState({})
    const [encodedMedia, setEncodedMedia] = React.useState({})
    const [referenceDeinterlace, ] = React.useState('Auto');
    const [encodedDeinterlace, ] = React.useState('Auto');
    const [framerateSynchronizer, ] = React.useState('Auto');
    const [scaler, ] = React.useState('BasedOnVMAF');
    const [vmafModel, ] = React.useState('HD');
    const [compareCollection, setCompareCollection] = React.useState([])
    const [tabIndex, setTabIndex] = React.useState('0')
    const [tabIndexMedia, setTabIndexMedia] = React.useState('0')
    const [tabIndexComparison, setTabIndexComparison] = React.useState('0')
    const [encodeAnalysisCollection, setEncodeAnalysisCollection] = React.useState()
    const [mediaCollection, setMediaCollection] = React.useState()
    const [comparisonCollection, setComparisonCollection] = React.useState()
    const [mediaPageImportAlarmList, setMediaPageImportAlarmList] = React.useState([{
        key: (new Date()).getTime(),
        variation: 'info',
        isDismissible: true,
        heading:'Browser compatibility',
        text: 'The drag and drop functionality has been tested on Chrome, Edge and Firefox. Mobile devices are not supported.'}])
    const [isDisabled, setIsDisabled] = React.useState(false)
    const refFileInput = React.createRef();

    //
    // Declarations used for sharing
    //
    // The users that will be added are stored in shareWith
    const [shareWith, setShareWith] = React.useState([])
    // The users from which you can choose are stored in shareWithSelection
    const [shareWithSelection, setShareWithSelection] = React.useState([])
    const [searchFieldValue, setSearchFieldValue] = React.useState('')
    const handleCloseShareWith = React.useCallback((data) => {
        console.log(data)
        setShareWith(prevBadges => prevBadges.filter(item => item['id'] !== data))},[])
    const handleClickShareWithSelection  = React.useCallback( (data) => {
        setShareWith(prevBadges => [...prevBadges, data])
        setShareWithSelection([])
        setSearchFieldValue('')
    }, [])
    const handleSearchFieldClear = React.useCallback(() => {
        setSearchFieldValue('')
        setShareWithSelection([])
    },[])
    const handleSearchFieldChange = React.useCallback( (e) => {
        const searchString = e.target.value
        setSearchFieldValue(searchString)
        const searchTerms = searchString.split(' ')
        if (searchTerms.length) {
            // noinspection JSCheckFunctionSignatures
            setShareWithSelection(
                props.userList.filter(item => {
                        let match = true
                        for (let i = 0; i < searchTerms.length; i++) {
                            match = match && (item['first_name'].startsWith(searchTerms[i]) || item['last_name'].startsWith(searchTerms[i]) || item['email'].startsWith(searchTerms[i]))
                        }
                        return match
                    }
                ).map(item => {
                        item['label'] = item['first_name'] + ' ' + item['last_name']
                        item['type'] = 'person'
                        item['initials'] = item['first_name'][0] + item['last_name'][0]
                        return item
                    }
                ).concat(
                    props.teamList.filter(item => {
                            let match = true
                            for (let i = 0; i < searchTerms.length; i++) {
                                match = match && item['name'].startsWith(searchTerms[i])
                            }
                            return match
                        }
                    ).map(item => {
                        item['label'] = item['name'] + ' members'
                        item['type'] = 'team'
                        item['initials'] = item['name'][0]
                        return item
                    })
                )
            )
        } else {
            setShareWithSelection([])
        }
    },[props.teamList, props.userList])
    const handleSearchFieldSubmit = React.useCallback( (e) => {console.log(e)}, [])
    const handleShareButtonClick = (id, objectType) => {
        console.log('Share Button Clicked', id, objectType)
        shareWith.forEach(item => {
            console.log(id, item, objectType )
            props.onShareItem(id, item, objectType)
        })
        setModalId('')
        setShareWithSelection([])
        setSearchFieldValue('')
        setShareWith([])
    }
    const handleOpenModal = React.useCallback( (id) => {setModalId(id)}, [])
    const handleCloseModal = React.useCallback( (id) => {
        setModalId('')
        setShareWithSelection([])
        setSearchFieldValue('')
        setShareWith([])
    }, [])
    const [modalId, setModalId] = React.useState('')
    const [openS3Popup, setOpenS3Popup] = React.useState(false)

    React.useEffect(() => {
        // console.log('encodeAnalyses changed', tabIndex)
        // console.log(props.encodeAnalyses, props.encodeAnalysesSharedWithMe)
        if (tabIndex === '0'){
            // console.log('Tabindex 0')
            setEncodeAnalysisCollection(props.encodeAnalyses)
        } else if (tabIndex === '1'){
            // console.log('Tabindex 1')
            setEncodeAnalysisCollection(props.encodeAnalysesSharedWithMe)
        } else if (tabIndex === '2'){
            // console.log('Tabindex 1')
            setEncodeAnalysisCollection(props.encodeAnalysesSharedWithTeam)
        }
    }, [props.encodeAnalyses, props.encodeAnalysesSharedWithMe, props.encodeAnalysesSharedWithTeam, tabIndex])

    React.useEffect(() => {
        // console.log('encodeAnalyses changed', tabIndex)
        // console.log(props.media, props.mediaSharedWithMe)
        if (tabIndexMedia === '0'){
            // console.log('Tabindex 0')
            setMediaCollection(props.media)
        } else if (tabIndexMedia === '1') {
            // console.log('Tabindex 1')
            setMediaCollection(props.mediaSharedWithMe)
        } else if (tabIndexMedia === '2') {
            // console.log('Tabindex 2')
            setMediaCollection(props.mediaSharedWithTeam)
        }
    }, [props.media, props.mediaSharedWithMe, props.mediaSharedWithTeam, tabIndexMedia])

    React.useEffect(() => {
        // console.log('encodeAnalyses changed', tabIndex)
        // console.log(props.media, props.mediaSharedWithMe)
        if (tabIndexComparison === '0'){
            // console.log('Tabindex 0')
            setComparisonCollection(props.comparisons)
        } else if (tabIndexComparison === '1'){
            // console.log('Tabindex 1')
            setComparisonCollection(props.comparisonsSharedWithMe)
        } else if (tabIndexComparison === '2'){
            // console.log('Tabindex 2')
            setComparisonCollection(props.comparisonsSharedWithTeam)
        }
    }, [props.comparisons, props.comparisonsSharedWithMe, props.comparisonsSharedWithTeam, tabIndexComparison])


    let drop_zones = [
        "dropzone_import", "dropzone_import_text",
        "dropzone_analyse_reference", "dropzone_analyse_reference_text",
        "dropzone_analyse_encode", "dropzone_analyse_encode_text",
        "dropzone_compare", "dropzone_compare_text"];

    window.addEventListener("dragenter", function(e) {
        if (!drop_zones.includes(e.target.id)) {
            e.preventDefault();
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        }
    }, false);

    window.addEventListener("dragover", function(e) {
        if (!drop_zones.includes(e.target.id)) {
            e.preventDefault();
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        }
    });

    window.addEventListener("drop", function(e) {
        if (!drop_zones.includes(e.target.id)) {
            e.preventDefault();
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        }
    });
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });

    function mediaStartsWith(reference, keyword) {
        const words = keyword.split(' ')
        let condition = true
        for (let word of words){
            let subCondition = false
            subCondition = subCondition || reference.filename.includes(word)
            if (reference.spec_width && reference.spec_height && reference.spec_frame_rate){
                subCondition = subCondition || (reference.spec_width.toString() + 'x' + reference.spec_height.toString() + '@' + reference.spec_frame_rate).includes(word)
            }
            condition = condition && subCondition
        }
        return condition
    }

    function encodeAnalysisStartsWith(encodeAnalysis, keyword) {
        const words = keyword.split(' ')
        let condition = true
        for (let word of words){
            let subCondition = false
            subCondition = subCondition || encodeAnalysis.refMedia.filename.includes(word)
            subCondition = subCondition || encodeAnalysis.encMedia.filename.includes(word)
            if (encodeAnalysis.encMedia.spec_width && encodeAnalysis.encMedia.spec_height && encodeAnalysis.encMedia.spec_frame_rate){
                subCondition = subCondition || (encodeAnalysis.encMedia.spec_width.toString() + 'x' + encodeAnalysis.encMedia.spec_height.toString() + '@' + encodeAnalysis.encMedia.spec_frame_rate).includes(word)
            }
            condition = condition && subCondition
        }
        return condition
    }

    function comparisonStartsWith(comparison, keyword) {
        let subCondition = false
        for (let encodeAnalysis of comparison['encodeAnalyses']['items']){
            subCondition = subCondition || encodeAnalysisStartsWith(encodeAnalysis['encodeAnalysis'], keyword)
        }
        return subCondition
    }

    function allowDrop(ev) {
        ev.preventDefault();
    }

    function drop(ev, dropLocation) {
        ev.preventDefault();
        let data = ev.dataTransfer.getData("text");
            if (dropLocation==='reference') {
                if (tabIndexMedia === '0'){
                    setReferenceMedia(props.media.find(item => item.id === data))
                } else if (tabIndexMedia === '1'){
                    setReferenceMedia(props.mediaSharedWithMe.find(item => item.id === data))
                } else if (tabIndexMedia === '2'){
                    setReferenceMedia(props.mediaSharedWithTeam.find(item => item.id === data))
                }
                setShowReference(true)
            }
            if (dropLocation==='encoded version') {
                if (tabIndexMedia === '0'){
                    setEncodedMedia(props.media.find(item => item.id === data))
                } else if (tabIndexMedia === '1'){
                    setEncodedMedia(props.mediaSharedWithMe.find(item => item.id === data))
                } else if (tabIndexMedia === '2'){
                    setEncodedMedia(props.mediaSharedWithTeam.find(item => item.id === data))
                }
                setShowEncodedVersion(true)
            }
    }

    // console.log(compareCollection)
    function drop_analysis(ev) {
        ev.preventDefault();
        let data = ev.dataTransfer.getData("text");
        // console.log(data)
        if (!compareCollection.includes(data) ){
            setCompareCollection(prevCollection => [...prevCollection, data])
        }
    }

    async function drop_file(ev) {
        ev.preventDefault()
        ev.persist()
        // console.log(ev.dataTransfer.items)
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].type === 'text/plain') {
                    // If dropped items aren't files, reject them
                    ev.dataTransfer.items[i].getAsString(function (s) {
                        try {
                            const url = new URL(s)
                            if (url.hostname !== 's3.console.aws.amazon.com') return
                            if (! url.pathname.startsWith('/s3/object/')) return
                            const s3_bucket = url.pathname.replace('/s3/object/', '')
                            // console.log('Bucket: ', s3_bucket)
                            // console.log('Region: ', url.searchParams.get('region'))
                            const s3_object_key = url.searchParams.get('prefix')
                            // console.log('Key: ', s3_object_key)
                            handleMediaDropFromS3(s3_bucket, s3_object_key)
                        } catch (error) {
                            console.log(error)
                        }
                    })
                }
                if (ev.dataTransfer.items[i].kind === 'file') {
                    // If dropped items aren't files, reject them
                    let file = ev.dataTransfer.items[i].getAsFile()
                    console.log("... Drop: " + file.name);
                    // props.onMediaImportFromPC(file)
                    let response = await postMedia(file.name);
                    const media_id = response['id']
                    setMediaPageImportAlarmList(prevAlarms => [...prevAlarms, {
                        key: media_id,
                        variation: 'info',
                        heading:'Uploading ' + file.name,
                        text: 'Progress: 0%'}])
                    await Storage.put(
                        media_id + "/" + file.name,
                        file,
                        {
                            level: 'protected',
                            metadata: {
                                owner: props.user.username,
                            },
                            progressCallback(progress) {
                                if ((Math.floor(100 * progress.loaded / progress.total)) === 100) {
                                    setMediaPageImportAlarmList(prevAlarms => {
                                        return prevAlarms.map(item =>
                                            item.key === media_id ? {
                                                    key: media_id,
                                                    variation: 'success',
                                                    isDismissible: true,
                                                    heading:'Uploaded ' + file.name}
                                                : item)
                                    })
                                } else {
                                    // console.log('Progress: '+ Math.floor(100 * progress.loaded / progress.total) + '%')
                                    setMediaPageImportAlarmList(prevAlarms => {
                                        return prevAlarms.map(item =>
                                            item.key === media_id ? {
                                                    key: media_id,
                                                    variation: 'info',
                                                    heading:'Uploading ' + file.name,
                                                    text: 'Progress: '+ Math.floor(100 * progress.loaded / progress.total) + '%'}
                                                : item)
                                    })
                                }
                            }
                        }
                    )
                    await putMedia(media_id)
                    props.onRefresh()
                    // setMediaCollection(prevMedia => {
                    //     return [...prevMedia, {
                    //         'id': media_id,
                    //         'filename': file.name,
                    //         'status': 'Deploying VQaaS',
                    //         'createdAt': 'Just now',
                    //         'account': props.account,
                    //         'teams': {'items':[]},
                    //         'persons': {'items':[]}
                    //     }].sort(dynamicSort("-createdAt"))
                    // })
                }
            }
        }
    }

    const handleChange = async (e) => {
        setIsDisabled(true)
        let file = e.target.files.item(0);
        let response = await postMedia(file.name);
        const media_id = response['id']
        setMediaPageImportAlarmList(prevAlarms => [...prevAlarms, {
            key: media_id,
            variation: 'info',
            heading:'Uploading ' + file.name,
            text: 'Progress: 0%'}])
        await Storage.put(
            media_id + "/" + file.name,
            file,
            {
                level: 'protected',
                metadata: {
                    owner: props.user.username,
                },
                progressCallback(progress) {
                    if ((Math.floor(100 * progress.loaded / progress.total)) === 100) {
                        setMediaPageImportAlarmList(prevAlarms => {
                            return prevAlarms.map(item =>
                                item.key === media_id ? {
                                        key: media_id,
                                        variation: 'success',
                                        isDismissible: true,
                                        heading:'Uploaded ' + file.name}
                                    : item)
                        })
                    } else {
                        // console.log('Progress: '+ Math.floor(100 * progress.loaded / progress.total) + '%')
                        setMediaPageImportAlarmList(prevAlarms => {
                            return prevAlarms.map(item =>
                                item.key === media_id ? {
                                        key: media_id,
                                        variation: 'info',
                                        heading:'Uploading ' + file.name,
                                        text: 'Progress: '+ Math.floor(100 * progress.loaded / progress.total) + '%'}
                                    : item)
                        })
                    }
                }
            }
        )
        await putMedia(media_id)
        props.onRefresh()
        // setMediaCollection(prevMedia => {
        //     return [...prevMedia, {
        //         'id': media_id,
        //         'filename': file.name,
        //         'status': 'Deploying VQaaS',
        //         'createdAt': 'Just now',
        //         'account': props.account,
        //         'teams': {'items':[]},
        //         'persons': {'items':[]}
        //     }].sort(dynamicSort("-createdAt"))
        // })

        setIsDisabled(false)
    }

    const handleMediaDropFromS3 = async (s3_bucket, s3_object_key) => {
        const filename = "arn:aws:s3:::" + s3_bucket + "/" + s3_object_key
        const now = new Date()
        const alarm_id = now.getTime()
        setMediaPageImportAlarmList(prevAlarms => [...prevAlarms, {
            key: alarm_id,
            variation: 'info',
            isDismissible: false,
            heading:'Checking access rights ' + filename,
            text: ''}])
        let response = await postMedia(filename);
        // console.log(response)
        const media_id = response['id']
        response = await putMedia(media_id);
        // console.log(response)
        props.onRefresh()
        setMediaPageImportAlarmList(prevAlarms => {
            return prevAlarms.map(item =>
                item.key === alarm_id ? {
                        key: alarm_id,
                        variation: 'success',
                        isDismissible: true,
                        heading:'Checking access rights ' + filename,
                        text: 'Success'}
                    : item)
        })
        // setMediaCollection(prevMedia => {
        //     return [...prevMedia, {
        //         'id': media_id,
        //         'filename': s3_object_key,
        //         'status': 'Deploying VQaaS',
        //         'createdAt': 'Just now',
        //         'account': props.account,
        //         'teams': {'items':[]},
        //         'persons': {'items':[]}
        //     }].sort(dynamicSort("-createdAt"))
        // })
    }
    // const handleMediaImportFromPC = async (file) => {
    //     setRefUploadProgress(0)
    //     let response = await postMedia(file.name);
    //     // console.log(response)
    //     const media_id = response['id']
    //
    //     setMediaPageImportAlarmList(prevAlarms => [...prevAlarms, {
    //         key: media_id,
    //         variation: 'info',
    //         heading:'Uploading ' + file.name,
    //         text: 'Progress: 0%'}])
    //     await Storage.put(
    //         media_id + "/" + file.name,
    //         file,
    //         {
    //             level: 'protected',
    //             metadata: {
    //                 owner: appUser.username,
    //             },
    //             progressCallback(progress) {
    //                 if ((Math.floor(100 * progress.loaded / progress.total)) === 100) {
    //                     setMediaPageImportAlarmList(prevAlarms => {
    //                         return prevAlarms.map(item =>
    //                             item.key === media_id ? {
    //                                     key: media_id,
    //                                     variation: 'success',
    //                                     isDismissible: true,
    //                                     heading:'Uploaded ' + file.name}
    //                                 : item)
    //                     })
    //                 } else {
    //                     console.log('Progress: '+ Math.floor(100 * progress.loaded / progress.total) + '%')
    //                     setMediaPageImportAlarmList(prevAlarms => {
    //                         return prevAlarms.map(item =>
    //                             item.key === media_id ? {
    //                                     key: media_id,
    //                                     variation: 'info',
    //                                     heading:'Uploading ' + file.name,
    //                                     text: 'Progress: '+ Math.floor(100 * progress.loaded / progress.total) + '%'}
    //                                 : item)
    //                     })
    //                 }
    //             }
    //         }
    //     );
    //
    //     response = await putMedia(media_id);
    //     // console.log(response)
    //     setMedia(prevMedia => {
    //         return [...prevMedia, {
    //             'id': media_id,
    //             'filename': file.name,
    //             'status': 'Deploying VQaaS',
    //             'createdAt': 'Just now',
    //             'account': account,
    //             'teams': {'items':[]},
    //             'persons': {'items':[]}
    //         }].sort(dynamicSort("-createdAt"))
    //     })
    // }

    const handleDismissImportAlert = async (key) => {
        // console.log(key)
        setMediaPageImportAlarmList(prevAlarms => prevAlarms.filter(item => item.key !== key))
    }

    function onStartEncodeAnalysis(ev) {
        setShowEncodedVersion(false)
        setEncodedMedia({})
        props.onStartEncodeAnalysis(
            referenceMedia['id'], encodedMedia['id'],
            referenceDeinterlace, encodedDeinterlace,
            framerateSynchronizer, scaler, vmafModel, referenceMedia, encodedMedia)
    }

    function findEncodeAnalysis(encodeAnalysisId){
        let tmpEA = props.encodeAnalyses.find(encodeAnalysis => encodeAnalysis.id === encodeAnalysisId)
        if (tmpEA) return tmpEA
        tmpEA = props.encodeAnalysesSharedWithMe.find(encodeAnalysis => encodeAnalysis.id === encodeAnalysisId)
        if (tmpEA) return tmpEA
        tmpEA = props.encodeAnalysesSharedWithTeam.find(encodeAnalysis => encodeAnalysis.id === encodeAnalysisId)
        if (tmpEA) return tmpEA
    }

    function handleCompareClick(ev) {
        props.onCompareClick(compareCollection)
    }

    const handleMediaImportFromS3 = async (e) => {
        setOpenS3Popup(false)
        const s3_object_key = e.target.attributes.getNamedItem('data-s3_object_key').value
        const filename = "arn:aws:s3:::" + props.account.s3_bucket + "/" + s3_object_key
        const now = new Date()
        const alarm_id = now.getTime()
        setMediaPageImportAlarmList(prevAlarms => [...prevAlarms, {
            key: alarm_id,
            variation: 'info',
            isDismissible: false,
            heading:'Checking access rights ' + filename,
            text: ''}])

        let response = await postMedia(filename);
        // console.log(response)
        const media_id = response['id']
        response = await putMedia(media_id);
        console.log(response)
        props.onRefresh()
        setMediaPageImportAlarmList(prevAlarms => {
            return prevAlarms.map(item =>
                item.key === alarm_id ? {
                        key: alarm_id,
                        variation: 'success',
                        isDismissible: true,
                        heading:'Checking access rights ' + filename,
                        text: 'Success'}
                    : item)
        })
        // setMediaCollection(prevMedia => {
        //     return [...prevMedia, {
        //         'id': media_id,
        //         'filename': s3_object_key,
        //         'status': 'Deploying VQaaS',
        //         'createdAt': 'Just now',
        //         'account': props.account,
        //         'teams': {'items':[]},
        //         'persons': {'items':[]}
        //     }].sort(dynamicSort("-createdAt"))
        // })

    }


    // console.log(referenceMedia)
    return (
        <>
            <WhiteNavbar {...props}/>
            <MediaPageHeader title={"Media Center"} subTitle={"Overview and analysis"}/>
            <React.StrictMode>
                <div className="section">
                    <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
                        <View width={{base:'95%', xl:'95%'}}>
                            {/*Import files*/}
                            <View style={{paddingBottom:"2rem"}}>
                                <Heading level={"3"} style={{
                                    borderBottomWidth:"1px",
                                    borderBottomColor:"black",
                                    borderBottomStyle:"solid"}}>Import Media</Heading>
                                <Collection
                                    type="list"
                                    items={mediaPageImportAlarmList}
                                    direction="column"
                                    justifyContent="flex-start"
                                >
                                    {(alert, index) => (
                                        <Alert
                                            key={alert.key}
                                            variation={alert.variation}
                                            isDismissible={alert.isDismissible}
                                            heading={alert.heading}
                                            onDismiss={() => handleDismissImportAlert(alert.key)}
                                        >
                                            {alert.text}
                                        </Alert>
                                    )}
                                </Collection>
                                <br/>
                                <Flex justifyContent={"center"} direction={"row"} alignItems={"center"}>
                                    <View>
                                        <div className="fileinput text-center">
                                            <input
                                                type="file"
                                                style={{display:'none'}}
                                                onChange={handleChange}
                                                ref={refFileInput}/>
                                            <div>
                                                <Button
                                                    className="reference-card-button"
                                                    gap="0.2rem"
                                                    variation="primary"
                                                    isDisabled={isDisabled}
                                                    onClick={() => {
                                                        refFileInput.current.click();
                                                    }}
                                                >
                                                    <Icon
                                                        ariaLabel="Plus"
                                                        viewBox={{width: 24, height: 24}}
                                                        pathData="M20.484,20.485 C18.218,22.752 15.205,24.000 12.000,24.000 C8.795,24.000 5.782,22.752 3.516,20.485 C1.248,18.219 0.000,15.205 0.000,12.000 C0.000,8.795 1.248,5.782 3.516,3.516 C5.781,1.249 8.795,-0.000 12.000,-0.000 C15.205,-0.000 18.219,1.249 20.485,3.516 C22.752,5.782 24.000,8.795 24.000,12.000 C24.000,15.205 22.752,18.219 20.484,20.485 ZM19.070,4.930 C17.182,3.040 14.671,2.000 12.000,2.000 C9.329,2.000 6.818,3.040 4.930,4.930 C3.040,6.819 2.000,9.329 2.000,12.000 C2.000,14.671 3.040,17.182 4.930,19.071 C6.818,20.960 9.329,22.000 12.000,22.000 C14.671,22.000 17.182,20.960 19.070,19.071 C20.960,17.182 22.000,14.671 22.000,12.000 C22.000,9.329 20.960,6.819 19.070,4.930 ZM17.000,13.000 L13.000,13.000 L13.000,17.000 C13.000,17.552 12.552,18.000 12.000,18.000 C11.448,18.000 11.000,17.552 11.000,17.000 L11.000,13.000 L7.000,13.000 C6.448,13.000 6.000,12.552 6.000,12.000 C6.000,11.448 6.448,11.000 7.000,11.000 L11.000,11.000 L11.000,7.000 C11.000,6.448 11.448,6.000 12.000,6.000 C12.552,6.000 13.000,6.448 13.000,7.000 L13.000,11.000 L17.000,11.000 C17.552,11.000 18.000,11.448 18.000,12.000 C18.000,12.552 17.552,13.000 17.000,13.000 Z"
                                                    />
                                                    Import File
                                                </Button>
                                            </div>
                                        </div>
                                    </View>
                                    <View>
                                        <Button
                                            className="reference-card-button"
                                            gap="0.2rem"
                                            variation="primary"
                                            isDisabled={isDisabled}
                                            onClick={() => {
                                                setOpenS3Popup(true);
                                            }}
                                        >
                                            <Icon
                                                ariaLabel="Plus"
                                                viewBox={{width: 24, height: 24}}
                                                pathData="M20.484,20.485 C18.218,22.752 15.205,24.000 12.000,24.000 C8.795,24.000 5.782,22.752 3.516,20.485 C1.248,18.219 0.000,15.205 0.000,12.000 C0.000,8.795 1.248,5.782 3.516,3.516 C5.781,1.249 8.795,-0.000 12.000,-0.000 C15.205,-0.000 18.219,1.249 20.485,3.516 C22.752,5.782 24.000,8.795 24.000,12.000 C24.000,15.205 22.752,18.219 20.484,20.485 ZM19.070,4.930 C17.182,3.040 14.671,2.000 12.000,2.000 C9.329,2.000 6.818,3.040 4.930,4.930 C3.040,6.819 2.000,9.329 2.000,12.000 C2.000,14.671 3.040,17.182 4.930,19.071 C6.818,20.960 9.329,22.000 12.000,22.000 C14.671,22.000 17.182,20.960 19.070,19.071 C20.960,17.182 22.000,14.671 22.000,12.000 C22.000,9.329 20.960,6.819 19.070,4.930 ZM17.000,13.000 L13.000,13.000 L13.000,17.000 C13.000,17.552 12.552,18.000 12.000,18.000 C11.448,18.000 11.000,17.552 11.000,17.000 L11.000,13.000 L7.000,13.000 C6.448,13.000 6.000,12.552 6.000,12.000 C6.000,11.448 6.448,11.000 7.000,11.000 L11.000,11.000 L11.000,7.000 C11.000,6.448 11.448,6.000 12.000,6.000 C12.552,6.000 13.000,6.448 13.000,7.000 L13.000,11.000 L17.000,11.000 C17.552,11.000 18.000,11.448 18.000,12.000 C18.000,12.552 17.552,13.000 17.000,13.000 Z"
                                            />
                                            Import S3 Object
                                        </Button>
                                        <Popup
                                            modal
                                            closeOnDocumentClick
                                            open={openS3Popup}
                                            onClose={() => setOpenS3Popup(false)}
                                        >
                                            <S3Browser
                                                user={props.user}
                                                onImportFromS3={handleMediaImportFromS3}
                                                onClick={() => setOpenS3Popup(false)}
                                                account={props.account}
                                            />
                                        </Popup>
                                    </View>
                                </Flex>
                                <br/>
                                <Flex justifyContent={"center"} direction={"row"} alignItems={"center"}>
                                    <View
                                        id="dropzone_import"
                                        width={"75%"}
                                        onDragOver={(event) => allowDrop(event)}
                                        onDrop={(event) => drop_file(event)}
                                        textAlign={"center"}
                                    >
                                        <Text id={"dropzone_import_text"}>Drop file or S3 object here</Text>
                                    </View>
                                </Flex>
                            </View>

                            {/*My Media*/}
                            <View style={{paddingBottom:"2rem"}}>
                                <Heading level={"3"} style={{
                                    borderBottomWidth:"1px",
                                    borderBottomColor:"black",
                                    borderBottomStyle:"solid"}}>My Media</Heading>
                                <br/>
                                <Flex justifyContent={"center"}>
                                    <Tabs
                                        style={{display:"unset",borderStyle:"unset"}}
                                        justifyContent={"center"}
                                        currentIndex={tabIndexMedia}
                                        onChange={(i) => setTabIndexMedia(i)}
                                    >
                                        <TabItem title={"Recent"}><br/></TabItem>
                                        <TabItem title={"Shared with me"}><br/></TabItem>
                                        <TabItem title={"Shared with Team"}><br/></TabItem>
                                    </Tabs>
                                </Flex>
                                <Flex justifyContent={"flex-start"} direction={"column"} alignItems={"center"}>
                                    <Collection
                                        type="list"
                                        items={mediaCollection}
                                        itemsPerPage={8}
                                        isPaginated
                                        isSearchable
                                        searchFilter={mediaStartsWith}
                                        searchPlaceholder="Type to search..."
                                        direction="row"
                                        justifyContent="center"
                                        wrap="wrap"
                                    >
                                    {(mediaItem, index) => (
                                        <MediaCard
                                            key={mediaItem['id']}
                                            mediaItem={mediaItem}
                                            onRemoveMedia={()=>props.onRemoveMedia(mediaItem['id'], tabIndexMedia)}
                                            showButtons={true}
                                            tabIndex={tabIndexMedia}
                                            modalState={modalId === mediaItem['id']}
                                            onOpenModal={() => handleOpenModal(mediaItem['id'])}
                                            onCloseModal={() => handleCloseModal()}
                                            shareWith={shareWith}
                                            shareWithSelection={shareWithSelection}
                                            onCloseShareWith={(id) => handleCloseShareWith(id)}
                                            onClickShareWithSelection={(item) => handleClickShareWithSelection(item)}
                                            searchFieldValue={searchFieldValue}
                                            onSearchFieldChange={(e) => handleSearchFieldChange(e)}
                                            onSearchFieldSubmit={() => handleSearchFieldSubmit()}
                                            onSearchFieldClear={() => handleSearchFieldClear()}
                                            onShareButtonClick={() => handleShareButtonClick(mediaItem['id'], 'media')}
                                        />
                                    )}
                                    </Collection>
                                </Flex>
                            </View>

                            {/*Analyse*/}
                            <View style={{paddingBottom:"2rem"}}>
                                <Heading level={"3"} style={{
                                    borderBottomWidth:"1px",
                                    borderBottomColor:"black",
                                    borderBottomStyle:"solid"}}>Analyse</Heading>
                                <br/>
                                <Flex justifyContent="center">
                                    <View
                                        id="dropzone_analyse_reference"
                                        width={"536px"}
                                        height={"415px"}
                                        onDragOver={(event) => allowDrop(event)}
                                        onDrop={(event) => drop(event, 'reference')}
                                        textAlign={"center"}
                                    >
                                        <Flex justifyContent={"flex-start"} direction={"column"} alignItems={"center"}>
                                            <Text id={"dropzone_analyse_reference_text"}>Drop reference media here</Text>
                                            { (showReference && referenceMedia) ?
                                            <MediaCard
                                                key={referenceMedia['id']}
                                                mediaItem={referenceMedia}
                                                onRemoveMedia={()=>props.onRemoveMedia(referenceMedia['id'])}
                                                showButtons={false}
                                            /> : <></>}
                                        </Flex>
                                    </View>
                                    <View
                                        id="dropzone_analyse_encode"
                                        width={"536px"}
                                        height={"415px"}
                                        onDragOver={(event) => allowDrop(event)}
                                        onDrop={(event) => drop(event, 'encoded version')}
                                        textAlign={"center"}>
                                        <Flex justifyContent={"flex-start"} direction={"column"} alignItems={"center"}>
                                            <Text id={"dropzone_analyse_encode_text"}>Drop encoded media here</Text>
                                            { showEncodedVersion ?
                                                <MediaCard
                                                    key={encodedMedia['id']}
                                                    mediaItem={encodedMedia}
                                                    onRemoveMedia={()=>props.onRemoveMedia(encodedMedia['id'])}
                                                    showButtons={false}
                                                /> : <></>}
                                        </Flex>
                                    </View>
                                </Flex>
                                {/*<Flex justifyContent="center" style={{paddingTop: "6px"}}>*/}
                                {/*    <Flex direction={"column"} alignItems={"center"} gap={"5px"}>*/}
                                {/*        <View width={"30px"} height={"30px"}>*/}
                                {/*            <i className="arrow arrowdown" />*/}
                                {/*        </View>*/}
                                {/*        <ControlPanelCard/>*/}
                                {/*        <View width={"30px"} height={"30px"}>*/}
                                {/*            <i className="arrow arrowdown" />*/}
                                {/*        </View>*/}
                                {/*    </Flex>*/}
                                {/*</Flex>*/}
                                <br/>
                                <Flex justifyContent="center">
                                    <Button
                                        className="reference-card-button"
                                        isDisabled={!(showReference && showEncodedVersion)}
                                        gap="0.2rem"
                                        variation="primary"
                                        onClick={(e) => onStartEncodeAnalysis(e)}
                                    >
                                        <Icon
                                            ariaLabel="Plus"
                                            viewBox={{ width: 24, height: 24 }}
                                            pathData="M20.484,20.485 C18.218,22.752 15.205,24.000 12.000,24.000 C8.795,24.000 5.782,22.752 3.516,20.485 C1.248,18.219 0.000,15.205 0.000,12.000 C0.000,8.795 1.248,5.782 3.516,3.516 C5.781,1.249 8.795,-0.000 12.000,-0.000 C15.205,-0.000 18.219,1.249 20.485,3.516 C22.752,5.782 24.000,8.795 24.000,12.000 C24.000,15.205 22.752,18.219 20.484,20.485 ZM19.070,4.930 C17.182,3.040 14.671,2.000 12.000,2.000 C9.329,2.000 6.818,3.040 4.930,4.930 C3.040,6.819 2.000,9.329 2.000,12.000 C2.000,14.671 3.040,17.182 4.930,19.071 C6.818,20.960 9.329,22.000 12.000,22.000 C14.671,22.000 17.182,20.960 19.070,19.071 C20.960,17.182 22.000,14.671 22.000,12.000 C22.000,9.329 20.960,6.819 19.070,4.930 ZM17.000,13.000 L13.000,13.000 L13.000,17.000 C13.000,17.552 12.552,18.000 12.000,18.000 C11.448,18.000 11.000,17.552 11.000,17.000 L11.000,13.000 L7.000,13.000 C6.448,13.000 6.000,12.552 6.000,12.000 C6.000,11.448 6.448,11.000 7.000,11.000 L11.000,11.000 L11.000,7.000 C11.000,6.448 11.448,6.000 12.000,6.000 C12.552,6.000 13.000,6.448 13.000,7.000 L13.000,11.000 L17.000,11.000 C17.552,11.000 18.000,11.448 18.000,12.000 C18.000,12.552 17.552,13.000 17.000,13.000 Z"
                                        />
                                        Start analysis
                                    </Button>
                                </Flex>
                            </View>

                            {/*My Analyses*/}
                            <View style={{paddingBottom:"2rem"}}>
                                <Heading level={"3"} style={{
                                    borderBottomWidth:"1px",
                                    borderBottomColor:"black",
                                    borderBottomStyle:"solid"}}>My Analyses</Heading>
                                <br/>
                                <Flex justifyContent={"center"}>
                                    <Tabs
                                        style={{display:"unset",borderStyle:"unset"}}
                                        justifyContent={"center"}
                                        currentIndex={tabIndex}
                                        onChange={(i) => setTabIndex(i)}
                                    >
                                        <TabItem title={"Recent"}><br/></TabItem>
                                        <TabItem title={"Shared with me"}><br/></TabItem>
                                        <TabItem title={"Shared with Team"}><br/></TabItem>
                                    </Tabs>
                                </Flex>
                                <Collection
                                    type="list"
                                    items={encodeAnalysisCollection}
                                    itemsPerPage={8}
                                    isPaginated
                                    isSearchable
                                    searchFilter={encodeAnalysisStartsWith}
                                    searchPlaceholder="Type to search..."
                                    direction="row"
                                    justifyContent="center"
                                    wrap="wrap"
                                >
                                    {(encodeAnalysis, index) => (
                                        <EncodeAnalysisCard
                                            key={encodeAnalysis['id']}
                                            encodeAnalysis={encodeAnalysis}
                                            onRemoveEncodeAnalysis={()=>props.onRemoveEncodeAnalysis(encodeAnalysis['id'], tabIndex)}
                                            showButtons={true}
                                            tabIndex={tabIndex}
                                            modalState={modalId === encodeAnalysis['id']}
                                            onOpenModal={() => handleOpenModal(encodeAnalysis['id'])}
                                            onCloseModal={() => handleCloseModal()}
                                            shareWith={shareWith}
                                            shareWithSelection={shareWithSelection}
                                            onCloseShareWith={(id) => handleCloseShareWith(id)}
                                            onClickShareWithSelection={(item) => handleClickShareWithSelection(item)}
                                            searchFieldValue={searchFieldValue}
                                            onSearchFieldChange={(e) => handleSearchFieldChange(e)}
                                            onSearchFieldSubmit={() => handleSearchFieldSubmit()}
                                            onSearchFieldClear={() => handleSearchFieldClear()}
                                            onShareButtonClick={() => handleShareButtonClick(encodeAnalysis['id'], 'encodeAnalysis')}
                                        />
                                    )}
                                </Collection>
                            </View>

                            {/*Compare*/}
                            <View style={{paddingBottom:"2rem"}}>
                                <Heading level={"3"} style={{
                                    borderBottomWidth:"1px",
                                    borderBottomColor:"black",
                                    borderBottomStyle:"solid"}}>Compare</Heading>
                                <br/>
                                <Flex justifyContent={"center"} direction={"column"} alignItems={"center"}>
                                    <View
                                        id={"dropzone_compare"}
                                        width={"400px"}
                                        height={"100px"}
                                        onDragOver={(event) => allowDrop(event)}
                                        onDrop={(event) => drop_analysis(event)}
                                        textAlign={"center"}
                                    >
                                        <Text id={"dropzone_compare_text"}>Drop analyses here to add to comparison</Text>
                                    </View>
                                    <br/>
                                    <Flex justifyContent={"center"} direction={"column"} alignItems={"center"} alignContent={"center"}>
                                        <Collection
                                            type="list"
                                            items={compareCollection}
                                            itemsPerPage={8}
                                            direction="row"
                                            justifyContent="center"
                                            wrap="wrap"
                                        >
                                            {(data, index) => (
                                                <EncodeAnalysisCard
                                                    key={data}
                                                    encodeAnalysis={findEncodeAnalysis(data)}
                                                    onRemoveEncodeAnalysis={()=>props.onRemoveEncodeAnalysis(data)}
                                                    showButtons={false}/>
                                            )}
                                        </Collection>
                                    </Flex>
                                </Flex>
                                <br/>
                                <Flex justifyContent="center" gap={"10rem"} direction={"row"}>
                                    <Button
                                        className="reference-card-button"
                                        isDisabled={compareCollection.length === 0}
                                        gap="0.2rem"
                                        variation="primary"
                                        onClick={(e) => setCompareCollection([])}
                                    >
                                        <Icon
                                            ariaLabel="Trash"
                                            viewBox={{ width: 24, height: 24 }}
                                            pathData="M19.000,6.000 L17.910,6.000 L16.105,22.118 C15.986,23.173 15.062,24.000 14.000,24.000 L6.000,24.000 C4.937,24.000 4.014,23.172 3.897,22.116 L2.120,6.000 L1.000,6.000 C0.447,6.000 0.000,5.552 0.000,5.000 C0.000,4.448 0.447,4.000 1.000,4.000 L6.000,4.000 L6.000,3.000 C6.000,1.346 7.346,-0.000 9.000,-0.000 L11.000,-0.000 C12.654,-0.000 14.000,1.346 14.000,3.000 L14.000,4.000 L19.000,4.000 C19.553,4.000 20.000,4.448 20.000,5.000 C20.000,5.552 19.553,6.000 19.000,6.000 ZM12.000,3.000 C12.000,2.449 11.552,2.000 11.000,2.000 L9.000,2.000 C8.448,2.000 8.000,2.449 8.000,3.000 L8.000,4.000 L12.000,4.000 L12.000,3.000 ZM4.132,6.000 L5.885,21.896 C5.889,21.935 5.960,22.000 6.000,22.000 L14.000,22.000 C14.040,22.000 14.113,21.935 14.117,21.894 L15.897,6.000 L4.132,6.000 Z"
                                        />
                                        Clear selection
                                    </Button>
                                    {/*<Link as={ReactRouterLink} to={"/compare/" + compareCollection.join('/')}>*/}
                                        <Button
                                            className="reference-card-button"
                                            isDisabled={compareCollection.length < 2}
                                            gap="0.2rem"
                                            variation="primary"
                                            onClick={(ev) => handleCompareClick(ev)}
                                        >
                                            <Icon
                                                ariaLabel="Plus"
                                                viewBox={{ width: 24, height: 24 }}
                                                pathData="M20.484,20.485 C18.218,22.752 15.205,24.000 12.000,24.000 C8.795,24.000 5.782,22.752 3.516,20.485 C1.248,18.219 0.000,15.205 0.000,12.000 C0.000,8.795 1.248,5.782 3.516,3.516 C5.781,1.249 8.795,-0.000 12.000,-0.000 C15.205,-0.000 18.219,1.249 20.485,3.516 C22.752,5.782 24.000,8.795 24.000,12.000 C24.000,15.205 22.752,18.219 20.484,20.485 ZM19.070,4.930 C17.182,3.040 14.671,2.000 12.000,2.000 C9.329,2.000 6.818,3.040 4.930,4.930 C3.040,6.819 2.000,9.329 2.000,12.000 C2.000,14.671 3.040,17.182 4.930,19.071 C6.818,20.960 9.329,22.000 12.000,22.000 C14.671,22.000 17.182,20.960 19.070,19.071 C20.960,17.182 22.000,14.671 22.000,12.000 C22.000,9.329 20.960,6.819 19.070,4.930 ZM17.000,13.000 L13.000,13.000 L13.000,17.000 C13.000,17.552 12.552,18.000 12.000,18.000 C11.448,18.000 11.000,17.552 11.000,17.000 L11.000,13.000 L7.000,13.000 C6.448,13.000 6.000,12.552 6.000,12.000 C6.000,11.448 6.448,11.000 7.000,11.000 L11.000,11.000 L11.000,7.000 C11.000,6.448 11.448,6.000 12.000,6.000 C12.552,6.000 13.000,6.448 13.000,7.000 L13.000,11.000 L17.000,11.000 C17.552,11.000 18.000,11.448 18.000,12.000 C18.000,12.552 17.552,13.000 17.000,13.000 Z"
                                            />
                                            Compare
                                        </Button>
                                    {/*</Link>*/}
                                </Flex>

                            </View>

                            {/*My Comparisons*/}
                            <View style={{paddingBottom:"2rem"}}>
                                <Heading level={"3"} style={{
                                    borderBottomWidth:"1px",
                                    borderBottomColor:"black",
                                    borderBottomStyle:"solid"}}>My Comparisons</Heading>
                                <br/>
                                <Flex justifyContent={"center"}>
                                    <Tabs
                                        style={{display:"unset",borderStyle:"unset"}}
                                        justifyContent={"center"}
                                        currentIndex={tabIndexComparison}
                                        onChange={(i) => setTabIndexComparison(i)}
                                    >
                                        <TabItem title={"Recent"}><br/></TabItem>
                                        <TabItem title={"Shared with me"}><br/></TabItem>
                                        <TabItem title={"Shared with Team"}><br/></TabItem>
                                    </Tabs>
                                </Flex>
                                <Collection
                                    type="list"
                                    items={comparisonCollection}
                                    itemsPerPage={8}
                                    isPaginated
                                    isSearchable
                                    searchFilter={comparisonStartsWith}
                                    searchPlaceholder="Type to search..."
                                    direction="row"
                                    justifyContent="center"
                                    wrap="wrap"
                                >
                                    {(comparison, index) => (
                                        <ComparisonCard
                                            key={comparison['id']}
                                            comparison={comparison}
                                            onRemoveComparison={()=>props.onRemoveComparison(comparison['id'], tabIndexComparison)}
                                            showButtons={true}
                                            tabIndex={tabIndexComparison}
                                            modalState={modalId === comparison['id']}
                                            onOpenModal={() => handleOpenModal(comparison['id'])}
                                            onCloseModal={() => handleCloseModal()}
                                            shareWith={shareWith}
                                            shareWithSelection={shareWithSelection}
                                            onCloseShareWith={(id) => handleCloseShareWith(id)}
                                            onClickShareWithSelection={(item) => handleClickShareWithSelection(item)}
                                            searchFieldValue={searchFieldValue}
                                            onSearchFieldChange={(e) => handleSearchFieldChange(e)}
                                            onSearchFieldSubmit={() => handleSearchFieldSubmit()}
                                            onSearchFieldClear={() => handleSearchFieldClear()}
                                            onShareButtonClick={() => handleShareButtonClick(comparison['id'], 'Comparison')}

                                        />

                                    )}
                                </Collection>
                            </View>
                        </View>
                    </Flex>
                </div>
            </React.StrictMode>
            <FooterBlack {...props}/>
        </>
    );
}

export default MediaPage;

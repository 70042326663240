import React from "react";
import Popup from "reactjs-popup"
import {API, graphqlOperation, Storage} from "aws-amplify";
import {
    Badge,
    Button,
    Card,
    Divider,
    Flex,
    Heading,
    Icon,
    Image as AmplifyImage,
    Link,
    Loader,
    Menu,
    MenuButton,
    MenuItem,
    Text,
    View
} from '@aws-amplify/ui-react';
import {Link as ReactRouterLink} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import SharePopup from "./SharePopup";

const onUpdatePersonsMedia = /* GraphQL */ `
  subscription OnUpdateMedia($id: ID!, $owner: String!) {
    onUpdateMedia(id: $id, owner: $owner) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      build_number
      referenceMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodedMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      createdAt
      cross_account_s3_id
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      id
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      owner
      persons {
        items {
          id
          accountID
          account {
            id
            email
          }
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      fps
      bitrate_list_per_sec
      width
      height
      extension
      es_filename
      updatedAt
      user_identity_id
    }
  }
`;

const getPersonsMedia = /* GraphQL */ `
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      build_number
      referenceMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodedMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      createdAt
      cross_account_s3_id
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      id
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      owner
      persons {
        items {
          id
          accountID
          account {
            id
            email
          }
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      fps
      bitrate_list_per_sec
      width
      height
      extension
      es_filename
      updatedAt
      user_identity_id
    }
  }
`;

function SynamediaStrip() {
    return <Flex direction="column" gap={0}>
        <View
            backgroundColor={"#3380FF"}
            padding={"5px"}
            height={"12.5%"}
        />
        <View
            backgroundColor={"#FFB400"}
            padding={"5px"}
            height={"12.5%"}
        />
        <View
            backgroundColor={"#3380FF"}
            padding={"5px"}
            height={"75%"}
        />
    </Flex>;
}

function EASharing(props) {
    const {showSharing, account, persons, teams} = props || {}

    const sharedItem = (teams.items || []).map(item =>
        <Tooltip key={item.team.id} title={item.team.name} placement="bottom">
            <div data-initials={item.team.name[0]}/>
        </Tooltip>).concat((persons.items || []).map(item => <Tooltip key={item.account.id} title={item.account.email}
                                                                      placement="bottom">
        <div
            data-initials={((item.account.first_name ? item.account.first_name[0] : item.account.email[0]) + (item.account.last_name ? item.account.last_name[0] : item.account.email[1])).toUpperCase()}/>
    </Tooltip>))

    const sharedBy = <Tooltip title={account.email} placement={"bottom"}>
        <div data-initials={((account.first_name ? account.first_name[0] : account.email[0]) + (account.last_name ? account.last_name[0] : account.email[1])).toUpperCase()}/>
    </Tooltip>

    let sharingComponent = <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"center"} height={"40px"}>
        <Heading level={6}>Shared {props.tabIndex === "0" ? "with" : "by"}</Heading>
        <Flex direction={"row"} gap={"0"}>
            {props.tabIndex === "0" ? sharedItem : sharedBy}
        </Flex>
    </Flex>;

    sharingComponent = showSharing ? sharingComponent : <></>
    return sharingComponent

}

function MediaThumbnail(props) {
    const {mediaItemId, userIdentityId, progressCreateHls} = props
    const [thumbnailURL, setThumbnailURL] = React.useState()
    const img = new Image();
    img.src =  `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAABaCAMAAAAIGK1gAAAACXBIWXMAAA7zAAAO8wEcU5k6AAAAEXRFWHRUaXRsZQBQREYgQ3JlYXRvckFevCgAAAATdEVYdEF1dGhvcgBQREYgVG9vbHMgQUcbz3cwAAAALXpUWHREZXNjcmlwdGlvbgAACJnLKCkpsNLXLy8v1ytISdMtyc/PKdZLzs8FAG6fCPGXryy4AAAA51BMVEUAAAABAQENN18NOmMRToURUIYST4UST4YST4gTToYTUIcWY6cXZKgbiOMciOMcieQcieYdiOYdieMdieQeiuQfHx8fiOMfiOQiWIc4l+c4l+lBaIdNgK9qsO5rse9tbW1vsuZvsud2dnR2dnZ3dnR4eHiKioiSkpKUiACViQCWiQCq0uy5ubm6urq+xfO/xfW/xvTBxfTp6/rr6+ns7/ju7u7v7+/v7/fv8PXv8f3w7u/x8fH29vb5+v77+/3999P9+NL+7B7+/v7+////6gD/6wD/6wH/7AD/7R//99P//v////3///+NN1xIAAABaElEQVRo3u3aSVODMAAFYFBbqtS2NGq1iktQ64am1qp1QyCuzf//PcIwdXC8WQ9v9L0bOX3zkgCZiTXjONVKdbW3h5ktyxF5upcWZsIc2EAHiqZYwwYuITc4J0QLcYrtCXA+3yMt8DXYhmzQLjXY6Q6RG1zka2ZaoEAG1tA/dQf6RWsd4wIPM55+RgfqV3RgTCCBBBL4b4FHGe9Jxzds8KfA9Z3tLMfQ/4NeU/jQx04BDax4+Q+rj3toqqE3uJABPWRgcWgikEACCSSQQAIJJJBAAgkkkEACCSSQQAIJBIvNBn8POGSD0wE3ce8PFndYffRLtv7dMmY+16ABDYEEEkjgnweOjUlKj++QDabm4TsNa4pHp0r1iz5BgX0lVXkgAQKmOfBMKWXkbSplL5DGDAIpx1ANRlGUAaXczXQyMIN9HGCaz+bo8S1SKfAmub++ujj/OpRMgJ1GW6wAv6hn627ddTdCzJyEH65hkn9x3ZonAAAAAElFTkSuQmCC`

    React.useEffect(() => {
        let mounted = true
        async function getAWSUrl() {
            try {
                const result = await Storage.get(
                    mediaItemId + "/media_01.jpg",
                    {
                        level: 'protected',
                        identityId: userIdentityId,
                        expires: 86400
                    });
                if (mounted) {
                    setThumbnailURL(result);
                }
            } catch (err) {
                console.log('URL not found')
            }
        }

        if (progressCreateHls === 100) {
            getAWSUrl().then()
        }
        return function cleanup() {
            mounted = false;
        };

    }, [mediaItemId, userIdentityId, progressCreateHls])

    function drag(ev) {
        ev.dataTransfer.setDragImage(img, 20, 20);
        ev.dataTransfer.setData("text", ev.target.id);
    }

    return <Flex>
        <View height="243px" minWidth="432px">
            {thumbnailURL ?
                <AmplifyImage
                    draggable="true"
                    onDragStart={(event) => drag(event)}
                    key={mediaItemId}
                    id={mediaItemId}
                    style={{cursor: 'move'}}
                    height="100%"
                    objectFit="contain"
                    src={thumbnailURL}
                    alt="Not available"
                />
                :
                <Flex justifyContent="center" height="143px" padding="50px">
                    <Loader
                        height="100%"
                        filledColor="#FF3333"
                        emptyColor="var(--amplify-colors-brand-secondary-100)"
                    />
                </Flex>
            }
        </View>
    </Flex>;
}

function MediaFilename(props) {
    const {filename} = props
    let displayFilename
    let splitDisplayFilename = filename.split('/')
    if (splitDisplayFilename.length === 1) {
        displayFilename = splitDisplayFilename[splitDisplayFilename.length - 1]
    } else {
        displayFilename = '.../' + splitDisplayFilename[splitDisplayFilename.length - 1]
    }

    return <Flex>
        <Tooltip title={filename} placement={'top'}>
            <Heading level={6} padding={"3px"}>{displayFilename}</Heading>
        </Tooltip>
    </Flex>;
}

function MediaData(props) {
    const {status, specWidth, specHeight, specFrameRate, createdAt} = props
    return <Flex width={"200px"} direction={"column"} gap={0} justifyContent={"flex-start"}
                 alignItems={"flex-start"}>
        <Text
            padding={"3px"}>{(status === "Success") ? specWidth + "x" + specHeight + "@" + specFrameRate + "fps" : status}
        </Text>
        <Text>{createdAt}</Text>
    </Flex>;
}

function MediaButtons(props) {
    // console.log(typeof props.tabIndex)
    let showBadges = !props.showButtons
    let showDeleteButton = props.showButtons
    let showShareButton = props.showButtons
    if (props.tabIndex === '2') showDeleteButton = false
    if (props.tabIndex === '1' || props.tabIndex === '2') showShareButton = false
    // console.log(showShareButton)
    return <Flex width={"200px"} direction={"row"} justifyContent={"flex-end"}>
        {showBadges ?
            <div>
                <Badge variation={"info"}>{props.specCodec}</Badge>
                <Badge variation={"info"}>{props.scanMode}</Badge>
            </div> : <></>}
        {showShareButton ?
            <div>
                <Tooltip
                    title="Share"
                    placement="top"
                >
                    <Button className="reference-card-button" size="small"
                            onClick={props.onClick}>
                        <Icon
                            ariaLabel="Share"
                            viewBox={{width: 24, height: 24}}
                            pathData="M20.000,8.000 C18.788,8.000 17.714,7.447 16.980,6.593 L7.900,11.134 C7.962,11.413 8.000,11.701 8.000,11.998 C8.000,12.269 7.972,12.533 7.920,12.788 L16.984,17.402 C17.718,16.551 18.791,16.000 20.000,16.000 C22.206,16.000 24.000,17.794 24.000,20.000 C24.000,22.206 22.206,24.000 20.000,24.000 C17.794,24.000 16.000,22.206 16.000,20.000 C16.000,19.722 16.029,19.450 16.084,19.188 L7.031,14.580 C6.297,15.441 5.218,16.000 4.000,16.000 C1.794,16.000 0.000,14.205 0.000,11.998 C0.000,9.793 1.794,7.998 4.000,7.998 C5.190,7.998 6.248,8.530 6.981,9.357 L16.082,4.806 C16.028,4.545 16.000,4.276 16.000,4.000 C16.000,1.794 17.794,0.000 20.000,0.000 C22.206,0.000 24.000,1.794 24.000,4.000 C24.000,6.206 22.206,8.000 20.000,8.000 ZM20.000,22.000 C21.103,22.000 22.000,21.103 22.000,20.000 C22.000,18.897 21.103,18.000 20.000,18.000 C18.897,18.000 18.000,18.897 18.000,20.000 C18.000,21.103 18.897,22.000 20.000,22.000 ZM4.000,9.998 C2.897,9.998 2.000,10.895 2.000,11.998 C2.000,13.102 2.897,14.000 4.000,14.000 C5.103,14.000 6.000,13.102 6.000,11.998 C6.000,10.895 5.103,9.998 4.000,9.998 ZM20.000,2.000 C18.897,2.000 18.000,2.897 18.000,4.000 C18.000,5.103 18.897,6.000 20.000,6.000 C21.103,6.000 22.000,5.103 22.000,4.000 C22.000,2.897 21.103,2.000 20.000,2.000 Z"
                        />
                    </Button>
                </Tooltip>
                <Popup
                    modal
                    closeOnDocumentClick
                    open={props.open}
                    onClose={props.onClose}
                >
                    <SharePopup
                        onClick={props.onClick1}
                        shareWith={props.shareWith}
                        onCloseShareWith={props.onCloseShareWith}
                        searchFieldValue={props.searchFieldValue}
                        onSearchFieldChange={props.onSearchFieldChange}
                        onSearchFieldSubmit={props.onSearchFieldSubmit}
                        onSearchFieldClear={props.onSearchFieldClear}
                        shareWithSelection={props.shareWithSelection}
                        onClickShareWithSelection={props.onClickShareWithSelection}
                        onShareButtonClick={props.onShareButtonClick}
                        persons={props.persons}
                        teams={props.teams}
                    />
                </Popup>
            </div>
            : <></> }
        {props.showButtons ?
            <Link as={ReactRouterLink} to={"/media/" + props.mediaItem.id}>
                <Tooltip
                    title="Details"
                    placement="top"
                >
                    <Button className="reference-card-button" size="small">
                        <Icon
                            ariaLabel="Info"
                            viewBox={{width: 24, height: 24}}
                            pathData="M20.321,20.485 C18.069,22.752 15.074,24.000 11.887,24.000 C8.700,24.000 5.705,22.752 3.453,20.485 C-1.197,15.806 -1.197,8.193 3.453,3.514 C5.705,1.248 8.700,-0.000 11.887,-0.000 C15.074,-0.000 18.069,1.248 20.321,3.514 C22.574,5.781 23.815,8.794 23.815,12.000 C23.815,15.205 22.574,18.218 20.321,20.485 ZM18.916,4.929 C17.038,3.040 14.542,2.000 11.887,2.000 C9.232,2.000 6.736,3.040 4.858,4.929 C0.984,8.827 0.984,15.172 4.858,19.071 C6.736,20.960 9.232,22.000 11.887,22.000 C14.542,22.000 17.038,20.960 18.916,19.071 C20.793,17.182 21.828,14.671 21.828,12.000 C21.828,9.329 20.793,6.818 18.916,4.929 ZM13.876,19.000 L9.900,19.000 C9.351,19.000 8.906,18.552 8.906,18.000 C8.906,17.447 9.351,17.000 9.900,17.000 L10.894,17.000 L10.894,13.000 L9.900,13.000 C9.351,13.000 8.906,12.552 8.906,12.000 C8.906,11.448 9.351,11.000 9.900,11.000 L11.888,11.000 C12.437,11.000 12.882,11.448 12.882,12.000 L12.882,17.000 L13.876,17.000 C14.425,17.000 14.870,17.447 14.870,18.000 C14.870,18.552 14.425,19.000 13.876,19.000 ZM11.888,9.000 C10.792,9.000 9.900,8.102 9.900,6.998 C9.900,5.896 10.792,5.000 11.888,5.000 C12.984,5.000 13.876,5.896 13.876,6.998 C13.876,8.102 12.984,9.000 11.888,9.000 Z"
                        />
                    </Button>
                </Tooltip>
            </Link>
            : <></>}
        {showDeleteButton ?
                        <Button onClick={props.onClick2} className="reference-card-button" size="small">
                            <Icon
                                ariaLabel="Trash"
                                viewBox={{width: 24, height: 24}}
                                pathData="M19.000,6.000 L17.910,6.000 L16.105,22.118 C15.986,23.173 15.062,24.000 14.000,24.000 L6.000,24.000 C4.937,24.000 4.014,23.172 3.897,22.116 L2.120,6.000 L1.000,6.000 C0.447,6.000 0.000,5.552 0.000,5.000 C0.000,4.448 0.447,4.000 1.000,4.000 L6.000,4.000 L6.000,3.000 C6.000,1.346 7.346,-0.000 9.000,-0.000 L11.000,-0.000 C12.654,-0.000 14.000,1.346 14.000,3.000 L14.000,4.000 L19.000,4.000 C19.553,4.000 20.000,4.448 20.000,5.000 C20.000,5.552 19.553,6.000 19.000,6.000 ZM12.000,3.000 C12.000,2.449 11.552,2.000 11.000,2.000 L9.000,2.000 C8.448,2.000 8.000,2.449 8.000,3.000 L8.000,4.000 L12.000,4.000 L12.000,3.000 ZM4.132,6.000 L5.885,21.896 C5.889,21.935 5.960,22.000 6.000,22.000 L14.000,22.000 C14.040,22.000 14.113,21.935 14.117,21.894 L15.897,6.000 L4.132,6.000 Z"
                            />
                        </Button> : <></>}
    </Flex>;
}

function MediaCard(props) {
    const {mediaItem, modalState} = props
    const {account, teams} = mediaItem || {}
    const [persons, setPersons] = React.useState(mediaItem.persons)
    const [mediaItemId, ] = React.useState(mediaItem.id)
    const [userIdentityId, ] = React.useState(mediaItem.user_identity_id)
    const [filename, ] = React.useState(mediaItem.filename)
    const [progressCreateHls, setProgressCreateHls] = React.useState(mediaItem.progress_create_hls)
    const [status, setStatus] = React.useState(mediaItem.status)
    const [specCodec, setSpecCodec] = React.useState(mediaItem.spec_codec)
    const [scanMode, setScanMode] = React.useState(mediaItem.scan_mode)
    const [specWidth, setSpecWidth] = React.useState(mediaItem.spec_width)
    const [specHeight, setSpecHeight] = React.useState(mediaItem.spec_height)
    const [specFrameRate, setSpecFrameRate] = React.useState(mediaItem.spec_frame_rate)
    const [createdAt, setCreatedAt] = React.useState(mediaItem.createdAt)

    // Subscribe to update Media mutation
    React.useEffect(() => {
        let subscription
        let mounted = true

        async function setupSubscription() {
            subscription = API.graphql(
                graphqlOperation(
                    onUpdatePersonsMedia,
                    {id: mediaItemId, owner: account.id}
                )).subscribe({
                next: (data) => {
                    if (mounted) {
                        setStatus(data['value']['data']['onUpdateMedia']['status'])
                        setProgressCreateHls(data['value']['data']['onUpdateMedia']['progress_create_hls'])
                        setSpecCodec(data['value']['data']['onUpdateMedia']['spec_codec'])
                        setScanMode(data['value']['data']['onUpdateMedia']['scan_mode'])
                        setSpecWidth(data['value']['data']['onUpdateMedia']['spec_width'])
                        setSpecHeight(data['value']['data']['onUpdateMedia']['spec_height'])
                        setSpecFrameRate(data['value']['data']['onUpdateMedia']['spec_frame_rate'])
                        setCreatedAt(data['value']['data']['onUpdateMedia']['createdAt'])
                        setPersons(data['value']['data']['onUpdateMedia']['persons'])
                    }
                },
                error: error => {
                    console.warn(error);
                }
            })
        }
        async function getMyMedia(){
            let response = await API.graphql(graphqlOperation(getPersonsMedia, {id: mediaItemId, owner: account.id}))
            return (response['data']['getMedia'])
        }

        if (account ) {
            getMyMedia().then(data => {
                if (mounted && data) {
                    setStatus(data['status'])
                    setProgressCreateHls(data['progress_create_hls'])
                    setSpecCodec(data['spec_codec'])
                    setScanMode(data['scan_mode'])
                    setSpecWidth(data['spec_width'])
                    setSpecHeight(data['spec_height'])
                    setSpecFrameRate(data['spec_frame_rate'])
                    setCreatedAt(data['createdAt'])
                    setPersons(data['persons'])

                }})
            setupSubscription().then()
            return () => {
                mounted = false
                if (subscription) {
                    subscription.unsubscribe()
                }
            }
        }
    }, [account, mediaItemId, status ])

    function handleUpdatePersons() {
        setTimeout(() => { API.graphql(graphqlOperation(getPersonsMedia, {id: mediaItemId, owner: account.id})).then(response => setPersons(response['data']['getMedia']['persons']))}, 2000)
        setTimeout(() => { API.graphql(graphqlOperation(getPersonsMedia, {id: mediaItemId, owner: account.id})).then(response => setPersons(response['data']['getMedia']['persons']))}, 4000)
    }

    return (
        <Card
            key={mediaItem.id}
            id={mediaItem.id}
            padding="0rem"
            variation="elevated"
        >
            <Flex direction="row" justifyContent="space-between" gap="5px">
                <SynamediaStrip/>
                <Flex direction="column" gap={"3px"} alignItems={"flex-start"}>
                    <MediaThumbnail
                        mediaItemId={mediaItemId}
                        progressCreateHls={progressCreateHls}
                        userIdentityId={userIdentityId}
                    />
                    <MediaFilename filename={filename}/>
                    <Flex justifyContent={"space-between"} alignItems={"center"} direction={"row"} minWidth="400px">
                        <MediaData
                            status={status}
                            specWidth={specWidth}
                            specHeight={specHeight}
                            specFrameRate={specFrameRate}
                            createdAt={createdAt}
                        />
                        <MediaButtons
                            tabIndex={props.tabIndex}
                            showButtons={props.showButtons}
                            onClick={() => props.onOpenModal(mediaItem.id)}
                            open={modalState}
                            onClose={() => {
                                props.onCloseModal()
                            }}
                            onClick1={() => {
                                props.onCloseModal()
                            }}
                            shareWith={props.shareWith}
                            onCloseShareWith={props.onCloseShareWith}
                            searchFieldValue={props.searchFieldValue}
                            onSearchFieldChange={props.onSearchFieldChange}
                            onSearchFieldSubmit={props.onSearchFieldSubmit}
                            onSearchFieldClear={props.onSearchFieldClear}
                            shareWithSelection={props.shareWithSelection}
                            onClickShareWithSelection={props.onClickShareWithSelection}
                            onShareButtonClick={() => {
                                props.onShareButtonClick();
                                props.onCloseModal()
                                handleUpdatePersons()
                            }}
                            persons={persons}
                            teams={teams}
                            mediaItem={mediaItem}
                            specCodec={specCodec}
                            scanMode={scanMode}
                            onClick2={props.onRemoveMedia}/>
                    </Flex>
                    <Divider/>
                    <EASharing
                        showSharing={props.showButtons}
                        tabIndex={props.tabIndex}
                        persons={persons}
                        teams={teams}
                        account={account}/>
                </Flex>
            </Flex>
        </Card>
    )
}

export default MediaCard
import React from "react";
import {API, graphqlOperation, Storage} from "aws-amplify";
import {Card, Flex, Heading, Image as AmplifyImage, Loader, View} from '@aws-amplify/ui-react';
import {FcHighPriority} from 'react-icons/fc'
import {VscBellDot} from 'react-icons/vsc'
import {IconContext} from "react-icons";

import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import HighchartsReact from 'highcharts-react-official';
import {onCreateVqm} from "./ViewerDigitalTwinServiceCard";

highchartsMore(Highcharts);
solidGauge (Highcharts);

const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      name
      owner
      rootID
      root {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      unAcknowledged
      createdAt
      updatedAt
    }
  }
`;
const onUpdateChannel = /* GraphQL */ `
  subscription OnUpdateChannel($id: ID!) {
    onUpdateChannel(id: $id) {
      id
      name
      owner
      inAlarm
      unAcknowledged
      createdAt
      updatedAt
    }
  }
`;

function ChannelThumbnail(props) {
    const {channelId, channelThumbnail} = props

    return <View height="189px" width={"335px"} backgroundColor={"black"}>
            {channelThumbnail ?
                <AmplifyImage
                    key={channelId}
                    id={channelId}
                    height="189px"
                    width="335px"
                    objectFit="cover"
                    src={channelThumbnail}
                    alt="Not available"/>
                :
                <Flex justifyContent="center" height="143px" padding="50px">
                    <Loader
                        height="100%"
                        filledColor="#FF3333"
                        emptyColor="var(--amplify-colors-brand-secondary-100)"
                    />
                </Flex>
            }
        </View>;
}

function ChannelCard(props) {
    const {channelId, channelName} = props
    // console.log(channel, channelId, channelName)
    const [gaugeOptions, setGaugeOptions] = React.useState({
        chart: {
            type: 'solidgauge',
            height: '75px'
        },

        title: null,

        pane: {
            center: ['50%', '40%'],
            size: '40px',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },

        exporting: {
            enabled: false
        },

        tooltip: {
            enabled: false
        },

        yAxis: {
            min: 0,
            max: 100,
            stops: [
                [0.25, '#FF3333'], // red
                [0.55, '#FF3333'], // red
                [0.75, '#FFC41C'], // yellow
                [0.9, '#37F713'], // green
                [1, '#37F713'] // green
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
                enabled: false,
            },
            labels: {
                enabled: false,
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Speed',
            data: [80],
            dataLabels: {
                format:
                    '<div style="text-align:center">' +
                    '<span style="font-size:20px">{y}</span><br/>' +
                    '</div>'
            },
            tooltip: {
                valueSuffix: ' km/h'
            }
        }],
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 10,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        }
    })
    const [thumbnailUpdatedAt, setThumbnailUpdatedAt] = React.useState()
    const[thumbnailUrl, setThumbnailUrl] = React.useState()
    const bitrateChartRef = React.useRef()
    const [monitorPoints, ] = React.useState([
        {id: '03c2e699-1cee-4540-b505-5f4dcbad94fe'},
        {id: 'e178ce98-5f41-4cd8-a7a2-252dfb0ba8cd'},
        {id: '9a4682cb-e2b4-4b8c-980b-f9273d1051cd'},
        {id: 'd20a79c4-a90b-42cc-8096-fff23e9cbdfd'},
        {id: '68eff4bf-1137-43a7-afd0-1e2040c446e8'},
        {id: '46718190-d11a-4688-98ab-7150e3066fca'},
        {id: '5d03af6f-e25a-4abc-bc9b-7570c712cddc'},
        {id: 'a7fda52a-2722-49ac-8420-a109972c8db3'},
        {id: 'f8284e92-5156-426d-b1b0-eeb25c503477'},
        {id: 'a27ac715-0b7c-460f-a235-549a43e37991'},
        {id: '3daee515-a7e2-4f62-a47d-5e7ff3ecb551'},
    ])
    const [monitorPointsResult, setMonitorPointsResult] = React.useState([
        {id: '03c2e699-1cee-4540-b505-5f4dcbad94fe', name: 'Source', format: '1280x720p50', codec: 'AVC', bitrate: '26000 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: 'e178ce98-5f41-4cd8-a7a2-252dfb0ba8cd', name: 'Mux', format: '1280x720p50', codec: 'AVC', bitrate: '26000 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: '9a4682cb-e2b4-4b8c-980b-f9273d1051cd', name: 'Decoder ABR', format: '1280x720p50', codec: 'AVC', bitrate: '26000 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: 'd20a79c4-a90b-42cc-8096-fff23e9cbdfd', name: 'Decoder CBR', format: '1280x720p50', codec: 'AVC', bitrate: '26000 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: '68eff4bf-1137-43a7-afd0-1e2040c446e8', name: 'CBR#1', format: '1280x720p50', codec: 'AVC', bitrate: '16000 kbps', score: 100, inAlarm: true, unAcknowledged: true},
        {id: '46718190-d11a-4688-98ab-7150e3066fca', name: 'ABR#1', format: '1280x720p25', codec: 'AVC', bitrate: '2650 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: '5d03af6f-e25a-4abc-bc9b-7570c712cddc', name: 'ABR#2', format: '960x540p25', codec: 'AVC', bitrate: '1620 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: 'a7fda52a-2722-49ac-8420-a109972c8db3', name: 'ABR#3', format: '768x432p25', codec: 'AVC', bitrate: '1160 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: 'f8284e92-5156-426d-b1b0-eeb25c503477', name: 'ABR#4', format: '640x360p25', codec: 'AVC', bitrate: '864 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: 'a27ac715-0b7c-460f-a235-549a43e37991', name: 'ABR#5', format: '512x288p25', codec: 'AVC', bitrate: '645 kbps', score: 100, inAlarm: false, unAcknowledged: false},
        {id: '3daee515-a7e2-4f62-a47d-5e7ff3ecb551', name: 'ABR#6', format: '384x216p12.5', codec: 'AVC', bitrate: '342 kbps', score: 100, inAlarm: false, unAcknowledged: false},
    ])
    const [showAlarmIcon, setShowAlarmIcon] = React.useState(false)
    const [showUnAcknowledged, setShowUnAcknowledged] = React.useState(false)

    React.useEffect(() => {
        let subscriptions = []
        let mounted = true

        async function setupSubscription(monitorPointId) {
            // console.log('Subscribing to createVQM')
            subscriptions.push(API.graphql(
                graphqlOperation(
                    onCreateVqm,
                    {measurementPointId: monitorPointId}
                )).subscribe({
                next: (data) => {
                    if (mounted) {
                        if (data['value']['data']['onCreateVqm']['chunk']){
                            const score = Math.min(...data['value']['data']['onCreateVqm']['chunk'].map(item => item.value))
                            setMonitorPointsResult(prevList => {
                                return prevList.map(item =>
                                    item.id === monitorPointId ? {
                                            id: item.id,
                                            name: item.name,
                                            score: score}
                                        : item)
                            })
                        }
                    }
                },
                error: error => {
                    console.warn(error);
                }
            }))
        }
        if (monitorPoints) {
            if (channelName === 'Mountain Bike Channel') monitorPoints.forEach((monitorPoint) => setupSubscription(monitorPoint.id).then())
            return () => {
                mounted = false
                if (subscriptions) {
                    subscriptions.forEach((subscription) => subscription.unsubscribe())
                }
            }
        }
    },[channelName, monitorPoints])

    React.useEffect(() => {
        if (channelName === 'Mountain Bike Channel'){
            let scoreList = monitorPointsResult.map(item => item.score)
            const scoreMin = Math.min(...scoreList)
            const arrAvg = arr => arr.reduce((a,b) => a + b, 0) / arr.length
            const scoreAverage = arrAvg(scoreList)
            const score = Math.floor(0.2 * scoreAverage + 0.8 * scoreMin)
            // console.log(scoreMin, scoreAverage, score)
            setShowAlarmIcon((score < 80))
            setGaugeOptions({series: [{data: [score]}]})
        }
    }, [channelName, monitorPointsResult])

    React.useEffect(() => {
        let mounted = true
        let interval
        if (channelName !== 'Mountain Bike Channel'){
            interval = setInterval(() => {
                if (mounted){
                    setGaugeOptions({series: [{data: [80 + Math.floor(Math.random() * 10)]}]})
                }
            }, 3000);
        }
        return () => {
            mounted = false
            if (interval) clearInterval(interval);
        };
    }, [channelName]);

    // Subscribe to update Channel mutation
    React.useEffect(() => {
        let subscription
        let mounted = true

        async function setupSubscription() {
            subscription = API.graphql(
                graphqlOperation(
                    onUpdateChannel,
                    {id: channelId}
                )).subscribe({
                next: (data) => {
                    if (mounted) {
                        // console.log(data['value']['data']['onUpdateChannel'])
                        setThumbnailUpdatedAt(data['value']['data']['onUpdateChannel']['updatedAt'])
                    }
                },
                error: error => {
                    console.warn(error);
                }
            })
        }
        if (channelId) {
            setupSubscription().then()
            return () => {
                mounted = false
                if (subscription) {
                    subscription.unsubscribe()
                }
            }
        }
    }, [channelId])


    React.useEffect(() => {
        let mounted = true

        async function getMyChannel(){
            let response = await API.graphql(graphqlOperation(getChannel, {id: channelId}))
            return (response['data']['getChannel'])
        }

        getMyChannel().then(data => {
            if (mounted && data) {
                // console.log(data)
                setShowUnAcknowledged(data.unAcknowledged)
            }})
        return () => {
            mounted = false
        }
    }, [channelId])

    // Load newest thumbnail
    React.useEffect(()=> {
        let mounted = true
        async function getAWSUrl() {
            if (channelId){
                const fileAccessURLCapture = await Storage.get(
                    'thumbnails/' + channelId + ".jpeg",
                    {
                        identityId: 'us-east-2:4d7d9cd0-8558-48e8-a6ce-65333aa0c26c',
                        level: 'protected',
                        expires: 86400 });
                if (mounted) setThumbnailUrl(fileAccessURLCapture);
            }
        }
        getAWSUrl().then()
        return () => {
            mounted = false
        }
    }, [channelId, thumbnailUpdatedAt])

    // console.log(thumbnailUrl)
    return (
        <Card
            key={channelId}
            id={channelId}
            padding="0rem"
            variation="elevated"
            onClick={props.onClick}
        >
            <Flex direction="column" gap={"0px"} alignItems={"center"} justifyContent={"center"}>
                <ChannelThumbnail
                    channelId={channelId}
                    channelName={channelName}
                    channelThumbnail={thumbnailUrl}
                />
                <Flex direction={"row"} justifyContent={"flex-start"} gap={0} width={'100%'} backgroundColor={'white'}>
                    <div style={{width:'75px', height:'75px'}}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={gaugeOptions}
                            ref={bitrateChartRef}
                        />
                    </div>
                    <Flex direction={"column"} alignItems={"flex-start"} gap={'2px'}>
                        <div style={{width:'225px', height:'30px'}}>
                            <Heading level={5} padding={"5px"}>{channelName}</Heading>
                        </div>
                        <Flex direction={"row"} justifyContent={"flex-end"} width={"100%"}>
                            { showAlarmIcon ?
                                <div style={{width:'25px', height:'25px', paddingTop:'4px'}}>
                                    <IconContext.Provider value={{ style:{width:'25px', height:'25px'} }}>
                                        <div>
                                            <FcHighPriority className={"customClass"}/>
                                        </div>
                                    </IconContext.Provider>
                                </div>: <div style={{width:'25px', height:'25px', paddingTop:'4px'}}/>
                            }
                            {showUnAcknowledged ?
                                <div style={{width: '25px', height: '25px', paddingTop: '4px'}}>
                                    <IconContext.Provider
                                        value={{color: "black", style: {width: '25px', height: '25px'}}}>
                                        <div>
                                            <VscBellDot/>
                                        </div>
                                    </IconContext.Provider>
                                </div>: <div style={{width:'25px', height:'25px', paddingTop:'4px'}}/>
                            }
                        </Flex>
                    </Flex>

                </Flex>
            </Flex>
        </Card>
    )
}

export default ChannelCard